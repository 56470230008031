import React from 'react'
import './press.css'
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import optimize_customer_img from "../../assets/images/optimize-customer-img.png";
export default function Press() {
  return (
    <div className='press'>
        <Navbar
        extra_heading = "KASOOKOO CONTACT CENTRE"
        heading="A highly efficient Contact Center for Increased Productivity"
        text="Enable smooth and effortless customer interactions by leveraging a comprehensive cloud-based contact center solution that seamlessly integrates calls, messages, and OTT conversations. Empower your agents and managers with robust integrations that enhance customer engagement to an unprecedented level."
        img={optimize_customer_img}
        contact_center_form = "true"
        first_section = {true}
      ></Navbar>
        

            

     <div id="num-section-1" class="w-100 position-relative block-centered_title pb-4">
            <style>
                           </style>
            
<div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
    <div class="container">
        <div class="row ">
            <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">Toku Press Releases</h2>            </div>
        </div>
    </div>
</div>
        </div>
                <div id="num-section-2" class="w-100 position-relative block-block_text mb-5">
            <style>
                           </style>
            <div class="container press-shortcode"><div class="row rseh-md"><div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 mb-lg-5 press-item ">

    <a href="https://toku.co/press/toku-acquires-leading-customer-engagement-firm-activeo-singapore-to-help-more-apac-businesses-unlock-modern-cx/" class="card card-normal w-100 d-flex d-md-block rs_equalHeight" style={{height: '478px'}}>
		<div class="w-100 d-flex order-0 card-header mb-3 p-0">
			<img src="https://toku.co/wp-content/uploads/2023/04/Press-release-Toku-acquires-Activeo-Singapore-signing.png" alt="Toku acquires leading customer engagement firm Activeo Singapore to help more APAC businesses unlock modern CX" class="card-normal-header-image img-responsive responsive--full w-100 h-auto img-obj border-radius-10"/>
		</div>
		<div class="w-100 d-flex order-2 order-md-1 d-md-block pt-0 pt-md-3">
			<h4 class="title-card title-card-normal">Toku acquires leading customer engagement firm Activeo Singapore to help more APAC businesses unlock modern CX</h4>
		</div>
		<div class="w-100 d-flex order-1 order-md-2 d-md-block card-normal-author pb-3 pb-md-5">
			April 4, 2023		</div>
	</a>
</div>
<div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 mb-lg-5 press-item ">

    <a href="https://toku.co/press/toku-launches-connectivity-for-zoom-phone/" class="card card-normal w-100 d-flex d-md-block rs_equalHeight" style={{height: '478px'}}>
		<div class="w-100 d-flex order-0 card-header mb-3 p-0">
			<img src="https://toku.co/wp-content/uploads/2022/12/V1-3.png" alt="Toku Launches Enhanced Cloud Communications Solution for Zoom Phone in 14 APAC markets" class="card-normal-header-image img-responsive responsive--full w-100 h-auto img-obj border-radius-10"/>
		</div>
		<div class="w-100 d-flex order-2 order-md-1 d-md-block pt-0 pt-md-3">
			<h4 class="title-card title-card-normal">Toku Launches Enhanced Cloud Communications Solution for Zoom Phone in 14 APAC markets</h4>
		</div>
		<div class="w-100 d-flex order-1 order-md-2 d-md-block card-normal-author pb-3 pb-md-5">
			December 15, 2022		</div>
	</a>
</div>
<div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 mb-lg-5 press-item ">

    <a href="https://toku.co/press/toku-appoints-coo/" class="card card-normal w-100 d-flex d-md-block rs_equalHeight" style={{height: '478px'}}>
		<div class="w-100 d-flex order-0 card-header mb-3 p-0">
			<img src="https://toku.co/wp-content/uploads/2022/12/Frame-2228.png" alt="Toku Appoints Cloud Communications Veteran to Newly-Created COO Role" class="card-normal-header-image img-responsive responsive--full w-100 h-auto img-obj border-radius-10"/>
		</div>
		<div class="w-100 d-flex order-2 order-md-1 d-md-block pt-0 pt-md-3">
			<h4 class="title-card title-card-normal">Toku Appoints Cloud Communications Veteran to Newly-Created COO Role</h4>
		</div>
		<div class="w-100 d-flex order-1 order-md-2 d-md-block card-normal-author pb-3 pb-md-5">
			November 30, 2022		</div>
	</a>
</div>
<div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 mb-lg-5 press-item ">

    <a href="https://toku.co/press/toku-series-a-extension/" class="card card-normal w-100 d-flex d-md-block rs_equalHeight" style={{height: '478px'}}>
		<div class="w-100 d-flex order-0 card-header mb-3 p-0">
			<img src="https://toku.co/wp-content/uploads/2022/12/Frame-2229.png" alt="Toku Announces Series A Extension to US$10m as it Accelerates APAC Growth" class="card-normal-header-image img-responsive responsive--full w-100 h-auto img-obj border-radius-10"/>
		</div>
		<div class="w-100 d-flex order-2 order-md-1 d-md-block pt-0 pt-md-3">
			<h4 class="title-card title-card-normal">Toku Announces Series A Extension to US$10m as it Accelerates APAC Growth</h4>
		</div>
		<div class="w-100 d-flex order-1 order-md-2 d-md-block card-normal-author pb-3 pb-md-5">
			October 26, 2022		</div>
	</a>
</div>
<div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 mb-lg-5 press-item ">

    <a href="https://toku.co/press/toku-singapore-consumer-engagement-report-67-percent-resolve-in-app/" class="card card-normal w-100 d-flex d-md-block rs_equalHeight" style={{height: '478px'}}>
		<div class="w-100 d-flex order-0 card-header mb-3 p-0">
			<img src="https://toku.co/wp-content/uploads/2022/12/Frame-2231.png" alt="Toku Singapore Consumer Engagement Report Reveals 67% Prefer to Resolve Issues In App" class="card-normal-header-image img-responsive responsive--full w-100 h-auto img-obj border-radius-10"/>
		</div>
		<div class="w-100 d-flex order-2 order-md-1 d-md-block pt-0 pt-md-3">
			<h4 class="title-card title-card-normal">Toku Singapore Consumer Engagement Report Reveals 67% Prefer to Resolve Issues In App</h4>
		</div>
		<div class="w-100 d-flex order-1 order-md-2 d-md-block card-normal-author pb-3 pb-md-5">
			September 22, 2022		</div>
	</a>
</div>
<div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 mb-lg-5 press-item ">

    <a href="https://toku.co/press/toku-launches-contact-centre-omnichannel-customer-experiences-businesses-apac/" class="card card-normal w-100 d-flex d-md-block rs_equalHeight" style={{height: '478px'}}>
		<div class="w-100 d-flex order-0 card-header mb-3 p-0">
			<img src="https://toku.co/wp-content/uploads/2022/12/Frame-2230-1.png" alt="Toku Launches a Contact Centre Platform to Deliver Better Omnichannel Customer Experiences for Businesses Operating in APAC" class="card-normal-header-image img-responsive responsive--full w-100 h-auto img-obj border-radius-10"/>
		</div>
		<div class="w-100 d-flex order-2 order-md-1 d-md-block pt-0 pt-md-3">
			<h4 class="title-card title-card-normal">Toku Launches a Contact Centre Platform to Deliver Better Omnichannel Customer Experiences for Businesses Operating in APAC</h4>
		</div>
		<div class="w-100 d-flex order-1 order-md-2 d-md-block card-normal-author pb-3 pb-md-5">
			July 5, 2022		</div>
	</a>
</div>
<div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 mb-lg-5 press-item  d-none ">

    <a href="https://toku.co/press/toku-leads-effort-eliminate-call-fraud-scams-singapore-ab-handshake/" class="card card-normal w-100 d-flex d-md-block rs_equalHeight" style={{height: '478px'}}>
		<div class="w-100 d-flex order-0 card-header mb-3 p-0">
			<img src="https://toku.co/wp-content/uploads/2022/06/toku-ab-handshake-press-release-hero.png" alt="Toku Leads Effort to Eliminate Call Frauds in the Telecommunications Industry as Singapore Residents Still at High Risk of Call Scams" class="card-normal-header-image img-responsive responsive--full w-100 h-auto img-obj border-radius-10"/>
		</div>
		<div class="w-100 d-flex order-2 order-md-1 d-md-block pt-0 pt-md-3">
			<h4 class="title-card title-card-normal">Toku Leads Effort to Eliminate Call Frauds in the Telecommunications Industry as Singapore Residents Still at High Risk of Call Scams</h4>
		</div>
		<div class="w-100 d-flex order-1 order-md-2 d-md-block card-normal-author pb-3 pb-md-5">
			June 28, 2022		</div>
	</a>
</div>
<div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 mb-lg-5 press-item  d-none ">

    <a href="https://toku.co/press/toku-teams-up-with-zoom-to-deliver-greater-cloud-connectivity-and-flexibility-to-businesses-across-apac/" class="card card-normal w-100 d-flex d-md-block rs_equalHeight" style={{height: '478px'}}>
		<div class="w-100 d-flex order-0 card-header mb-3 p-0">
			<img src="https://toku.co/wp-content/uploads/2022/12/Frame-2233.png" alt="Toku Teams Up with Zoom to Deliver Greater Cloud Connectivity and Flexibility to Businesses Across APAC" class="card-normal-header-image img-responsive responsive--full w-100 h-auto img-obj border-radius-10"/>
		</div>
		<div class="w-100 d-flex order-2 order-md-1 d-md-block pt-0 pt-md-3">
			<h4 class="title-card title-card-normal">Toku Teams Up with Zoom to Deliver Greater Cloud Connectivity and Flexibility to Businesses Across APAC</h4>
		</div>
		<div class="w-100 d-flex order-1 order-md-2 d-md-block card-normal-author pb-3 pb-md-5">
			September 16, 2021		</div>
	</a>
</div>
<div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 mb-lg-5 press-item  d-none ">

    <a href="https://toku.co/press/toku-raises-usd-5-million-series-a-to-help-apac-businesses-accelerate-digital-transformation-plans/" class="card card-normal w-100 d-flex d-md-block rs_equalHeight" style={{height: '478px'}}>
		<div class="w-100 d-flex order-0 card-header mb-3 p-0">
			<img src="https://toku.co/wp-content/uploads/2022/12/Frame-2234.png" alt="Toku Raises USD 5 Million Series A to Help APAC Businesses Accelerate Digital Transformation Plans" class="card-normal-header-image img-responsive responsive--full w-100 h-auto img-obj border-radius-10"/>
		</div>
		<div class="w-100 d-flex order-2 order-md-1 d-md-block pt-0 pt-md-3">
			<h4 class="title-card title-card-normal">Toku Raises USD 5 Million Series A to Help APAC Businesses Accelerate Digital Transformation Plans</h4>
		</div>
		<div class="w-100 d-flex order-1 order-md-2 d-md-block card-normal-author pb-3 pb-md-5">
			September 7, 2021		</div>
	</a>
</div>
</div>
        <div class="row">
            <div class="col-12 text-center"><button class="btn btn-blue press-show-more">Show more</button></div>
        </div>
    </div><div class="container section-block_text">
	<div class="row">
		<div class="col-12">
			
		</div>
	</div>
</div>
        </div>
                <div id="num-section-3" class="w-100 position-relative block-cta_strip_2 ">
            <style>
                
            </style>
            
<div class="w-100 section-cta_strip_2 text-white text-center text-md-start ">
	<div class="container pt-5 pt-md-0">
		<div class="row py-md-5">
            <div class="col-12 col-md-6 d-flex ps-md-5  order-last order-md-last" style={{verticalAlign: 'bottom'}}>
                <img src="https://toku.co/wp-content/uploads/2023/01/Frame-46398.png" alt="Toku Press Kit" class="w-100 img-class" style={{objectFit: 'contain'}}/>
            </div>
			<div class="col-12 col-md-6 py-4 py-md-5 my-auto px-lg-4 ">
				<div class="d-block w-100">
					<h2 class="style-h2">Toku Press Kit</h2>
				</div>
                                    <div class="d-block w-100">
                        <div class="p-big"><p>Get our logos and brand photography for media use.</p>
</div>
                    </div>
                                				<div class="d-block w-100 mt-4">
                    <a href="https://toku.co/press-kit/" class="btn btn-yellow border-radius-50 mx-auto ms-0" target="">
						Go to Press Kit                     </a>
				</div>
                			</div>
		</div>
	</div>
</div>

        </div>
                <div id="num-section-4" class="w-100 position-relative block-centered_title pt-4 pb-4">
            <style>
                            </style>
            
<div class="w-100 section-centered_title pt-4 pb-4 py-3 align-items-center text-left text-md-center">
    <div class="container">
        <div class="row ">
            <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">Toku News</h2>            </div>
        </div>
    </div>
</div>
        </div>
                <div id="num-section-5" class="w-100 position-relative block-news_items pb-4">
            <style>
                           </style>
            <div class="w-100 section-news_items p-3 p-md-0">
    <div class="container">
        <div class="row">
            
                <div class="careers-shortcode-item news-item mb-4 p-3 p-md-4  d-block d-md-flex ">
                    <div class="cs-content mb-4 mb-md-0 my-auto">
                        <div class="p-small cs-title mb-3 my-auto">
                            <a href="https://www.uctoday.com/unified-communications/ringcentral-bandwidth-and-toku-on-cloud-calling-and-byoc/" style={{textAlign: 'left'}}>
                                RingCentral, Bandwidth, and Toku on Cloud Calling and BYOC                             </a>
                        </div>
                    </div>
                    <div class="cs-button my-auto">
                        <img src="https://toku.co/wp-content/uploads/2023/02/UC_Today_FullLogo-1.png"/>
                    </div>
                </div>
            
                <div class="careers-shortcode-item news-item mb-4 p-3 p-md-4  d-block d-md-flex ">
                    <div class="cs-content mb-4 mb-md-0 my-auto">
                        <div class="p-small cs-title mb-3 my-auto">
                            <a href="https://www.uctoday.com/unified-communications/toku-launches-contact-centre-in-apac/" style={{textAlign: 'left'}}>
                                Toku Launches Contact Centre in APAC                             </a>
                        </div>
                    </div>
                    <div class="cs-button my-auto">
                        <img src="https://toku.co/wp-content/uploads/2023/02/UC_Today_FullLogo-1.png"/>
                    </div>
                </div>
            
                <div class="careers-shortcode-item news-item mb-4 p-3 p-md-4  d-block d-md-flex ">
                    <div class="cs-content mb-4 mb-md-0 my-auto">
                        <div class="p-small cs-title mb-3 my-auto">
                            <a href="https://finance.yahoo.com/news/toku-leads-effort-eliminate-call-020000813.html" style={{textAlign: 'left'}}>
                                Toku Leads Effort to Eliminate Call Frauds in the Telecommunications Industry As Singapore Residents Still at High Risk of Call Scams                             </a>
                        </div>
                    </div>
                    <div class="cs-button my-auto">
                        <img src="https://toku.co/wp-content/uploads/2023/02/YAHOO-FINANCE-1.png"/>
                    </div>
                </div>
            
                <div class="careers-shortcode-item news-item mb-4 p-3 p-md-4  d-block d-md-flex ">
                    <div class="cs-content mb-4 mb-md-0 my-auto">
                        <div class="p-small cs-title mb-3 my-auto">
                            <a href="https://www.channelasia.tech/article/691123/singapore-toku-pumps-5m-investment-into-capability-coverage-expansion/" style={{textAlign: 'left'}}>
                                Singapore's Toku pumps $5M investment into capability and coverage expansion                             </a>
                        </div>
                    </div>
                    <div class="cs-button my-auto">
                        <img src="https://toku.co/wp-content/uploads/2023/02/Untitled-design-2023-01-18T111303-1.png/"/>
                    </div>
                </div>
            
                <div class="careers-shortcode-item news-item mb-4 p-3 p-md-4  d-none ">
                    <div class="cs-content mb-4 mb-md-0 my-auto">
                        <div class="p-small cs-title mb-3 my-auto">
                            <a href="https://www.uctoday.com/unified-communications/fighting-fraud-toku-joins-the-ab-handshake-community/" style={{textAlign: 'left'}}>
                                Fighting Fraud: Toku Joins the AB Handshake Community                             </a>
                        </div>
                    </div>
                    <div class="cs-button my-auto">
                        <img src="https://toku.co/wp-content/uploads/2023/02/UC_Today_FullLogo-1.png"/>
                    </div>
                </div>
            
                <div class="careers-shortcode-item news-item mb-4 p-3 p-md-4  d-none ">
                    <div class="cs-content mb-4 mb-md-0 my-auto">
                        <div class="p-small cs-title mb-3 my-auto">
                            <a href="https://www.uctoday.com/unified-communications/toku-enabling-direct-connectivity-in-apac/" style={{textAlign: 'left'}}>
                                Toku: enabling direct connectivity in APAC                            </a>
                        </div>
                    </div>
                    <div class="cs-button my-auto">
                        <img src="https://toku.co/wp-content/uploads/2023/02/UC_Today_FullLogo-1.png"/>
                    </div>
                </div>
            
                <div class="careers-shortcode-item news-item mb-4 p-3 p-md-4  d-none ">
                    <div class="cs-content mb-4 mb-md-0 my-auto">
                        <div class="p-small cs-title mb-3 my-auto">
                            <a href="https://www.uctoday.com/unified-communications/ucaas/thanks-to-toku-apac-businesses-can-successfully-face-ucaas-challenges/" style={{textAlign: 'left'}}>
                                Toku helps APAC businesses successfully face UCaaS challenges                            </a>
                        </div>
                    </div>
                    <div class="cs-button my-auto">
                        <img src="https://toku.co/wp-content/uploads/2023/02/UC_Today_FullLogo-1.png"/>
                    </div>
                </div>
            
                <div class="careers-shortcode-item news-item mb-4 p-3 p-md-4  d-none ">
                    <div class="cs-content mb-4 mb-md-0 my-auto">
                        <div class="p-small cs-title mb-3 my-auto">
                            <a href="https://www.uctoday.com/unified-communications/cpaas/how-tokus-customers-are-leveraging-communications-apis-to-boost-cx/" style={{textAlign: 'left'}}>
                                How Toku’s customers are leveraging communications APIs to boost CX                            </a>
                        </div>
                    </div>
                    <div class="cs-button my-auto">
                        <img src="https://toku.co/wp-content/uploads/2023/02/UC_Today_FullLogo-1.png"/>
                    </div>
                </div>
            
                <div class="careers-shortcode-item news-item mb-4 p-3 p-md-4  d-none ">
                    <div class="cs-content mb-4 mb-md-0 my-auto">
                        <div class="p-small cs-title mb-3 my-auto">
                            <a href="https://www.uctoday.com/collaboration/team-collaboration/beyond-collaboration-using-microsoft-teams-with-contact-centre-features/" style={{textAlign: 'left'}}>
                                Beyond collaboration: Using Microsoft Teams with Contact Centre features                            </a>
                        </div>
                    </div>
                    <div class="cs-button my-auto">
                        <img src="https://toku.co/wp-content/uploads/2023/02/UC_Today_FullLogo-1.png"/>
                    </div>
                </div>
            
                <div class="careers-shortcode-item news-item mb-4 p-3 p-md-4  d-none ">
                    <div class="cs-content mb-4 mb-md-0 my-auto">
                        <div class="p-small cs-title mb-3 my-auto">
                            <a href="https://www.channelasia.tech/article/691368/singapore-toku-targets-regional-remote-working-market-zoom-deal/" style={{textAlign: 'left'}}>
                                Singapore’s Toku targets regional remote working market with Zoom deal                            </a>
                        </div>
                    </div>
                    <div class="cs-button my-auto">
                        <img src="https://toku.co/wp-content/uploads/2023/02/Untitled-design-2023-01-18T111303-1.png"/>
                    </div>
                </div>
            
                <div class="careers-shortcode-item news-item mb-4 p-3 p-md-4  d-none ">
                    <div class="cs-content mb-4 mb-md-0 my-auto">
                        <div class="p-small cs-title mb-3 my-auto">
                            <a href="https://www.uctoday.com/unified-communications/the-toku-phone-app-makes-direct-routing-in-apac-even-more-accessible/" style={{textAlign: 'left'}}>
                                The Toku Phone App Makes Direct Routing in APAC Even More Accessible                             </a>
                        </div>
                    </div>
                    <div class="cs-button my-auto">
                        <img src="https://toku.co/wp-content/uploads/2023/02/UC_Today_FullLogo-1.png"/>
                    </div>
                </div>
            
                <div class="careers-shortcode-item news-item mb-4 p-3 p-md-4  d-none ">
                    <div class="cs-content mb-4 mb-md-0 my-auto">
                        <div class="p-small cs-title mb-3 my-auto">
                            <a href="https://www.uctoday.com/unified-communications/toku-enabling-direct-connectivity-in-apac/" style={{textAlign: 'left'}}>
                                Toku: Enabling Direct Connectivity in APAC                            </a>
                        </div>
                    </div>
                    <div class="cs-button my-auto">
                        <img src="https://toku.co/wp-content/uploads/2023/02/UC_Today_FullLogo-1.png"/>
                    </div>
                </div>
                    </div>
        <div class="row">
            <div class="col-12 text-center"><button class="btn btn-blue news-show-more">Show more</button></div>
        </div>
    </div>
</div>


        </div>
                <div id="num-section-6" class="w-100 position-relative block-cta_strip_2_images " style={{backgroundColor:'#7DBAAB'}}>
            <style>
                           </style>
            
<div class="w-100 section-cta_strip text-white py-0 py-md-3">
	<div class="container pt-5 pt-md-0">
		<div class="row align-items-center py-4 py-md-5">

            <div class="col-12 col-md-3 d-none d-md-flex image-one ">
                <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000005260-1.png" alt="Learn more about how Toku reimagines customer experiences at scale" class=""/>
            </div>

            <div class="col-12 col-md-3 image-two  order-md-last ">
                <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000005099-2.png" alt="Learn more about how Toku reimagines customer experiences at scale" class="d-block mx-auto mx-md-0 mb-4 mb-md-0 w-75 w-md-auto"/>
            </div>

            <div class="col-12 col-md-6 text-center">

                <div class="d-block w-100 px-md-5">
                    <h2 class="style-h2">Learn more about how Toku reimagines customer experiences at scale</h2>
                </div>
                <div class="d-block w-100 px-md-5">
                    <div class="p-big"></div>
                </div>
                <div class="d-block w-100 px-md-5 mt-3 text-center">
                    <a href="/about-us/" class="btn btn-yellow border-radius-50 mb-5 mb-md-0 mx-auto" target="">
			            More About Us                    </a>
                </div>

            </div>

		</div>
	</div>
</div>

        </div>
        <Footer />
        			
    </div>
  )
}
