import React from "react";
import "./hostpitality.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import comapaign_img from "../../assets/images/consulting-img.png";
export default function Hostpitality() {
  return (
    <div className="hostpitality">
      <Navbar
        heading="Holistic business transformation solutions"
        text="At Kasooko Consulting, we understand that successful digital transformation requires much more than a one-size-fits-all approach. That’s why we adopt an end-to-end consultancy approach, guiding your enterprise on every step of the way from vision to conception and implementation of the right solution."
        img={comapaign_img}
        extra_heading="KASOOKOO CONSULTING"
        first_section = {true}
      ></Navbar>

      <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title pb-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Stress-free customer journeys, made easy
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-2"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary pb-5"
      >
        <style></style>

        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "436px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Group-1000005055.svg"
                      alt="Group 1000005055"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Scale your comms with demand</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      When the peak booking seasons come around, you don’t want
                      to have to scale up your call or messaging capacity only
                      not to use it later. Ensure your communications scale with
                      your business and only pay for what you use.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "436px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Group-1000005056.svg"
                      alt="Group 1000005056"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Enable better user relationships</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Add new comms channels to your app or platform and enable
                      seamless communications between all parties involved for a
                      better experience when booking or travelling.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "436px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Group-7327.svg"
                      alt="Group 7327"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Reduce operational complexity</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Expanding to new places? Take your app or platform into
                      new markets quickly and easily, by managing your
                      communications through a single provider, reducing costs
                      and simplifying your operations.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-3"
        class="w-100 position-relative block-centered_title pt-5"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Make sure you know how guests &amp; customers want to
                  communicate
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-4"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Consumer preferences are changing rapidly, and guests &amp;
                  travelers are demanding more channels, better communications,
                  and improved customer service.
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-5"
        class="w-100 position-relative block-4_columns_with_counters "
      >
        <style></style>
        <style></style>
        <div class="container section-4_columns_with_counters strip-counter">
          <div class="row strip-counter-style-centered ">
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">33</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers prefer SMS as a communication channel, ranking it
                higher than any other*{" "}
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">54</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers use voice calls to resolve payment issues quickly*{" "}
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">67</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers prefer to stay within app environments for all
                company communications*{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-6"
        class="w-100 position-relative block-blog_cta pb-5"
      >
        <style></style>

        <div class="w-100 section-blog_cta pt-3 pb-md-3">
          <div class="container">
            <div class="w-100 cta-row my-4">
              <div
                class="cta-we-can-help px-4 px-md-5 py-3 text-white mx-auto"
                style={{
                  backgroundImage:
                    "url('https://toku.co/wp-content/uploads/2023/02/Frame.png')",
                }}
              >
                <div class="d-block w-100">
                  <h4 class="style-h4 mb-3">
                    Want to learn more about how consumers want to engage with
                    companies like yours?
                  </h4>
                  <div class="description mb-3">
                    *Read Kasooko Singapore Consumer Engagement Report 2022
                  </div>
                </div>
                <div class="d-block w-100">
                  <a class="btn btn-yellow" target="" href="">
                    Download Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-7"
        class="w-100 position-relative block-topic_center_repeater py-5"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-purple">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Frame-46s3397.svg"
                      alt="TOKU IN-APP VOICE CALLING"
                    />{" "}
                    Kasooko IN-APP VOICE CALLING{" "}
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Keep transactions in your app
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Stay connected on the go. Keep your customers updated and
                      engaged while they travel by adding a redundant channel
                      built into your application.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a href="" class="btn btn-arrow text-start" target="">
                    Bring Conversations Into Your App{" "}
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Group-1000005261-1.png"
                  alt="Group 1000005261"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-8"
        class="w-100 position-relative block-topic_center_repeater pt-5"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Frame-463398.svg"
                      alt="TOKU CONTACT CENTRE"
                    />{" "}
                    KASOOKO CONTACT CENTRE{" "}
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Take the stress out of travel troubles
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      When travelling gets difficult, give your guests a
                      seamless customer service experience with multiple
                      channels to choose from, all going to a single contact
                      centre for a simple way to get help when they’re on the
                      go.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a href="" class="btn btn-arrow text-start" target="">
                    Begin Your Omnichannel Journey{" "}
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  pe-md-5 order-md-first">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Image-34.png"
                  alt="Image 34"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-sm-none bg-archer bg-archer-down">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 87C0 87 80.5 46.5 200 46.5C319.5 46.5 400 87 400 87V0H0V87Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-sm-block bg-archer bg-archer-down">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-9"
        class="w-100 position-relative block-topic_center_repeater pb-5"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-turquoise">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Frame-463402.svg"
                      alt="TOKU CAMPAIGN MANAGER"
                    />{" "}
                    KASOOKO CAMPAIGN MANAGER{" "}
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Automate your marketing and updates
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Broadcast holiday offers, flight details, and exclusive
                      deals to thousands of customers at once. Elevate your
                      marketing and send out reliable campaigns with SMS.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a href="/" class="btn btn-arrow text-start" target="">
                    Learn More About Sending Bulk Messages{" "}
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Image-38-1.png"
                  alt="Image 38"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-10"
        class="w-100 position-relative block-topic_center_repeater py-5"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-green">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Frame-463403.svg"
                      alt="USER VERIFICATION"
                    />{" "}
                    USER VERIFICATION{" "}
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Simplify customer verification
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Verify customer bookings with programmable voice and
                      messaging APIs while combatting fraud, automating
                      verification and creating a secure environment for your
                      customers.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a href="/" class="btn btn-arrow text-start" target="">
                    Create Robust User Verification{" "}
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  pe-md-5 order-md-first">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Frame-46260-2.png"
                  alt="Frame 46260"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-11"
        class="w-100 position-relative block-topic_center_repeater py-5"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Group-1000005009-1.svg"
                      alt="TOKU BUSINESS TELEPHONY"
                    />{" "}
                    KASOOKO BUSINESS TELEPHONY{" "}
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Elevate your internal comms
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Calling your teammates worldwide in other locations,
                      hotels, and venues can be costly. Save money, improve your
                      operational efficiency, and ensure your team can work
                      smoothly when making calls internally and externally on{" "}
                      <a href="/">Microsoft Teams</a> or{" "}
                      <a href="/">Zoom Phone</a>.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Frame-46256-5.png"
                  alt="Frame 46256"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-12"
        class="w-100 position-relative block-top_title pt-5"
      >
        <style></style>
        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-purple">
              <div class="col-12 text-center pt-4">
                <div class="top-title ms-0 mx-md-auto">
                  <img
                    src="https://toku.co/wp-content/uploads/2023/01/Frame-4653410.svg"
                    alt="OUR OFFERING"
                    class="me-2"
                  />
                  OUR OFFERING{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-13"
        class="w-100 position-relative block-centered_title pb-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Learn about our other products &amp; solutions
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-14"
        class="w-100 position-relative block-2_items_in_row_with_image_title_text_link pb-5"
      >
        <style></style>
        <style></style>
        <div class="container section-2_items_in_row_with_image_title_text_link style-slimmer">
          <div class="row rseh-md pb-5">
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href="/"
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "102px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img
                        src="https://toku.co/wp-content/uploads/2023/01/Frame-463408.svg"
                        alt="Programmable  Voice  "
                      />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        Programmable Voice{" "}
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Embed voice calls in your product{" "}
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href="/"
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "102px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img
                        src="https://toku.co/wp-content/uploads/2023/01/Frame-463410.svg"
                        alt="Programmable Messaging"
                      />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        Programmable Messaging{" "}
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Build the messaging experience you want{" "}
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-15"
        class="w-100 position-relative block-cta_strip_2_images "
      >
        <style></style>

        <div class="w-100 section-cta_strip text-white py-0 py-md-3">
          <div class="container pt-5 pt-md-0">
            <div class="row align-items-center py-4 py-md-5">
              <div class="col-12 col-md-3 d-none d-md-flex image-one ">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Frame-46306.png"
                  alt="Create concierge-level customer experiences"
                  class=""
                />
              </div>

              <div class="col-12 col-md-3 image-two  order-md-last ">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Frame-46291-1.png"
                  alt="Create concierge-level customer experiences"
                  class="d-block mx-auto mx-md-0 mb-4 mb-md-0 w-75 w-md-auto"
                />
              </div>

              <div class="col-12 col-md-6 text-center">
                <div class="d-block w-100 px-md-5">
                  <h2 class="style-h2">
                    Create concierge-level customer experiences
                  </h2>
                </div>
                <div class="d-block w-100 px-md-5">
                  <div class="p-big"></div>
                </div>
                <div class="d-block w-100 px-md-5 mt-3 text-center">
                  <a
                    href="#Solutions"
                    class="btn btn-yellow border-radius-50 mb-5 mb-md-0 mx-auto"
                    target=""
                    data-bs-toggle="modal"
                    data-bs-target="#Solutions"
                  >
                    Talk to an Expert{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}
