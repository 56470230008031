import React, { useState } from "react";
import "./home.css";

import customer_support from "../../assets/images/customer-support.jpeg";

import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

import Feedback from "../../components/Feedback/Feedback";

// images
import optimize_customer_img from "../../assets/images/1.png";
import customized_solutions_icon from "../../assets/images/customized-solutions-icon.svg";
import achieving_growth_icon from "../../assets/images/achieving-growth-icon.svg";
import enhance_customer_icon from "../../assets/images/enhance-customer-icon.svg";
import seamless_processes_icon from "../../assets/images/seamless-processes-icon.svg";
import contact_center_icon from "../../assets/images/contact-center-icon.svg";
import grup1 from "../../assets/images/Group 1171.svg";
import grup2 from "../../assets/images/Group 1172.svg";
import grup3 from "../../assets/images/Group 1173.svg";
import grup4 from "../../assets/images/Group 1174.svg";
import surface from "../../assets/images/surface1.svg";
import trust from "../../assets/images/trust.svg";
import layer from "../../assets/images/layer1-0.svg";
import transform_every_img from "../../assets/images/2.png";

import embeddables_icon from "../../assets/images/embeddables.svg";
import expericence_user_img from "../../assets/images/3.png";
import business_telephony_icon from "../../assets/images/business-telephony.svg";
import microsoft_teams_img from "../../assets/images/4.png";
import zoom_phone_img from "../../assets/images/5.png";
import security_img from "../../assets/images/security-img.png";
import join_forces_1 from "../../assets/images/7-1.png";
import join_forces_2 from "../../assets/images/7-2.png";


import client1 from '../../assets/clientlogo/client1.png'
import client2 from '../../assets/clientlogo/client2.png'
import client3 from '../../assets/clientlogo/client3.png'
import client4 from '../../assets/clientlogo/client4.png'
import client5 from '../../assets/clientlogo/client5.png'
import client6 from '../../assets/clientlogo/client6.png'
import client7 from '../../assets/clientlogo/client7.png'
import client8 from '../../assets/clientlogo/client8.png'



export default function Home() {
  const images = [
    customer_support,
    customer_support,
    customer_support,
    customer_support,
  ];

  


  return (
    <div className="home">
      <Navbar
        heading="Optimize customer engagement on a large scale"
        text="With our tailored cloud communication and customer engagement solutions, Kasookoo empowers businesses to create personalized and seamless customer experiences (CX) that are perfectly suited for the digital era."
        img={optimize_customer_img}
        contact_center_form = "false"
        first_section = {true}
      ></Navbar>
      {/* <img className='w-100' style={{height:'100vh'}} src={background_img} alt="" /> */}

      <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h6 class="style-h6">
                  TRUSTED BY LEADING GLOBAL ORGANISATIONS
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-2"
        class="w-100 position-relative block-logos pb-3 hide-slick-dots-on-desktop"
      >
        <style></style>

        <div class="w-100  py-3 py-md-5 ">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12 text-center slider-logos slick-initialized slick-slider slick-dotted d-flex">

              <div class="slider mt-2 w-100" style={{overflow: 'hidden',whiteSpace: 'nowrap'}} >
                    <div class="slide-item d-flex" style={{height:'40px'}} >
                    <img
                  loading="lazy"
                  src={client1}

                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                /><img
                  loading="lazy"
                  src={client2}

                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                /><img
                  loading="lazy"
                  src={client3}

                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                /><img
                  loading="lazy"
                  src={client4}

                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                />
                <img
                  loading="lazy"
                  src={client5}

                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                />

                <img
                  loading="lazy"
                  src={client6}

                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                />

                <img
                  loading="lazy"
                  src={client7}

                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                /> 
                <img
                  loading="lazy"
                  src={client8}

                  alt="Group 6722"
                  class="m-auto d-block h-auto middle-box p-4"
                /> 
                        
                    
                    </div>
                </div>
                
                
                
               

                {/* <div class="slick-list draggable"><div class="slick-track" style={{ opacity: '1', width: '1060px', transform: 'translate3d(0px, 0px, 0px)' }}><div class="slide-logo px-3 slick-slide slick-current slick-active" style={{ width: '212px' }} tabindex="0" role="tabpanel" id="slick-slide00" aria-describedby="slick-slide-control00" data-slick-index="0" aria-hidden="false">
                                <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Group-6722.svg" alt="Group 6722" class="m-auto d-block w-100 h-auto middle-box" />
                            </div><div class="slide-logo px-3 slick-slide slick-active" tabindex="0" role="tabpanel" id="slick-slide01" aria-describedby="slick-slide-control01" style={{ width: '212px' }} data-slick-index="1" aria-hidden="false">
                                    <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/MicrosoftTeams-image-53-1.png" alt="MicrosoftTeams-image (53)" class="m-auto d-block w-100 h-auto middle-box" />
                                </div><div class="slide-logo px-3 slick-slide slick-active" tabindex="0" role="tabpanel" id="slick-slide02" aria-describedby="slick-slide-control02" style={{ width: '212px' }} data-slick-index="2" aria-hidden="false">
                                    <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Group-6720.svg" alt="Group 6720" class="m-auto d-block w-100 h-auto middle-box" />
                                </div><div class="slide-logo px-3 slick-slide slick-active" tabindex="0" role="tabpanel" id="slick-slide03" aria-describedby="slick-slide-control03" style={{ width: '212px' }} data-slick-index="3" aria-hidden="false">
                                    <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Group-6749.svg" alt="Group 6749" class="m-auto d-block w-100 h-auto middle-box" />
                                </div><div class="slide-logo px-3 slick-slide slick-active" tabindex="0" role="tabpanel" id="slick-slide04" aria-describedby="slick-slide-control04" style={{ width: '212px' }} data-slick-index="4" aria-hidden="false">
                                    <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Group-6748.svg" alt="Group 6748" class="m-auto d-block w-100 h-auto middle-box" />
                                </div></div></div>
                                
                                <ul class="slick-dots" role="tablist"><li class="slick-active" role="presentation"><button type="button" role="tab" id="slick-slide-control00" aria-controls="slick-slide00" aria-label="1 of 1" tabindex="0" aria-selected="true">1</button></li></ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-3"
        class="w-100 position-relative block-topic_center_repeater pb-4 text-white"
      >
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Expand and redefine the CX across
                    Africa and other regions
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      By leveraging Kasookoo as your reliable partner, you can
                      venture into new markets with increased assurance, while
                      simultaneously gaining command over your customer
                      experience (CX) and customer support through omnichannel
                      communications.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5 ">
                <div className="p-4">
                  <p>&nbsp;</p>
                  <div
                    style={{
                      position: "relative",
                      paddingBottom: "56.25%",
                      height: "0",
                    }}
                   
                  >
                    <iframe
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                      }}
                      src="https://www.youtube.com/embed/yJPbyDcqSeE?hide_owner=true&amp;hide_share=true&amp;hide_title=true&amp;hideEmbedTopBar=true"
                      frameborder="0"
                      allowfullscreen="allowfullscreen"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-4"
        class="w-100 position-relative block-centered_title pt-5 pb-3"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-5 pb-3 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Ignite transformative discussions that redefine the trajectory
                  of business growth
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-5"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary py-5"
      >
        <style></style>

        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-4">
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "330px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={grup1} alt="Customized solutions icon" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Customized Solutions </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Tailored features and integrations designed specifically
                      for your intricate business requirements.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "330px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={grup2}
                      alt="Achieving growth and scalability icon"
                    />
                  </div>

                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4"> Achieving Growth & Scalability </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      
                      Attain rapid market entry in Africa through scalable
                      solutions that expand alongside your business as you
                      venture into new markets.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "330px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={grup3} alt="Enhanced Customer Service icon" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Enhanced Customer Service</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Count on us as your trusted support partner for all your
                      platform and network requirements.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "330px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img src={grup4} alt="Seamless Processes Icon" />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Seamless Workflows & Processes</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Experience seamless integration and optimal collaboration
                      between all your on-premise and digital business tools.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 d-sm-none bg-archer bg-archer-down">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 87C0 87 80.5 46.5 200 46.5C319.5 46.5 400 87 400 87V0H0V87Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div class="w-100 d-none d-sm-block bg-archer bg-archer-down">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div
        id="num-section-6"
        class="w-100 position-relative block-topic_center_repeater pb-4"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src={contact_center_icon}
                      alt="KASOOKOO CONTACT CENTRE"
                    />
                    KASOOKOO CONTACT CENTRE
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Transform every interaction into a smooth 
                    experience
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Establish connections with your customers in their
                      preferred manner by consolidating and streamlining your
                      communication channels through Kasookoo Contact Centre.
                    </p>
                    <p>
                      Our cloud-based platform combines the convenience of
                      omnichannel with the power of enterprise-grade African 
                      network connectivity in one solution.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a
                    href="/contact-center"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Explore Kasookoo Contact Centre
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src={transform_every_img}
                  alt="Kasookoo Contact Centre"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>

            <div class="row small_issues pt-5 py-md-5 style-blue">
              <div class="col-12 col-md-4 pb-4 pb-md-1 px-4 px-md-3">
                <div class="title-entry px-md-4 mb-3">
                  Elevate your workforce
                </div>
                <div class="text-entry px-md-4">
                  Get a unified view of all communications
                </div>
              </div>
              <div class="col-12 col-md-4 pb-4 pb-md-1 px-4 px-md-3">
                <div class="title-entry px-md-4 mb-3">
                  Harness the power of data
                </div>
                <div class="text-entry px-md-4">
                  Gain valuable insights to customize customer experiences
                </div>
              </div>
              <div class="col-12 col-md-4 pb-4 pb-md-1 px-4 px-md-3">
                <div class="title-entry px-md-4 mb-3">
                  Driven by Artificial Intelligence
                </div>
                <div class="text-entry px-md-4">
                  Deepen customer knowledge through advanced sentiment
                  analysis
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-7"
        class="w-100 position-relative block-top_title pt-5"
      >
        <style></style>
        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-turquoise">
              <div class="col-12 text-center ">
                <div class="top-title ms-0 mx-md-auto">
                  <img
                    src={embeddables_icon}
                    alt="KASOOKOO EMBEDDABLES"
                    class="me-2"
                  />
                  KASOOKOO EMBEDDABLES
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-8"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Discover user-friendly experience tailored to your platform
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-9"
        class="w-100 position-relative block-centered_title pb-5"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Seamlessly integrate communication channels, foster customer
                  relationships, leverage contextual data, and unlock numerous
                  possibilities for your product, app, or web browser
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-10"
        class="w-100 position-relative block-block_text pb-5"
      >
        <style></style>
        <div class="container section-block_text">
          <div class="row">
            <div class="col-12">
              <p>
                <img
                  decoding="async"
                  loading="lazy"
                  class="aligncenter wp-image-2880 size-full"
                  src={expericence_user_img}
                  alt=""
                  sizes="(max-width: 984px) 100vw, 984px"
                />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-11"
        class="w-100 position-relative block-centered_title pb-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Take full control of every communication on your platform
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-12"
        class="w-100 position-relative block-items_left_icon pb-5"
      >
        <style></style>
        <div class="w-100 section-items_left_icon">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 ">
                <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                  <img
                    src={surface}
                    alt="Protect customer data and privacy"
                    class="middle-box"
                  />
                </div>
                <div class="col pe-1">
                  <p class="p-small mb-0">Protect customer data and privacy</p>
                </div>
              </div>
              <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 ">
                <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                  <img
                    src={trust}
                    alt="Boost trust by improving user confidentiality"
                    class="middle-box"
                  />
                </div>
                <div class="col pe-1">
                  <p class="p-small mb-0">
                    Enhance user confidentiality to bolster trust
                  </p>
                </div>
              </div>
              <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 ">
                <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                  <img
                    src={layer}
                    alt="Gain valuable data and insights to construct superior customer experiences"
                    class="middle-box"
                  />
                </div>
                <div class="col pe-1">
                  <p class="p-small mb-0">
                    Gain valuable data and insights to construct superior
                    customer experiences
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AccordionComponent />

      <div
        id="num-section-14"
        class="w-100 position-relative block-top_title pt-5"
      >
        <style></style>
        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 text-center ">
                <div class="top-title ms-0 mx-md-auto">
                  <img
                    src="https://toku.co/wp-content/uploads/2023/01/Frame-4653424-1.svg"
                    alt="KASOOKOO BUSINESS TELEPHONY"
                    class="me-2"
                  />
                  KASOOKOO BUSINESS TELEPHONY
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-15"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Elevate your business communications to new heights
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-16"
        class="w-100 position-relative block-topic_center_repeater "
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-turquoise">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Kasookoo for Microsoft Teams
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Enable seamless global calling from any location and
                      device using a unified telephony integration designed
                      specifically for Microsoft Teams. We have the right
                      solution for your telephony needs, whether you're a large
                      enterprise or a small business.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a
                    href="/microsoft-teams"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Discover More!
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src={microsoft_teams_img}
                  alt=""
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>

            <div class="row align-items-center style-turquoise">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Get the best of both worlds with Zoom Phone and Kasookoo
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Experience the full suite of Zoom Phone features and
                      capabilities you adore, enhanced by the robust
                      enterprise-grade connectivity provided by Kasookoo
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a
                    href="/zoom-phone/"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Learn More!
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  pe-md-5 order-md-first">
                <img
                  src={zoom_phone_img}
                  alt="Toku for Zoom Phone"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 d-md-none bg-archer bg-archer-up">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div
        id="num-section-17"
        class="w-100 position-relative block-centered_title pb-0"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-0 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">Feedback from our valued customers</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-18"
        class="w-100 position-relative block-centered_title pt-0"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-0 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  
                  Renowned brands worldwide rely on Kasookoo to empower them in
                  creating a highly scalable and effortlessly seamless customer
                  experience (CX).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-19"
        class="w-100 position-relative block-recommendations_slider py-5 pb-5"
      >
        <Feedback />
      </div>

      <div
        id="num-section-20"
        class="w-100 position-relative block-topic_center_repeater "
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-turquoise">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Security is our top priority
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      
                      Our Information Security Management System, which
                      encompasses the development, provisioning, and maintenance
                      of Telco Interfaces, Cloud Communications, and Customer
                      Engagement Platforms with connecting APIs and SDKs, is
                      certified for
                      <br />
                      ISO/IEC 27001:2013.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/02/ISO-certification.png"
                  alt="ISO certification"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-21"
        class="w-100 position-relative block-cta_strip_2_images "
      >
        <style></style>

        <div class="w-100 section-cta_strip text-white py-0 py-md-3">
          <div class="container pt-5 pt-md-0">
            <div class="row align-items-center py-4 py-md-5">
              <div class="col-12 col-md-3 d-none d-md-flex image-one ">
                <img
                  src={join_forces_2}
                  alt="Join forces with Toku to reimagine CX today! "
                  class=""
                />
              </div>

              <div class="col-12 col-md-3 image-two  order-md-last ">
                <img
                  src={join_forces_1}
                  alt="Join forces with Toku to reimagine CX today! "
                  class="d-block mx-auto mx-md-0 mb-4 mb-md-0 w-75 w-md-auto"
                />
              </div>

              <div class="col-12 col-md-6 text-center">
                <div class="d-block w-100 px-md-5">
                  <h2 class="style-h2">
                    Join forces with Toku to reimagine CX today!
                  </h2>
                </div>
                <div class="d-block w-100 px-md-5">
                  <div class="p-big"></div>
                </div>
                <div class="d-block w-100 px-md-5 mt-3 text-center">
                  <a
                    href="#contactModal"
                    class="btn btn-yellow border-radius-50 mb-5 mb-md-0 mx-auto"
                    target=""
                    data-bs-toggle="modal"
                    data-bs-target="#contactModal"
                  >
                    Talk to an Expert
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}



export function AccordionComponent() {
  const list_accordion_6 =  [
    {
      title: "In-App Voice Calling",
      text: "<p>Simplify the process of connecting with you or third parties through voice calls, ensuring a secure user experience within your app. Collect valuable data that can be transformed into impactful business insights.</p>",
      image:
        "https://toku.co/wp-content/uploads/2023/01/Work-hard-play-hard-5.png",
      bottom_arrow_link: {
        title: "Add In-App Voice Calling Today",
        url: "/in-app-voice-calling/",
        target: "",
      },
    },
  
    {
      title: "Number Masking",
      text: "<p>Safeguard your users, agents, sellers, drivers, and service providers with a number masking API, enabling anonymization or customization of caller ids for enhanced user privacy.</p>",
      image: "https://toku.co/wp-content/uploads/2023/01/Image-532.png",
      bottom_arrow_link: {
        title: "Learn More About Number Masking",
        url: "/number-masking",
        target: "",
      },
    },
  
    {
      title: "Programmable Voice",
      text: "<p>Enhance control and integrability by incorporating voice calling capabilities and expanding your range of voice functionalities through our programmable voice APIs.</p>",
      image:
        "https://toku.co/wp-content/uploads/2023/01/Programmable-Voice-2.png",
      bottom_arrow_link: {
        title: "Integrate Voice Within Your Platform",
        url: "/programmable-voice/",
        target: "",
      },
    },
  
    {
      title: "Programmable Messaging",
      text: "<p>Bridge the gap with your audience and craft an exceptional messaging experience using our Programmable Messaging APIs.</p>",
      image:
        "https://toku.co/wp-content/uploads/2023/01/Programmable-Messaging-23.png",
      bottom_arrow_link: {
        title: "Explore More About Programmable Messaging",
        url: "/programmable-messaging/",
        target: "",
      },
    },
    {
      title: "User Verification",
      text: "<p>Enhance your customer validation process, boost conversion rates, and effectively combat fraud using our comprehensive set of APIs.</p>",
      image: "https://toku.co/wp-content/uploads/2023/01/Image-23456.png",
      bottom_arrow_link: {
        title: "Discover New Ways to Verify Your Customers",
        url: "/user-varification",
        target: "",
      },
    },
  ];
  const [activeItem, setActiveItem] = useState(0);

  const showItem = (index) => {
    setActiveItem(index);
  };
  return (
    <div
      id="num-section-13"
      className="w-100 position-relative block-accordion_list py-5"
    >
      <style>{`
        /* CSS styles here */
      `}</style>
      <div className="w-100 section-accordion_list ng-scope">
        <div className="container">
          <div className="row d-md-none">
            <div className="accordion accordion-flush" id="accordionFlush-6">
              {list_accordion_6.map((item, index) => (
                <div className="accordion-item" key={index}>
                  <h2
                    className="accordion-header"
                    id={`flush-heading${index + 1}`}
                  >
                    <button
                      className={`accordion-button ${
                        index === activeItem ? "style-h4" : "collapsed style-h4"
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapse${index + 1}`}
                      aria-expanded={index === 0 ? "true" : "false"}
                      aria-controls={`flush-collapse${index + 1}`}
                    >
                      {item.title}
                    </button>
                  </h2>
                  <div
                    id={`flush-collapse${index + 1}`}
                    className={`accordion-collapse collapse ${
                      index === 0 ? "show" : ""
                    }`}
                    aria-labelledby={`flush-heading${index + 1}`}
                    data-bs-parent="#accordionFlush-6"
                  >
                    <div className="accordion-body">
                      <div className="d-block w-100">
                        <img
                          decoding="async"
                          loading="lazy"
                          className="aligncenter size-full size-full mb-3 mb-md-0"
                          src={item.image}
                          alt={item.title}
                        />
                      </div>
                      <div
                        className="d-block w-100 p-small"
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="row d-none d-md-flex">
            <div className="col-6">
              <h3 className="style-h3 mb-5">How we deliver our offering</h3>
              {list_accordion_6.map((item, index) => (
                <button
                  className={`list-accordion style-h4 w-100 ng-binding ng-scope ${
                    index === activeItem ? "active" : "text-dark"
                  }`}
                  key={index}
                  onClick={() => showItem(index)}
                  style={{ boxShadow: "none" }}
                >
                  {item.title}
                </button>
              ))}
            </div>
            <div className="col-6">
              <div className="d-block w-100 mb-2">
                <img
                  decoding="async"
                  loading="lazy"
                  className="size-full"
                  src={list_accordion_6[activeItem].image}
                  alt={list_accordion_6[activeItem].title}
                />
              </div>
              <div className="d-block w-100">
                <div className="style-h4 mb-2">
                  {list_accordion_6[activeItem].title}
                </div>
              </div>
              <div
                className="d-block w-100 ng-binding"
                dangerouslySetInnerHTML={{
                  __html: list_accordion_6[activeItem].text,
                }}
              />
              <a
                className="btn btn-arrow text-start ng-binding ng-hide"
                href={list_accordion_6[activeItem].bottom_arrow_link.url}
              >{list_accordion_6[activeItem].bottom_arrow_link.title}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
