// HEAD;
// import logo from "./logo.svg";
// import "./App.css";
// import Home from "./pages/Home/Home";
// import Consulting from "./pages/Consulting/Consulting";
// import WhyKasookoo from "./pages/WhyKasookoo/WhyKasookoo";
// import Navbar from "./components/navbar/Navbar";
// import Footer from "./components/footer/Footer";

import logo from "./logo.svg";
import "./App.css";
import './style.css'

import Home from "./pages/Home/Home";
import ContactCenter from "./pages/ContactCenter/ContactCenter";
import Consulting from "./pages/Consulting/Consulting";
import WhyKasookoo from "./pages/WhyKasookoo/WhyKasookoo";
import CompaignManager from "./pages/CompaignManager/CompaignManager";
import InAppVoiceCalling from "./pages/InAppVoiceCalling/InAppVoiceCalling";
import KasookooForMicrosoft from "./pages/KasookooForMicrosoft/KasookooForMicrosoft";
import ProgrammableMessaging from "./pages/ProgrammableMessaging/ProgrammableMessaging";
import KasookooForZoom from "./pages/KasookooForZoom/KasookooForZoom";
import GovernmentSolution from "./pages/GovernmentSolution/GovernmentSolution";
import Hostpitality from "./pages/Hospitality/Hostpitality";
import AboutUS from "./pages/AboutUS/AboutUs"
import FinTech from "./pages/FinTech/FinTech";
import SharingEconomy from './pages/SharingEconomy/SharingEconomy'
import InsuranceServices from './pages/InsuranceServices/InsuranceServices'
import NumberMasking from "./pages/NumberMasking/NumberMasking";
import ProgrammableVoice from "./pages/ProgrammableVoice/ProgrammableVoice";
import RetaileCommerce from './pages/Retail&eCommerce/RetailAndeCommerce'
import UserVarification from './pages/UserVarification/UserVarification'
import Careers from "./pages/Careers/Careers";
import ViewJob from "./pages/ViewJob/ViewJob";
import Partners from "./pages/Partners/Partners";
import Press from "./pages/Press/Press";
import HelpCenter from './pages/Support Page Helpcenter/HelpCenter'
import Resources from './pages/ResourcesPage/Resources'
import Carrier from "./pages/Carrier/Carrier";
import ContactUs from "./pages/ContactUs/ContactUs";
import Faqs from './pages/Faqs/Faqs'
import GDPR from "./pages/GDPR/GDPR";
import TermAndConditions from "./pages/TermsAndConditions/TermAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";



// import 'bootstrap/dist/css/bootstrap.min.css';
import { HashRouter as Router, Routes, Route } from "react-router-dom";
// import "./kasookoo.css";



function App() {
  return (
    <>
      
    
      <Router>
        <Routes>
          <Route path="/" element={<Home />}></Route> 
          <Route path="/contact-center" element={<ContactCenter />}></Route> 
          <Route path="/about-us" element={<AboutUS />}></Route> 
          <Route path="/campaign-manager" element={<CompaignManager />}></Route>
          <Route path="/in-app-voice-calling" element={<InAppVoiceCalling />}></Route>
          <Route path="/kasookoo-for-microsoft" element={<KasookooForMicrosoft />}></Route>
          <Route path="/programmable-messaging" element={<ProgrammableMessaging />}></Route>
          <Route path="/kasookoo-for-zoom" element={<KasookooForZoom />}></Route>
          <Route path="/number-masking" element={<NumberMasking />}></Route>
          <Route path="/government-solution" element={<GovernmentSolution />}></Route>
          <Route path="/hostpitality" element={<Hostpitality />}></Route>
          <Route path="/insurance-services" element={<InsuranceServices />}></Route>
          <Route path="/consutling" element={<Consulting />}></Route>
          <Route path="/why-kasooko" element={<WhyKasookoo />}></Route>
          <Route path="/programmable-voice" element={<ProgrammableVoice />}></Route>
          <Route path="/FinTech" element={<FinTech />}></Route>
          <Route path="/sharing-economy" element={<SharingEconomy />}></Route>
          <Route path="/retail-ecommerce" element={<RetaileCommerce />}></Route>
          <Route path="/user-varification" element={<UserVarification />}></Route>
          <Route path="/customer" element={<Resources />}></Route>
          <Route path="/careers" element={<Careers />}></Route>
          <Route path="/careers/:title" element={<ViewJob />}></Route>

          <Route path="/partners" element={<Partners />}></Route>
          <Route path="/press" element={<Press />}></Route>
          {/* <Route path="/carrier-services" element={<Carrier />}></Route> */}
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/help-center" element={<HelpCenter />}></Route>
          <Route path="/faqs" element={<Faqs />}></Route>
          <Route path="/gdpr" element={<GDPR />}></Route>
          <Route path="/terms-and-conditions" element={<TermAndConditions />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>

          {/* 
          
          <Route path="/Components" element={<ComponentsPage />}></Route>
          
          
          
          
          
          
          
          
          
          
           */}

        </Routes>
      </Router>
      {/* <Navbar></Navbar> */}
      {/* <Home></Home> */}
      {/* <Footer></Footer> */}
    </>
  );
}

export default App;
