import React from "react";
import "./insuranceservices.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import first_section_left_image from "../../assets/images/first-section-left.png";

export default function InsuranceServices() {
  return (
    <div className="insurance">
      <Navbar
        heading="Maximise customer engagement at scale"
        text="Kasookoo helps businesses build more personalised, seamless customer experiences (CX) fit for the digital age with our bespoke cloud communications and customer engagement solutions"
        img={first_section_left_image}
        first_section = {true}
      ></Navbar>

      <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title pb-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  A frictionless experience is the best insurance
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-2"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary pb-5"
      >
        <style></style>

        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "409px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Frame-46345.svg"
                      alt="Frame 46345"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Personalised policy experiences</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Build more loyalty and trust by servicing your policies
                      with a human touch. Embed voice and messaging into your
                      customer interactions to foster deeper connections, and
                      boost retention.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "409px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Group-1000005056-1.svg"
                      alt="Group 1000005056"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Ensure smooth communications</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      When it comes to insurance, a single bad experience can
                      make or break a relationship. Ensure high-quality
                      communications across a range of channels, so there’s no
                      chance of a bad experience.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "409px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Group-7327.svg"
                      alt="Group 7327"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Integrate valuable client data</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Make sure your communications data is all in one place to
                      simplify the process of reporting and tracking your comms
                      against important policyholder information.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-3"
        class="w-100 position-relative block-centered_title pt-5"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Deeper, more engaging relationships with clients and
                  policyholders
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-4"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Insurance is a sensitive subject, the moment customers don’t
                  feel they’re getting the best customer experience, they may
                  look to change providers. Make sure you keep on top of what
                  they expect from their interactions.
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-5"
        class="w-100 position-relative block-4_columns_with_counters "
      >
        <style></style>
        <style></style>
        <div class="container section-4_columns_with_counters strip-counter">
          <div class="row strip-counter-style-centered ">
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">33</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers prefer SMS as a communication channel, ranking it
                higher than any other.*{" "}
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">54</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers prefer using voice calls when they need to resolve
                issues quickly.*{" "}
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">45</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers indicate that they would rather get policy updates
                via email.*{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-6"
        class="w-100 position-relative block-blog_cta pb-5"
      >
        <style></style>

        <div class="w-100 section-blog_cta pt-3 pb-md-3">
          <div class="container">
            <div class="w-100 cta-row my-4">
              <div
                class="cta-we-can-help px-4 px-md-5 py-3 text-white mx-auto"
                style={{
                  backgroundImage:
                    "url('https://toku.co/wp-content/uploads/2023/02/Frame.png')",
                }}
              >
                <div class="d-block w-100">
                  <h4 class="style-h4 mb-3">
                    Want to learn more about how consumers want to engage with
                    companies like yours?
                  </h4>
                  <div class="description mb-3">
                    *Read Kasooko Singapore Consumer Engagement Report 2022
                  </div>
                </div>
                <div class="d-block w-100">
                  <a class="btn btn-yellow" target="" href="">
                    Download here
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-7"
        class="w-100 position-relative block-topic_center_repeater py-5"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Group-2.svg"
                      alt="TOKU CONTACT CENTRE"
                    />{" "}
                    Kasooko CONTACT CENTRE{" "}
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Seamlessly connect your agents and policyholders
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      When talking to your customers about their policy or
                      claims, make sure you reduce the friction that already
                      comes with customer service and ensure the best possible
                      experience by allowing them the option to choose how they
                      interact with your agents.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a href="" class="btn btn-arrow text-start" target="">
                    Enable an Omnichannel Experience{" "}
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Frame-46261-2.png"
                  alt="Frame 46261"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-8"
        class="w-100 position-relative block-topic_center_repeater pt-5"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-green">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Frame-463403.svg"
                      alt="USER VERIFICATION"
                    />{" "}
                    USER VERIFICATION{" "}
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Simplify policyholder verification
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Fraud is a huge issue in the insurance space. Turn a
                      weakness into a strength by creating secure channels with
                      voice &amp; messaging verification capabilities that speed
                      up the insurance experience, and create a secure
                      environment for your customers.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a href="" class="btn btn-arrow text-start" target="">
                    Create Robust User Authentication{" "}
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  pe-md-5 order-md-first">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Frame-486259.png"
                  alt="Frame 486259"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-sm-none bg-archer bg-archer-down">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 87C0 87 80.5 46.5 200 46.5C319.5 46.5 400 87 400 87V0H0V87Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-sm-block bg-archer bg-archer-down">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-9"
        class="w-100 position-relative block-topic_center_repeater pb-5"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Group-5.svg"
                      alt="TOKU BUSINESS TELEPHONY"
                    />{" "}
                    Kasooko BUSINESS TELEPHONY{" "}
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Unify your agents’ communications
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Save money on your internal communications, improve your
                      operational efficiency, and ensure your insurance agents
                      can work smoothly when making calls on{" "}
                      <a href="">Microsoft Teams </a>
                      or <a href="">Zoom Phone</a>.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Frame-46258-3.png"
                  alt="Frame 46258"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-10"
        class="w-100 position-relative block-centered_title pb-3 pt-5"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-3 pt-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Here’s why insurance service providers work with Kasooko
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-11"
        class="w-100 position-relative block-image_on_the_left_and_4_subjects_on_the_right pt-3 pb-5"
      >
        <style></style>
        <div class="w-100 section-image_on_the_left_and_4_subjects_on_the_right py-3">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-4">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Image-234.png"
                  alt="Image 234"
                  class="w-100 h-auto mb-4"
                />
              </div>
              <div class="col-12 col-md-8 ps-md-5">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="w-100 d-block block-style">
                      <div class="w-100 d-block mb-3">
                        <img
                          src="https://toku.co/wp-content/uploads/2023/01/Frame-46363.svg"
                          alt="Safe. Secure. Reliable"
                          title="Safe. Secure. Reliable"
                        />
                      </div>
                      <div class="w-100 d-block mb-3">
                        <h4 class="style-h4">Safe. Secure. Reliable</h4>
                      </div>
                      <div class="w-100 d-block mb-3">
                        <div class="p-small pb-3">
                          We are recognised by IMDA as a Tier 1 Aggregator and
                          compliant with all IMDA requirements, including with
                          respect to anti-spoofing protocols for protecting SMS.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="w-100 d-block block-style">
                      <div class="w-100 d-block mb-3">
                        <img
                          src="https://toku.co/wp-content/uploads/2023/01/Group-7326-1.svg"
                          alt="Designed for Efficiency"
                          title="Designed for Efficiency"
                        />
                      </div>
                      <div class="w-100 d-block mb-3">
                        <h4 class="style-h4">Designed for Efficiency</h4>
                      </div>
                      <div class="w-100 d-block mb-3">
                        <div class="p-small pb-3">
                          Customise our innovative and flexible cloud
                          communications solutions your way, and enjoy new
                          operational efficiencies in your insurance customer
                          experience.{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="w-100 d-block block-style">
                      <div class="w-100 d-block mb-3">
                        <img
                          src="https://toku.co/wp-content/uploads/2023/01/Group-1000005327.svg"
                          alt="Local 24/7 Expert Support"
                          title="Local 24/7 Expert Support"
                        />
                      </div>
                      <div class="w-100 d-block mb-3">
                        <h4 class="style-h4">Local 24/7 Expert Support</h4>
                      </div>
                      <div class="w-100 d-block mb-3">
                        <div class="p-small pb-3">
                          Experienced, responsive Singapore and APAC-based
                          technical teams ready to provide 24/7 support and
                          solve any issues or problems.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="w-100 d-block block-style">
                      <div class="w-100 d-block mb-3">
                        <img
                          src="https://toku.co/wp-content/uploads/2023/01/Group-1000005281.svg"
                          alt="Compliant with PDPA and DSS"
                          title="Compliant with PDPA and DSS"
                        />
                      </div>
                      <div class="w-100 d-block mb-3">
                        <h4 class="style-h4">Compliant with PDPA and DSS</h4>
                      </div>
                      <div class="w-100 d-block mb-3">
                        <div class="p-small pb-3">
                          We know that compliance with regulatory standards like
                          PDPA (Personal Data Protection Act) and DSS (Digital
                          Service Standards) matters, so we take this
                          requirement very seriously.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-12"
        class="w-100 position-relative block-top_title pt-5"
      >
        <style></style>
        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-purple">
              <div class="col-12 text-center pt-4">
                <div class="top-title ms-0 mx-md-auto">
                  <img
                    src="https://toku.co/wp-content/uploads/2023/01/Frame-46340-1.svg"
                    alt="OUR OFFERING"
                    class="me-2"
                  />
                  OUR OFFERING{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-13"
        class="w-100 position-relative block-centered_title pb-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Learn about our other products &amp; solutions
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-14"
        class="w-100 position-relative block-2_items_in_row_with_image_title_text_link pb-4"
      >
        <style></style>
        <style></style>
        <div class="container section-2_items_in_row_with_image_title_text_link style-slimmer">
          <div class="row rseh-md pb-4">
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href=""
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "159px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img
                        src="https://toku.co/wp-content/uploads/2023/01/Frame-463408.svg"
                        alt="Programmable  Voice  "
                      />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        Programmable Voice{" "}
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Embed voice calls in your product{" "}
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href=""
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "159px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img
                        src="https://toku.co/wp-content/uploads/2023/01/Frame-463410.svg"
                        alt="Programmable Messaging"
                      />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        Programmable Messaging{" "}
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Build the messaging experience you want{" "}
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href=""
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "159px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img
                        src="https://toku.co/wp-content/uploads/2023/01/Frame-463409.svg"
                        alt="Campaign Manager"
                      />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        Campaign Manager{" "}
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Compose, configure, and launch bulk messaging campaigns{" "}
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href=""
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "159px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img
                        src="https://toku.co/wp-content/uploads/2023/01/Frame-463411.svg"
                        alt="In-App Voice Calling"
                      />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        In-App Voice Calling{" "}
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Enable calling capabilities on your app{" "}
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-15"
        class="w-100 position-relative block-cta_strip_2_images "
      >
        <style></style>

        <div class="w-100 section-cta_strip text-white py-0 py-md-3">
          <div class="container pt-5 pt-md-0">
            <div class="row align-items-center py-4 py-md-5">
              <div class="col-12 col-md-3 d-none d-md-flex image-one  order-md-last ">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Group-1000005258.png"
                  alt="Deliver a premium insurance customer experience"
                  class=""
                />
              </div>

              <div class="col-12 col-md-3 image-two  order-md-first ">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Frame-46367.png"
                  alt="Deliver a premium insurance customer experience"
                  class="d-block mx-auto mx-md-0 mb-4 mb-md-0 w-75 w-md-auto"
                />
              </div>

              <div class="col-12 col-md-6 text-center">
                <div class="d-block w-100 px-md-5">
                  <h2 class="style-h2">
                    Deliver a premium insurance customer experience
                  </h2>
                </div>
                <div class="d-block w-100 px-md-5">
                  <div class="p-big"></div>
                </div>
                <div class="d-block w-100 px-md-5 mt-3 text-center">
                  <a
                    href="#Solutions"
                    class="btn btn-yellow border-radius-50 mb-5 mb-md-0 mx-auto"
                    target=""
                    data-bs-toggle="modal"
                    data-bs-target="#Solutions"
                  >
                    Talk to an Expert{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}
