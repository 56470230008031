import React from "react";
import "./retail&ecommerce.css";
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import optimize_customer_img from '../../assets/images/optimize-customer-img.png'
export default function RetaileCommerce() {
  return (
    <div className="retail">
      <Navbar heading="Optimize customer engagement on a large scale." text='With our tailored cloud communication and customer engagement solutions, Kasookoo empowers businesses to create personalized and seamless customer experiences (CX) that are perfectly suited for the digital era.' img={optimize_customer_img} first_section = {true}></Navbar>
      <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title pb-4"
      >
        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Make shopping effortless from start to finish
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-2"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary pt-3 pb-5"
      >
        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "410px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Frame-46343.svg"
                      alt="Frame 46343"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Build a better buyer experience</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Whether you want to set up a call centre, send messages in
                      bulk or get a solution tailored to your needs, we have the
                      products and expertise to create a better digital
                      experience for your buyers.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "410px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Frame-46344.svg"
                      alt="Frame 46344"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Create a better customer journey</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Increase conversions, improve your CSAT scores, and retain
                      more revenue, by enabling your agents to help your
                      customers or make your customers lives easier.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "410px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Frame-46345.svg"
                      alt="Frame 46345"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Earn repeat, loyal customers</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Create experiences that encourage loyalty and trust by
                      unlocking personalised touchpoints via voice and messaging
                      with our suite of customer engagement tools and APIs.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-3"
        class="w-100 position-relative block-centered_title pt-5"
      >
        <div class="w-100 section-centered_title pt-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">Keep up with buyer expectations</h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-4"
        class="w-100 position-relative block-centered_title "
      >
        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  The world of e-commerce is changing rapidly, and consumers are
                  demanding more channels, better communications, and improved
                  customer service.
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-5"
        class="w-100 position-relative block-4_columns_with_counters "
      >
        <div class="container section-4_columns_with_counters strip-counter">
          <div class="row strip-counter-style-centered ">
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">41</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers prefer SMS for payment status updates.*{" "}
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">67</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers prefer to stay within app environments for all
                company communications.*{" "}
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">81</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers indicate that they would rather get marketing
                promotions from a channel that wasn’t email.*{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-6"
        class="w-100 position-relative block-blog_cta pb-5"
      >
        <div class="w-100 section-blog_cta pt-3 pb-md-3">
          <div class="container">
            <div class="w-100 cta-row my-4">
              <div
                class="cta-we-can-help px-4 px-md-5 py-3 text-white mx-auto"
                style={{
                  backgroundImage:
                    "url('https://toku.co/wp-content/uploads/2023/02/Frame.png')",
                }}
              >
                <div class="d-block w-100">
                  <h4 class="style-h4 mb-3">
                    Want to learn more about how consumers want to engage with
                    brands?
                  </h4>
                  <div class="description mb-3">
                    *Read Toku's Singapore Consumer Engagement Report 2022
                  </div>
                </div>
                <div class="d-block w-100">
                  <a
                    class="btn btn-yellow"
                    target=""
                    href="https://4284619.fs1.hubspotusercontent-na1.net/hubfs/4284619/Toku%20Singapore%20Consumer%20Engagement%20Report%202022.pdf"
                  >
                    Download Here
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-7"
        class="w-100 position-relative block-topic_center_repeater py-5"
      >
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Group-1.svg"
                      alt="TOKU CONTACT CENTRE"
                    />{" "}
                    TOKU CONTACT CENTRE{" "}
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Give shoppers the power to choose
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Online customers are all about convenience and choice.
                      Offer them a convenient way to contact you, and the power
                      to choose which channel they do it on.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a
                    href="https://toku.co/contact-centre/"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Unlock Omnichannel Customer Service{" "}
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Frame-46262-1.png"
                  alt="Frame 4626223"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-8"
        class="w-100 position-relative block-topic_center_repeater pt-5"
      >
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-turquoise">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Frame-463402.svg"
                      alt="TOKU CAMPAIGN MANAGER"
                    />{" "}
                    TOKU CAMPAIGN MANAGER{" "}
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Broadcast your online sales &amp; marketing
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Send the latest offers, promotions and deals to your
                      customers at scale for increased sales. Customise your
                      offers by your audience segments and automate the process
                      of bulk messaging.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a
                    href="https://toku.co/campaign-manager/"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Learn More About Sending Bulk Messages{" "}
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  pe-md-5 order-md-first">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Frame-46323-2.png"
                  alt="Frame 46323"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-sm-none bg-archer bg-archer-down">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 87C0 87 80.5 46.5 200 46.5C319.5 46.5 400 87 400 87V0H0V87Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-sm-block bg-archer bg-archer-down">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-9"
        class="w-100 position-relative block-topic_center_repeater pb-5"
      >
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-purple">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Frame-46s3397.svg"
                      alt="TOKU IN-APP VOICE"
                    />{" "}
                    TOKU IN-APP VOICE{" "}
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Keep transactions in your app
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      When customers are shopping via your app, create a fully
                      redundant way to connect them to your team while adding a
                      trustworthy environment for call-based transactions on
                      your app or platform.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a
                    href="https://toku.co/in-app-voice-calling/"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Learn How to Add Voice Calls to Your Application{" "}
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Group-10000052.png"
                  alt="Group 10000052"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-10"
        class="w-100 position-relative block-top_title pt-5"
      >
        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 text-center pt-4">
                <div class="top-title ms-0 mx-md-auto">
                  <img
                    src="https://toku.co/wp-content/uploads/2023/01/Frame-4653410.svg"
                    alt="OUR OFFERING"
                    class="me-2"
                  />
                  OUR OFFERING{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-11"
        class="w-100 position-relative block-centered_title "
      >
        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row pb-4">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Learn about our other products &amp; solutions
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-12"
        class="w-100 position-relative block-2_items_in_row_with_image_title_text_link pb-5"
      >
        <div class="container section-2_items_in_row_with_image_title_text_link style-slimmer">
          <div class="row rseh-md pb-5">
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href="https://toku.co/programmable-voice/"
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "231px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img
                        src="https://toku.co/wp-content/uploads/2023/01/Frame-463d408.svg"
                        alt="Programmable  Voice  "
                      />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        Programmable Voice{" "}
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Embed voice calls in your product{" "}
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href="https://toku.co/programmable-messaging/"
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "231px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img
                        src="https://toku.co/wp-content/uploads/2023/01/Frame-4633410.svg"
                        alt="Programmable Messaging"
                      />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        Programmable Messaging{" "}
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Build the messaging experience you want{" "}
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href="https://toku.co/number-masking/"
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "231px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img
                        src="https://toku.co/wp-content/uploads/2023/01/Frame-4634212.svg"
                        alt="Number  Masking"
                      />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        Number Masking{" "}
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Anonymise personal numbers and protect your users{" "}
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href="https://toku.co/user-verification/"
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "231px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img
                        src="https://toku.co/wp-content/uploads/2023/01/Frame-4634313.svg"
                        alt="User Verification"
                      />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        User Verification{" "}
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Verify legitimate users on a range of channels{" "}
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-13"
        class="w-100 position-relative block-cta_strip_2_images "
      >
        <div class="w-100 section-cta_strip text-white py-0 py-md-3">
          <div class="container pt-5 pt-md-0">
            <div class="row align-items-center py-4 py-md-5">
              <div class="col-12 col-md-3 d-none d-md-flex image-one ">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Group-1000005096.png"
                  alt="Start creating a better e-commerce experience"
                  class=""
                />
              </div>

              <div class="col-12 col-md-3 image-two  order-md-last ">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Group-1000005097.png"
                  alt="Start creating a better e-commerce experience"
                  class="d-block mx-auto mx-md-0 mb-4 mb-md-0 w-75 w-md-auto"
                />
              </div>

              <div class="col-12 col-md-6 text-center">
                <div class="d-block w-100 px-md-5">
                  <h2 class="style-h2">
                    Start creating a better e-commerce experience
                  </h2>
                </div>
                <div class="d-block w-100 px-md-5">
                  <div class="p-big"></div>
                </div>
                <div class="d-block w-100 px-md-5 mt-3 text-center">
                  <a
                    href="#Solutions"
                    class="btn btn-yellow border-radius-50 mb-5 mb-md-0 mx-auto"
                    target=""
                    data-bs-toggle="modal"
                    data-bs-target="#Solutions"
                  >
                    Talk to an Expert{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
