import "./Navabar.css";
import logo from "../../assets/images/logoFooter.svg";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import ContactForm from "../ContactForm/ContactForm";
const Navbar = (props) => {
  const [productIsShow, setProductIsShow] = useState(false);
  const [isFormOpen,setIsFormOpen] = useState(false)
  return (
    <div>
      <main role="main">
        <header class="header-hero position-fixed ">
          <div class="container">
            <div class="row align-items-center">
              <a href="/" class="col-auto header-logo">
                <img
                  width={"170px"}
                  loading="lazy"
                  src={logo}
                  alt="Group 6722"
                  class=""
                />
              </a>

              <div class="col d-none d-xxl-flex ms-4 align-items-center">
                <nav class="navbar navbar-expand-xxl">
                  <div class="w-100">
                    <div
                      class="collapse navbar-collapse"
                      id="main_nav_magamenu"
                    >
                      <ul class="navbar-nav">
                        <li class="nav-item dropdown has-megamenu">
                          <a
                            class="nav-link  dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                          >
                            Products
                          </a>
                          <div
                            class="dropdown-menu megamenu"
                            role="menu"
                            style={{ left: "250px" }}
                          >
                            <ul class="mega-col">
                              <div class="mega-col-title  big">
                                CUSTOMER ENGAGEMENT
                              </div>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/contact-center"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src="https://toku.co/wp-content/uploads/2022/12/Frame-46325.svg"
                                      alt="Contact Centre"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Contact Centre
                                    </span>
                                    <span class="mega-item-text">
                                      Build a bespoke 360 customer experience
                                      view{" "}
                                    </span>
                                  </span>
                                </a>
                              </li>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/campaign-manager"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src="https://toku.co/wp-content/uploads/2022/12/Frame-46326.svg"
                                      alt="Campaign Manager"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Campaign Manager
                                    </span>
                                    <span class="mega-item-text">
                                      Compose, configure, and launch bulk
                                      messaging campaigns{" "}
                                    </span>
                                  </span>
                                </a>
                              </li>
                              <div class="mega-col-title  big">
                                BUSINESS TELEPHONY
                              </div>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/kasookoo-for-microsoft"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src="https://toku.co/wp-content/uploads/2022/12/Frame-46327.svg"
                                      alt="Toku for Microsoft Teams "
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Kasooko for Microsoft Teams{" "}
                                    </span>
                                    <span class="mega-item-text">
                                      Softphone to ditch the deskphone{" "}
                                    </span>
                                  </span>
                                </a>
                              </li>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/kasookoo-for-zoom"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src="https://toku.co/wp-content/uploads/2022/12/Frame-46328.svg"
                                      alt="Toku for Zoom Phone"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Kasooko for Zoom Phone
                                    </span>
                                    <span class="mega-item-text">
                                      All-in-one business communications
                                      platform{" "}
                                    </span>
                                  </span>
                                </a>
                              </li>
                            </ul>
                            <ul class="mega-col">
                              <div class="mega-col-title  big">
                                EMBEDDED COMMUNICATION
                              </div>
                              <div class="mega-col-title ">VOICE</div>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/programmable-voice"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src="https://toku.co/wp-content/uploads/2022/12/Frame-463339.svg"
                                      alt="Programmable Voice"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Programmable Voice
                                    </span>
                                    <span class="mega-item-text">
                                      Embed voice calls in your product
                                    </span>
                                  </span>
                                </a>
                              </li>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/in-app-voice-calling"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src="https://toku.co/wp-content/uploads/2022/12/Frame-46333.svg"
                                      alt="In-App Voice Calling"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      In-App Voice Calling
                                    </span>
                                    <span class="mega-item-text">
                                      Enable voice calling in your app{" "}
                                    </span>
                                  </span>
                                </a>
                              </li>
                              <div class="mega-col-title ">MESSAGING</div>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/programmable-messaging"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src="https://toku.co/wp-content/uploads/2022/12/Messaging.svg"
                                      alt="Programmable Messaging"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Programmable Messaging
                                    </span>
                                    <span class="mega-item-text">
                                      Build the messaging experience you want{" "}
                                    </span>
                                  </span>
                                </a>
                              </li>
                            </ul>{" "}
                          </div>
                        </li>
                        <li class="nav-item dropdown has-megamenu">
                          <a
                            class="nav-link  dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                          >
                            Solutions
                          </a>
                          <div class="dropdown-menu megamenu" role="menu">
                            <ul class="mega-col">
                              <div class="mega-col-title  big">BY INDUSTRY</div>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/government-solution"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src="https://toku.co/wp-content/uploads/2022/12/Frame-463393-1.svg"
                                      alt="Government Solutions"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Government Solutions
                                    </span>
                                    <span class="mega-item-text">
                                      Engage with citizens and staff at scale{" "}
                                    </span>
                                  </span>
                                </a>
                              </li>

                              <li>
                                <a class="dropdown-item d-flex" href="/FinTech">
                                  <span class="mega-item-icon">
                                    <img
                                      src="https://toku.co/wp-content/uploads/2022/12/Frame-463394-1.svg"
                                      alt="Fintech"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">Fintech</span>
                                    <span class="mega-item-text">
                                      Build seamless digital experiences for
                                      your finance, banking, or investment
                                      platform
                                    </span>
                                  </span>
                                </a>
                              </li>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/sharing-economy"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src="https://toku.co/wp-content/uploads/2022/12/Frame-463395-1.svg"
                                      alt="Sharing Economy "
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Sharing Economy{" "}
                                    </span>
                                    <span class="mega-item-text">
                                      Boost your operational efficiency and
                                      communications{" "}
                                    </span>
                                  </span>
                                </a>
                              </li>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/insurance-services"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src="https://toku.co/wp-content/uploads/2022/12/Frame-463376.svg"
                                      alt="Insurance Services "
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Insurance Services{" "}
                                    </span>
                                    <span class="mega-item-text">
                                      Create a better experience for your agents
                                      and customers{" "}
                                    </span>
                                  </span>
                                </a>
                              </li>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/hostpitality"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src="https://toku.co/wp-content/uploads/2022/12/Frame-4633966.svg"
                                      alt="Travel &amp; Hospitality "
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Travel &amp; Hospitality{" "}
                                    </span>
                                    <span class="mega-item-text">
                                      Create an omnichannel hospitality
                                      experience{" "}
                                    </span>
                                  </span>
                                </a>
                              </li>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="retail-ecommerce"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src="https://toku.co/wp-content/uploads/2022/12/Frame-4633992.svg"
                                      alt="Retail &amp; eCommerce "
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Retail &amp; eCommerce{" "}
                                    </span>
                                    <span class="mega-item-text">
                                      Create exceptional buying experiences with
                                      less friction and more convenience
                                    </span>
                                  </span>
                                </a>
                              </li>
                            </ul>
                            <ul class="mega-col">
                              <div class="mega-col-title  big">BY USE CASE</div>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/number-masking"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src="https://toku.co/wp-content/uploads/2022/12/Frame-46341.svg"
                                      alt="Number Masking "
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Number Masking{" "}
                                    </span>
                                    <span class="mega-item-text">
                                      Anonymise personal numbers and protect
                                      your users{" "}
                                    </span>
                                  </span>
                                </a>
                              </li>

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/user-varification"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src="https://toku.co/wp-content/uploads/2022/12/Frame-46340.svg"
                                      alt="User Verification "
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      User Verification{" "}
                                    </span>
                                    <span class="mega-item-text">
                                      Verify legitimate users on a range of
                                      channels{" "}
                                    </span>
                                  </span>
                                </a>
                              </li>
                            </ul>{" "}
                          </div>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="/consutling">
                            Consulting
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="/why-kasooko">
                            Why Kasookoo
                          </a>
                        </li>
                        <li class="nav-item dropdown has-megamenu">
                          <a
                            class="nav-link  dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                          >
                            Resources
                          </a>
                          <div class="dropdown-menu megamenu" role="menu">
                            <ul class="mega-col">
                              

                              <li>
                                <a
                                  class="dropdown-item d-flex"
                                  href="/customer"
                                >
                                  <span class="mega-item-icon">
                                    <img
                                      src="https://toku.co/wp-content/uploads/2022/12/Customer-stories.svg"
                                      alt="Customer Stories"
                                    />
                                  </span>
                                  <span class="mega-content">
                                    <span class="mega-item-title">
                                      Customer Stories
                                    </span>
                                    <span class="mega-item-text">
                                      See how Kasooko customer experience
                                      solutions help enterprises succeed
                                    </span>
                                  </span>
                                </a>
                              </li>
                            </ul>{" "}
                          </div>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="about-us">
                            About Us
                          </a>
                        </li>
                        <li class="nav-item dropdown has-megamenu">
	<a class="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown">Support</a>
	<div class="dropdown-menu megamenu" role="menu" >
		<ul class="mega-col">



<li>
	<a class="dropdown-item d-flex" href="help-center">
					<span class="mega-item-icon">
				<img src="https://toku.co/wp-content/uploads/2022/12/Frame-463391.svg" alt="Help Centre   "/>
			</span>
				<span class="mega-content">
            <span class="mega-item-title">Help Centre   </span>
							<span class="mega-item-text">Need help? Get support, read our FAQs, and find info on our products</span>
			        </span>
	</a>
</li>
</ul>	</div>
</li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>

              <div class="col-auto px-3 d-none d-sm-flex ms-auto me-0">
                <a
                  href="/contact-us"
                  class="btn btn-big btn-yellow border-radius-25 mb-0 "
                >
                  Contact Us
                </a>
              </div>

              <nav class="col-auto navbar d-flex d-xxl-none align-items-center me-0 ms-auto ms-sm-2">
                <button
                  class="navbar-toggler"
                  type="button"
                  role="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasNavbar"
                  aria-controls="offcanvasNavbar"
                  style={{ border: "none", outline: "none" }}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span class="navbar-toggler-icon visually-hidden"></span>
                </button>
              </nav>
            </div>
          </div>
        </header>

        <div class="element-canvas">
          <div
            class="offcanvas d-xxl-none"
            tabindex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div class="offcanvas-body">
              <ul class="navbar-nav justify-content-end flex-grow-1 w-lg-50 mx-auto">
                <li class="nav-item dropdown has-megamenu">
                  <a
                    class="nav-link  dropdown-toggle"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    Products
                  </a>
                  <div class="dropdown-menu megamenu" role="menu">
                    <ul class="mega-col">
                      <div class="mega-col-title  big">CUSTOMER ENGAGEMENT</div>

                      <li>
                        <a class="dropdown-item d-flex" href="">
                          <span class="mega-item-icon">
                            <img
                              src="https://toku.co/wp-content/uploads/2022/12/Frame-46325.svg"
                              alt="Contact Centre"
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">Contact Centre</span>
                            <span class="mega-item-text">
                              Build a bespoke 360 customer experience view{" "}
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a class="dropdown-item d-flex" href="">
                          <span class="mega-item-icon">
                            <img
                              src="https://toku.co/wp-content/uploads/2022/12/Frame-46326.svg"
                              alt="Campaign Manager"
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Campaign Manager
                            </span>
                            <span class="mega-item-text">
                              Compose, configure, and launch bulk messaging
                              campaigns{" "}
                            </span>
                          </span>
                        </a>
                      </li>
                      <div class="mega-col-title  big">BUSINESS TELEPHONY</div>

                      <li>
                        <a class="dropdown-item d-flex" href="">
                          <span class="mega-item-icon">
                            <img
                              src="https://toku.co/wp-content/uploads/2022/12/Frame-46327.svg"
                              alt="Toku for Microsoft Teams "
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Kasooko for Microsoft Teams{" "}
                            </span>
                            <span class="mega-item-text">
                              Softphone to ditch the deskphone{" "}
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a class="dropdown-item d-flex" href="">
                          <span class="mega-item-icon">
                            <img
                              src="https://toku.co/wp-content/uploads/2022/12/Frame-46328.svg"
                              alt="Toku for Zoom Phone"
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Kasooko for Zoom Phone
                            </span>
                            <span class="mega-item-text">
                              All-in-one business communications platform{" "}
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>
                    <ul class="mega-col">
                      <div class="mega-col-title  big">
                        EMBEDDED COMMUNICATION
                      </div>
                      <div class="mega-col-title ">VOICE</div>

                      <li>
                        <a class="dropdown-item d-flex" href="">
                          <span class="mega-item-icon">
                            <img
                              src="https://toku.co/wp-content/uploads/2022/12/Frame-463339.svg"
                              alt="Programmable Voice"
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Programmable Voice
                            </span>
                            <span class="mega-item-text">
                              Embed voice calls in your product
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a class="dropdown-item d-flex" href="">
                          <span class="mega-item-icon">
                            <img
                              src="https://toku.co/wp-content/uploads/2022/12/Frame-46333.svg"
                              alt="In-App Voice Calling"
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              In-App Voice Calling
                            </span>
                            <span class="mega-item-text">
                              Enable voice calling in your app{" "}
                            </span>
                          </span>
                        </a>
                      </li>
                      <div class="mega-col-title ">MESSAGING</div>

                      <li>
                        <a class="dropdown-item d-flex" href="">
                          <span class="mega-item-icon">
                            <img
                              src="https://toku.co/wp-content/uploads/2022/12/Messaging.svg"
                              alt="Programmable Messaging"
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Programmable Messaging
                            </span>
                            <span class="mega-item-text">
                              Build the messaging experience you want{" "}
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>{" "}
                  </div>
                </li>
                <li class="nav-item dropdown has-megamenu">
                  <a
                    class="nav-link  dropdown-toggle"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    Solutions
                  </a>
                  <div class="dropdown-menu megamenu" role="menu">
                    <ul class="mega-col">
                      <div class="mega-col-title  big">BY INDUSTRY</div>

                      <li>
                        <a class="dropdown-item d-flex" href="">
                          <span class="mega-item-icon">
                            <img
                              src="https://toku.co/wp-content/uploads/2022/12/Frame-463393-1.svg"
                              alt="Government Solutions"
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Government Solutions
                            </span>
                            <span class="mega-item-text">
                              Engage with citizens and staff at scale{" "}
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a class="dropdown-item d-flex" href="">
                          <span class="mega-item-icon">
                            <img
                              src="https://toku.co/wp-content/uploads/2022/12/Frame-463394-1.svg"
                              alt="Fintech"
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">Fintech</span>
                            <span class="mega-item-text">
                              Build seamless digital experiences for your
                              finance, banking, or investment platform
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/sharing-economy"
                        >
                          <span class="mega-item-icon">
                            <img
                              src="https://toku.co/wp-content/uploads/2022/12/Frame-463395-1.svg"
                              alt="Sharing Economy "
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Sharing Economy{" "}
                            </span>
                            <span class="mega-item-text">
                              Boost your operational efficiency and
                              communications{" "}
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/insurance-services"
                        >
                          <span class="mega-item-icon">
                            <img
                              src="https://toku.co/wp-content/uploads/2022/12/Frame-463376.svg"
                              alt="Insurance Services "
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Insurance Services{" "}
                            </span>
                            <span class="mega-item-text">
                              Create a better experience for your agents and
                              customers{" "}
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/hospitality"
                        >
                          <span class="mega-item-icon">
                            <img
                              src="https://toku.co/wp-content/uploads/2022/12/Frame-4633966.svg"
                              alt="Travel &amp; Hospitality "
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Travel &amp; Hospitality{" "}
                            </span>
                            <span class="mega-item-text">
                              Create an omnichannel hospitality experience{" "}
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/retail-ecommerce"
                        >
                          <span class="mega-item-icon">
                            <img
                              src="https://toku.co/wp-content/uploads/2022/12/Frame-4633992.svg"
                              alt="Retail &amp; eCommerce "
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Retail &amp; eCommerce{" "}
                            </span>
                            <span class="mega-item-text">
                              Create exceptional buying experiences with less
                              friction and more convenience
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>
                    <ul class="mega-col">
                      <div class="mega-col-title  big">BY USE CASE</div>

                      <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/number-masking"
                        >
                          <span class="mega-item-icon">
                            <img
                              src="https://toku.co/wp-content/uploads/2022/12/Frame-46341.svg"
                              alt="Number Masking "
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">Number Masking </span>
                            <span class="mega-item-text">
                              Anonymise personal numbers and protect your users{" "}
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/user-varification"
                        >
                          <span class="mega-item-icon">
                            <img
                              src="https://toku.co/wp-content/uploads/2022/12/Frame-46340.svg"
                              alt="User Verification "
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              User Verification{" "}
                            </span>
                            <span class="mega-item-text">
                              Verify legitimate users on a range of channels{" "}
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>{" "}
                  </div>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/consutling">
                    Consulting
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/why-kasooko">
                    Why Kasooko
                  </a>
                </li>
                <li class="nav-item dropdown has-megamenu">
                  <a
                    class="nav-link  dropdown-toggle"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    Resources
                  </a>
                  <div class="dropdown-menu megamenu" role="menu">
                    <ul class="mega-col">
                     

                      <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/customers"
                        >
                          <span class="mega-item-icon">
                            <img
                              src="https://toku.co/wp-content/uploads/2022/12/Customer-stories.svg"
                              alt="Customer Stories"
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">
                              Customer Stories
                            </span>
                            <span class="mega-item-text">
                              See how Kasooko customer experience solutions help
                              enterprises succeed
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>{" "}
                  </div>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/about-us">
                    About Us
                  </a>
                </li>
                <li class="nav-item dropdown has-megamenu">
                  <a
                    class="nav-link  dropdown-toggle"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    Support
                  </a>
                  <div class="dropdown-menu megamenu" role="menu">
                    <ul class="mega-col">
                      

                      <li>
                        <a
                          class="dropdown-item d-flex"
                          href="/help-center"
                        >
                          <span class="mega-item-icon">
                            <img
                              src="https://toku.co/wp-content/uploads/2022/12/Frame-463391.svg"
                              alt="Help Centre   "
                            />
                          </span>
                          <span class="mega-content">
                            <span class="mega-item-title">Help Centre </span>
                            <span class="mega-item-text">
                              Need help? Get support, read our FAQs, and find
                              info on our products
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>{" "}
                  </div>
                </li>
              </ul>
              <div class="offcanvas-body-footer text-center my-3">
                <a
                  href="/contact-us"
                  class="btn btn-big btn-yellow border-radius-50 px-5 mx-auto ms-md-0 "
                  target=""
                >
                  Contact us
                </a>
              </div>
            </div>
          </div>
        </div>

       {
        props.first_section && <div
        id="hero"
        class="container-fluid text-white position-relative num-hero-1 hero-contact_center pb-5 "
      >
        <div class="container pb-md-5 mb-md-5">
          <style></style>
          <div class="row align-items-center pb-md-5 mb-2 mb-md-4">
            <div class="col-12 col-lg-6">
              <div class="w-100 text-center text-md-start">
                <div class="d-block mb-3">
                  <h1 class="style-h5">{props.extra_heading}</h1>
                </div>
                <div class="d-block mb-4">
                  <h2 class="style-h1">
                    {props.heading}
                  </h2>
                </div>
                <div class="d-block mb-4">
                  <div class="p-big">
                    <p>
                      {props.text}
                    </p>
                  </div>
                </div>
                <div class="d-block text-center text-md-start">
                  <button class="btn btn-big btn-yellow border-radius-50 px-5 mx-auto ms-md-0" onClick={()=>{setIsFormOpen(!isFormOpen)}}>
                    Talk to an Expert
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 text-center">
              <img
                class="mb-md-2 mb-lg-0 mx-auto mx-md-0 mw-100 my-5 my-sm-0"
                src={props.img}
                alt="Frame 46308"
              />
            </div>
          </div>
        </div>
      </div>
       } 
      </main>

      {/* <div className="text-white" id='first-section' style={{zIndex:'100'}}>
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-sm-6 welcome-text-div h-100">
                            <div className="row h-100">
                                <div className="col-11  h-100 d-flex flex-column justify-content-center pl-3">
                                  <p className="xm-font bold">{props.extra_heading}</p>
                                    <p id='main-text' className="xxxl-font bold">{props.heading}</p>
                                    <p className='xxsm-font mt-3 semi-bold'>{props.text}</p>
                                    <span><button className='talk-btn mt-3 bold'>Talk to an Expert</button></span>

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 welcome-img-div">
                            <div className='d-flex h-100 justify-content-center align-items-center position-relative'>
                                <img className='' src={props.img} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
        
                <ContactForm/>
        
    </div> */}

      {/* <div id="hero" class="container-fluid text-white position-relative num-hero-1 hero-contact_center pb-5 ">
        
        <div class="container pb-md-5 mb-md-5">
       
<div class="row align-items-center pb-md-5 mb-2 mb-md-4">
    <div class="col-12 col-lg-6">
        <div class="w-100 text-center text-md-start">
            <div class="d-block mb-3">
                <h1 class="style-h5">{props.extra_heading}</h1>
            </div>
            <div class="d-block mb-4">
                <h2 class="style-h1">{props.heading}</h2>
            </div>
            <div class="d-block mb-4">
                <div class="p-big"><p>{props.text}</p>
</div>
            </div>
                            <div class="d-block text-center text-md-start">
                    <a href="#Solutions" class="btn btn-big btn-yellow border-radius-50 px-5 mx-auto ms-md-0" target="" data-bs-toggle="modal" data-bs-target="#Solutions">Talk to a Toku Expert</a>
                </div>
                    </div>
    </div>
    <div class="col-12 col-lg-6 text-center">
                    <img class="mb-md-2 mb-lg-0 mx-auto mx-md-0 mw-75 my-5 my-sm-0" src={props.img}/>
            </div>
</div>
</div></div> */}


  {
    isFormOpen && <ContactForm ContactCenter ={props.contact_center_form} setIsFormOpen ={setIsFormOpen} />
  }
    </div>
  );
};

export default Navbar;
