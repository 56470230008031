import "./whykasookoo.css";
import Navbar from "../../components/navbar/Navbar";
import consulting_img from "../../assets/images/WHY MAIN.png";
import headphone from "../../assets/images/headphones.svg";
import voice from "../../assets/images/voice-recorder (1).svg";
import speaker from "../../assets/images/speaker.svg";
import send from "../../assets/images/send.svg";
import STAT from "../../assets/images/statistics.svg";

import email from "../../assets/images/email.svg";
import tick from "../../assets/images/true.svg";
import con from "../../assets/images/connectivity.svg";
import call from "../../assets/images/call.svg";
import sur from "../../assets/images/surface1.svg";
import l1 from "../../assets/images/l1.png";
import l2 from "../../assets/images/l2.png";
import l3 from "../../assets/images/l3.png";
import l4 from "../../assets/images/l4.png";
import l5 from "../../assets/images/l5.png";
import NET from "../../assets/images/NETWORK.png";
import curve from "../../assets/images/tailord-why.png";
import PROG from "../../assets/images/GOV-SOL.png";
import HELP from "../../assets/images/HELP AGENT.png";
import Footer from "../../components/footer/Footer";
import ban from "../../assets/images/Group 1188.png";

import { useState } from "react";
export default function WhyKasookoo() {
  var list_accordion_6 = [
    {
      title: "Building a connected Africa",
      text: "<p>Simplifying your expansion into Africa, Kasookoo serves as your trusted communications technology provider, granting you access to our extensive enterprise telco network across the region. Seamlessly expand your operations with ease and efficiency.</a></p>\n",
      image: NET,
      bottom_arrow_link: "",
    },
    {
      title: "Tailored to your requirement",
      text: "<p>Collaborate with our team of technology experts to tailor our contact center platform or voice and messaging APIs, creating a fully customized customer engagement solution that precisely aligns with your unique requirements</a></p>\n",
      image: curve,
      bottom_arrow_link: "",
    },
    {
      title: "Programmable Communications",
      text: "<p>Unleash innovative communication channels for your customers through our comprehensive suite of programmable APIs, seamlessly integrated with your platform and our robust telco network</a></p>\n<p>&nbsp;</p>\n",
      image: PROG,
      bottom_arrow_link: "",
    },
    {
      title: "On-demand support, no matter where you are",
      text: "<p>No matter the nature of your requirements, our team of dedicated experts based in Africa is available 24/7 to assist you with your communication needs. Count on us to ensure you can consistently deliver the best possible customer experience.</a></p>\n",
      image: HELP,
      bottom_arrow_link: "",
    },
  ];

  return (
    <div className="why-kasookoo">
      <Navbar
        extra_heading="WHY KASOOKOO"
        heading="Your trusted partner for cloud communications solutions that deliver superior customer experiences in Africa."
        img={consulting_img}
      ></Navbar>

      <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h6 class="style-h6">
                  TRUSTED BY LEADING ORGANISATIONS IN APAC{" "}
                </h6>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-2"
        class="w-100 position-relative block-logos pb-3 hide-slick-dots-on-desktop"
      >
        <style></style>

        <div class="w-100 section-logos-slider py-3 py-md-5 bg- ">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12 text-center slider-logos slick-initialized slick-slider slick-dotted">
                <div class="slick-list draggable">
                  <div
                    class="slick-track"
                    style={{
                      opacity: "1",
                      width: "1060px",
                      transform: "translate3d(0px, 0px, 0px)",
                    }}
                  >
                    <div
                      class="slide-logo px-3 slick-slide slick-current slick-active"
                      data-slick-index="0"
                      aria-hidden="false"
                      style={{ width: "212px" }}
                      tabindex="0"
                      role="tabpanel"
                      id="slick-slide00"
                      aria-describedby="slick-slide-control00"
                    >
                      <img
                        loading="lazy"
                        src={l1}
                        alt="Group 6720"
                        class="m-auto d-block w-100 h-auto middle-box"
                      />
                    </div>
                    <div
                      class="slide-logo px-3 slick-slide slick-active"
                      data-slick-index="1"
                      aria-hidden="false"
                      tabindex="0"
                      role="tabpanel"
                      id="slick-slide01"
                      aria-describedby="slick-slide-control01"
                      style={{ width: "212px" }}
                    >
                      <img
                        loading="lazy"
                        src={l2}
                        alt="MicrosoftTeams-image (55)"
                        class="m-auto d-block w-100 h-auto middle-box"
                      />
                    </div>
                    <div
                      class="slide-logo px-3 slick-slide slick-active"
                      data-slick-index="2"
                      aria-hidden="false"
                      tabindex="0"
                      role="tabpanel"
                      id="slick-slide02"
                      aria-describedby="slick-slide-control02"
                      style={{ width: "212px" }}
                    >
                      <img
                        loading="lazy"
                        src={l3}
                        alt="Group 6722"
                        class="m-auto d-block w-100 h-auto middle-box"
                      />
                    </div>
                    <div
                      class="slide-logo px-3 slick-slide slick-active"
                      data-slick-index="3"
                      aria-hidden="false"
                      tabindex="0"
                      role="tabpanel"
                      id="slick-slide03"
                      aria-describedby="slick-slide-control03"
                      style={{ width: "212px" }}
                    >
                      <img
                        loading="lazy"
                        src={l4}
                        alt="Group 6748"
                        class="m-auto d-block w-100 h-auto middle-box"
                      />
                    </div>
                    <div
                      class="slide-logo px-3 slick-slide slick-active"
                      data-slick-index="4"
                      aria-hidden="false"
                      tabindex="0"
                      role="tabpanel"
                      id="slick-slide04"
                      aria-describedby="slick-slide-control04"
                      style={{ width: "212px" }}
                    >
                      <img
                        loading="lazy"
                        src={l5}
                        alt="Group 6749"
                        class="m-auto d-block w-100 h-auto middle-box"
                      />
                    </div>
                  </div>
                </div>

                <ul class="slick-dots" role="tablist">
                  <li class="slick-active" role="presentation">
                    <button
                      type="button"
                      role="tab"
                      id="slick-slide-control00"
                      aria-controls="slick-slide00"
                      aria-label="1 of 1"
                      tabindex="0"
                      aria-selected="true"
                    >
                      1
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-3"
        class="w-100 position-relative block-top_title pt-5"
      >
        <style></style>
        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-purple">
              <div class="col-12 text-center ">
                <div class="top-title ms-0 mx-md-auto">
                  <img
                    src="https://toku.co/wp-content/uploads/2023/01/Group-6.svg"
                    alt="OUR OFFERING"
                    class="me-2"
                  />
                  OUR OFFERING{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-4"
        class="w-100 position-relative block-centered_title text-start text-md-center"
      >
        <style></style>

        <div class="w-100 section-centered_title text-start text-md-center py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h1 class="style-h2">
                  Scalable cloud communications solutions for enterprises
                </h1>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-5"
        class="w-100 position-relative block-centered_title text-start text-md-center"
      >
        <style></style>

        <div class="w-100 section-centered_title text-start text-md-center py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h1 class="p-small">
                  By selecting Kasookoo as your communications provider in
                  Africa, you gain access to our tailored enterprise technology
                  solutions, coupled with carrier-grade telco connectivity
                  across 17 countries. This ensures seamless, high-quality
                  communications for your business operations.
                </h1>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AccordionComponent />

      <div
        id="num-section-7"
        class="w-100 position-relative block-top_title pt-5"
      >
        <style></style>
        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-turquoise">
              <div class="col-12 text-center ">
                <div class="top-title ms-0 mx-md-auto">
                  <img src={STAT} alt="OUR STATISTICS" class="me-2" />
                  OUR STATISTICS{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-8"
        class="w-100 position-relative block-centered_title text-start text-md-center text-white"
      >
        <style></style>

        <div class="w-100 section-centered_title text-start text-md-center text-white py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h1 class="style-h2">
                  What sets Kasookoo apart from the rest?
                </h1>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-9"
        class="w-100 position-relative block-centered_title text-start text-md-center pb-5 text-white"
      >
        <style></style>

        <div class="w-100 section-centered_title text-start text-md-center pb-5 text-white py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h1 class="p-small">
                  Kasookoo aims to revolutionize customer engagement for
                  enterprises in Africa by introducing a comprehensive suite of
                  communication solutions that prioritize the highest quality
                  connectivity. Our goal is to redefine how businesses interact
                  with their customers in the region.
                </h1>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-10"
        class="w-100 position-relative block-4_columns_with_counters pb-5"
      >
        <style></style>
        <style></style>
        <div class="container section-4_columns_with_counters strip-counter">
          <div class="row strip-counter-style-default pb-5">
            <div class="col-12 col-lg py-4 py-lg-2 style-col-3-border">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-flex mb-2">
                  <span class="count">17</span>
                  <span class="count-text-after"></span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 p-small">
                APAC countries with PSTN replacement{" "}
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 style-col-3-border">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-flex mb-2">
                  <span class="count">20</span>
                  <span class="count-text-after">K +</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 p-small">
                concurrent agents using our contact centre platform{" "}
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 style-col-3-border">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-flex mb-2">
                  <span class="count">200</span>
                  <span class="count-text-after">M +</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 p-small">
                SMS messages sent per year{" "}
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 style-col-3-border">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-flex mb-2">
                  <span class="count">250</span>
                  <span class="count-text-after">M +</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 p-small">
                minutes of Voice traffic per year{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-11"
        class="w-100 position-relative block-top_title pt-5"
      >
        <style></style>
        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-purple">
              <div class="col-12 text-center ">
                <div class="top-title ms-0 mx-md-auto">
                  <img
                    src="https://toku.co/wp-content/uploads/2023/01/Group-1000004753.svg"
                    alt="OUR PRODUCTS"
                    class="me-2"
                  />
                  OUR PRODUCTS{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-12"
        class="w-100 position-relative block-centered_title text-start text-md-center"
      >
        <style></style>

        <div class="w-100 section-centered_title text-start text-md-center py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h1 class="style-h1">
                  Powering cloud-based communications from a unified source
                </h1>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-13"
        class="w-100 position-relative block-centered_title text-start text-md-center"
      >
        <style></style>

        <div class="w-100 section-centered_title text-start text-md-center py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row  ">
              <div class="col-12 mx-auto col-md-8">
                <h1 class="p-small">
                  Access enterprise-grade software and Africa connectivity
                  through a single provider, unlocking a suite of solutions
                  meticulously crafted to deliver seamless customer engagement
                  experiences.
                </h1>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-14"
        class="w-100 position-relative block-3_items_in_row_with_icon_title_text_link "
      >
        <style></style>
        <div class="container section-3_items_in_row_with_icon_title_text_link">
          <div class="row rseh-md ">
            <div class="col-12 col-md-4">
              <div
                class="style-item rs_equalHeight"
                style={{ height: "314.5px" }}
              >
                <a
                  href="/contact-center"
                  class="d-grid h-100 w-100 position-relative"
                >
                  <div class="d-block w-100">
                    <img src={headphone} alt="Contact Centre" />
                  </div>
                  <div class="d-block w-100 style-h4 pb-3">Contact Centre </div>
                  <div class="d-block w-75 w-md-100 p-small pb-2">
                    A custom, cloud-based contact center solution that is
                    tailored to the needs of companies operating in Africa
                  </div>
                  <div class="d-block w-100 text-end position-relative mb-0 mt-auto align-bottom">
                    <div class="el-arrow-link d-inline-block"></div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div
                class="style-item rs_equalHeight"
                style={{ height: "314.5px" }}
              >
                <a
                  href="/programmable-voice"
                  class="d-grid h-100 w-100 position-relative"
                >
                  <div class="d-block w-100">
                    <img src={voice} alt="Programmable Voice" />
                  </div>
                  <div class="d-block w-100 style-h4 pb-3">
                    Programmable Voice{" "}
                  </div>
                  <div class="d-block w-75 w-md-100 p-small pb-2">
                    Deliver a personalized, seamless, and engaging voice
                    experience across all channels with programmable calling.
                  </div>
                  <div class="d-block w-100 text-end position-relative mb-0 mt-auto align-bottom">
                    <div class="el-arrow-link d-inline-block"></div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div
                class="style-item rs_equalHeight"
                style={{ height: "314.5px" }}
              >
                <a
                  href="/programmable-messaging"
                  class="d-grid h-100 w-100 position-relative"
                >
                  <div class="d-block w-100">
                    <img src={email} alt="Programmable Messaging" />
                  </div>
                  <div class="d-block w-100 style-h4 pb-3">
                    Programmable Messaging{" "}
                  </div>
                  <div class="d-block w-75 w-md-100 p-small pb-2">
                    Send your messages from Africa to anywhere in the world with
                    confidence.
                  </div>
                  <div class="d-block w-100 text-end position-relative mb-0 mt-auto align-bottom">
                    <div class="el-arrow-link d-inline-block"></div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-15"
        class="w-100 position-relative block-3_items_in_row_with_icon_title_text_link "
      >
        <style></style>
        <div class="container section-3_items_in_row_with_icon_title_text_link">
          <div class="row rseh-md ">
            <div class="col-12 col-md-4">
              <div
                class="style-item rs_equalHeight"
                style={{ height: "341.5px" }}
              >
                <a
                  href="in-app-voice-calling"
                  class="d-grid h-100 w-100 position-relative"
                >
                  <div class="d-block w-100">
                    <img src={speaker} alt="In-App Voice Calling" />
                  </div>
                  <div class="d-block w-100 style-h4 pb-3">
                    In-App Voice Calling{" "}
                  </div>
                  <div class="d-block w-75 w-md-100 p-small pb-2">
                    Use a single voice SDK to add inbound and outbound calls to
                    your app, so you can keep customer experiences in one place.
                  </div>
                  <div class="d-block w-100 text-end position-relative mb-0 mt-auto align-bottom">
                    <div class="el-arrow-link d-inline-block"></div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div
                class="style-item rs_equalHeight"
                style={{ height: "341.5px" }}
              >
                <a
                  href="/user-verification"
                  class="d-grid h-100 w-100 position-relative"
                >
                  <div class="d-block w-100">
                    <img src={tick} alt="User Verification" />
                  </div>
                  <div class="d-block w-100 style-h4 pb-3">
                    User Verification{" "}
                  </div>
                  <div class="d-block w-75 w-md-100 p-small pb-2">
                    Enhance your customer validation process, boost conversion
                    rates, and effectively combat fraud using our comprehensive
                    set of APIs.
                  </div>
                  <div class="d-block w-100 text-end position-relative mb-0 mt-auto align-bottom">
                    <div class="el-arrow-link d-inline-block"></div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div
                class="style-item rs_equalHeight"
                style={{ height: "341.5px" }}
              >
                <a
                  href="/campaign-manager"
                  class="d-grid h-100 w-100 position-relative"
                >
                  <div class="d-block w-100">
                    <img src={send} alt="Campaign Manager" />
                  </div>
                  <div class="d-block w-100 style-h4 pb-3">
                    Campaign Manager{" "}
                  </div>
                  <div class="d-block w-75 w-md-100 p-small pb-2">
                    Send, track, and manage all of your sales, marketing, or
                    operational SMS campaigns at scale with a single platform.
                  </div>
                  <div class="d-block w-100 text-end position-relative mb-0 mt-auto align-bottom">
                    <div class="el-arrow-link d-inline-block"></div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-16"
        class="w-100 position-relative block-3_items_in_row_with_icon_title_text_link "
      >
        <style></style>
        <div class="container section-3_items_in_row_with_icon_title_text_link">
          <div class="row rseh-md ">
            <div class="col-12 col-md-4">
              <div
                class="style-item rs_equalHeight"
                style={{ height: "314.5px" }}
              >
                <a
                  href="/number-masking"
                  class="d-grid h-100 w-100 position-relative"
                >
                  <div class="d-block w-100">
                    <img src={sur} alt="Number Masking" />
                  </div>
                  <div class="d-block w-100 style-h4 pb-3">Number Masking </div>
                  <div class="d-block w-75 w-md-100 p-small pb-2">
                    Safeguard your users by implementing a number masking API
                    that anonymizes or adapts caller IDs, providing an
                    additional layer of protection.
                  </div>
                  <div class="d-block w-100 text-end position-relative mb-0 mt-auto align-bottom">
                    <div class="el-arrow-link d-inline-block"></div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div
                class="style-item rs_equalHeight"
                style={{ height: "314.5px" }}
              >
                <a
                  href="/microsoft-teams"
                  class="d-grid h-100 w-100 position-relative"
                >
                  <div class="d-block w-100">
                    <img src={call} alt="Toku for Microsoft Teams" />
                  </div>
                  <div class="d-block w-100 style-h4 pb-3">
                    Kasookoo for Microsoft Teams{" "}
                  </div>
                  <div class="d-block w-75 w-md-100 p-small pb-2">
                    Seamlessly integrate your telephony system with the MS Teams
                    console to enable efficient call management.
                  </div>
                  <div class="d-block w-100 text-end position-relative mb-0 mt-auto align-bottom">
                    <div class="el-arrow-link d-inline-block"></div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div
                class="style-item rs_equalHeight"
                style={{ height: "314.5px" }}
              >
                <a
                  href="https://toku.co/zoom-phone/"
                  class="d-grid h-100 w-100 position-relative"
                >
                  <div class="d-block w-100">
                    <img src={con} alt="Toku for Zoom Phone" />
                  </div>
                  <div class="d-block w-100 style-h4 pb-3">
                    Kasookoo for Zoom Phone{" "}
                  </div>
                  <div class="d-block w-75 w-md-100 p-small pb-2">
                    Get high-quality voice calls and video conferencing in
                    Africa with Zoom Phone.
                  </div>
                  <div class="d-block w-100 text-end position-relative mb-0 mt-auto align-bottom">
                    <div class="el-arrow-link d-inline-block"></div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-md-none bg-archer bg-archer-up">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-17"
        class="w-100 position-relative block-top_title pt-5"
      >
        <style></style>
        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-turquoise">
              <div class="col-12 text-center ">
                <div class="top-title ms-0 mx-md-auto">
                  <img
                    src="https://toku.co/wp-content/uploads/2023/01/Group-1000004751.svg"
                    alt="OUR CUSTOMERS"
                    class="me-2"
                  />
                  OUR CUSTOMERS{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-18"
        class="w-100 position-relative block-centered_title text-start text-md-center"
      >
        <style></style>

        <div class="w-100 section-centered_title text-start text-md-center py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">Hear from our customers"</h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-19"
        class="w-100 position-relative block-centered_title text-start text-md-center pt-0"
      >
        <style></style>

        <div class="w-100 section-centered_title text-start text-md-center pt-0 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h1 class="p-small">
                  We collaborate with businesses worldwide, delivering optimal
                  solutions for customer engagement in Africa.
                </h1>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-20"
        class="w-100 position-relative block-recommendations_slider py-5"
      >
        <style></style>
        <div class="w-100 section-recommendations_slider">
          <div class="container py-5">
            <div
              class="col-12 mx-auto slider-recommendations col-lg-9 slick-initialized slick-slider slick-dotted"
              data-slider-autoplay="1"
              data-slider-autoplay_speed="5000"
              data-slider-arrows="1"
              data-slider-dots="1"
              data-slider-infinite_scroll="1"
              data-slider-slides_to_show="1"
              data-slider-slides_to_scroll="1"
            >
              <button
                class="slick-prev slick-arrow"
                aria-label="Previous"
                type="button"
              >
                Previous
              </button>
              <div class="slick-list draggable">
                <div
                  class="slick-track"
                  style={{
                    opacity: "1",
                    width: "6608px",
                    transform: "translate3d(-2832px, 0px, 0px)",
                  }}
                >
                  <div
                    class="slide px-md-5 slick-slide slick-cloned"
                    data-slick-index="-1"
                    id=""
                    aria-hidden="true"
                    tabindex="-1"
                    style={{ width: "944px" }}
                  >
                    <div class="w-100 d-block px-md-5">
                      <blockquote class="text-center text-md-start mb-5 mb-md-3 px-md-5 px-lg-1 style-blue">
                        <p>
                          For most cases, Kasookoo has successfully kept the
                          undelivered SMS rate to between 5-6%. This is a good
                          number compared to other delivery rates I’ve seen in
                          my experience. I think it’s because Kasookoo has a
                          better presence in Southeast Asia. That means they
                          have better reach, and higher deliverability. And we
                          are seeing that across our campaigns.
                        </p>
                      </blockquote>
                    </div>
                    <div class="w-100 d-block px-md-3 px-lg-5">
                      <div class="row text-center">
                        <div class="col-12 col-md-6 text-md-end">
                          <div class="w-100 d-block">
                            <div class="name">Ajith Kumar</div>
                          </div>
                          <div class="w-100 d-block">
                            <div class="role">
                              CRM Lead, SEA at Lenskart at Lenskart
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 order-md-first">
                          <img
                            src="https://toku.co/wp-content/uploads/2023/01/Group-6720.svg"
                            alt="Group 6720"
                            class="m-auto mt-1 ms-md-0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="slide px-md-5 slick-slide"
                    data-slick-index="0"
                    aria-hidden="true"
                    style={{ width: "944px" }}
                    tabindex="-1"
                    role="tabpanel"
                    id="slick-slide10"
                    aria-describedby="slick-slide-control10"
                  >
                    <div class="w-100 d-block px-md-5">
                      <blockquote class="text-center text-md-start mb-5 mb-md-3 px-md-5 px-lg-1 style-turquoise">
                        <p>
                          Kasookoo’s solution helped us to scale down on our
                          manpower requirements, which was very helpful for us.
                        </p>
                      </blockquote>
                    </div>
                    <div class="w-100 d-block px-md-3 px-lg-5">
                      <div class="row text-center">
                        <div class="col-12 col-md-4 text-md-start order-md-last">
                          <div class="w-100 d-block">
                            <div class="name">Celine Que</div>
                          </div>
                          <div class="w-100 d-block">
                            <div class="role">
                              Head of Service Excellence, Singapore
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-5 order-md-first pb-3 pb-md-0">
                          <img
                            src="https://toku.co/wp-content/uploads/2023/01/MicrosoftTeams-image-55-e1673865545146.png"
                            alt="MicrosoftTeams-image (55)"
                            class="m-auto mt-1 ms-md-0"
                          />
                        </div>
                        <div class="col-12 col-md-3 text-md-end">
                          <div class="w-100 d-block">
                            <img
                              src="https://toku.co/wp-content/uploads/2022/12/Frame-46311.png"
                              alt="Celine Que"
                              class="mx-auto ms-md-auto me-md-2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="slide px-md-5 slick-slide"
                    data-slick-index="1"
                    aria-hidden="true"
                    tabindex="-1"
                    role="tabpanel"
                    id="slick-slide11"
                    aria-describedby="slick-slide-control11"
                    style={{ width: "944px" }}
                  >
                    <div class="w-100 d-block px-md-5">
                      <blockquote class="text-center text-md-start mb-5 mb-md-3 px-md-5 px-lg-1 style-turquoise">
                        <p>
                          Thanks to Kasookoo’s deep knowledge of our business
                          needs, they have helped create bespoke solutions for a
                          complex foodpanda ecosystem across 11 markets and
                          different business lines. Many more exciting solutions
                          are in the works for our partners and customers, and
                          we look forward to implementing them with Kasookoo’s
                          technical support.
                        </p>
                      </blockquote>
                    </div>
                    <div class="w-100 d-block px-md-3 px-lg-5">
                      <div class="row text-center">
                        <div class="col-12 col-md-6 text-md-end">
                          <div class="w-100 d-block">
                            <div class="name">Hossein Entekhabi</div>
                          </div>
                          <div class="w-100 d-block">
                            <div class="role">
                              Senior Director, Central Operations (APAC) at
                              foodpanda
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 order-md-first">
                          <img
                            src="https://toku.co/wp-content/uploads/2023/01/Group-6722.svg"
                            alt="Group 6722"
                            class="m-auto mt-1 ms-md-0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="slide px-md-5 slick-slide slick-current slick-active"
                    data-slick-index="2"
                    aria-hidden="false"
                    tabindex="0"
                    role="tabpanel"
                    id="slick-slide12"
                    aria-describedby="slick-slide-control12"
                    style={{ width: "944px" }}
                  >
                    <div class="w-100 d-block px-md-5">
                      <blockquote class="text-center text-md-start mb-5 mb-md-3 px-md-5 px-lg-1 style-blue">
                        <p>
                          For most cases, Kasookoo has successfully kept the
                          undelivered SMS rate to between 5-6%. This is a good
                          number compared to other delivery rates I’ve seen in
                          my experience. I think it’s because Kasookoo has a
                          better presence in Southeast Asia. That means they
                          have better reach, and higher deliverability. And we
                          are seeing that across our campaigns.
                        </p>
                      </blockquote>
                    </div>
                    <div class="w-100 d-block px-md-3 px-lg-5">
                      <div class="row text-center">
                        <div class="col-12 col-md-6 text-md-end">
                          <div class="w-100 d-block">
                            <div class="name">Ajith Kumar</div>
                          </div>
                          <div class="w-100 d-block">
                            <div class="role">
                              CRM Lead, SEA at Lenskart at Lenskart
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 order-md-first">
                          <img
                            src="https://toku.co/wp-content/uploads/2023/01/Group-6720.svg"
                            alt="Group 6720"
                            class="m-auto mt-1 ms-md-0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="slide px-md-5 slick-slide slick-cloned"
                    data-slick-index="3"
                    id=""
                    aria-hidden="true"
                    tabindex="-1"
                    style={{ width: "944px" }}
                  >
                    <div class="w-100 d-block px-md-5">
                      <blockquote class="text-center text-md-start mb-5 mb-md-3 px-md-5 px-lg-1 style-turquoise">
                        <p>
                          Kasookoo’s solution helped us to scale down on our
                          manpower requirements, which was very helpful for us.
                        </p>
                      </blockquote>
                    </div>
                    <div class="w-100 d-block px-md-3 px-lg-5">
                      <div class="row text-center">
                        <div class="col-12 col-md-4 text-md-start order-md-last">
                          <div class="w-100 d-block">
                            <div class="name">Celine Que</div>
                          </div>
                          <div class="w-100 d-block">
                            <div class="role">
                              Head of Service Excellence, Singapore
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-5 order-md-first pb-3 pb-md-0">
                          <img
                            src="https://toku.co/wp-content/uploads/2023/01/MicrosoftTeams-image-55-e1673865545146.png"
                            alt="MicrosoftTeams-image (55)"
                            class="m-auto mt-1 ms-md-0"
                          />
                        </div>
                        <div class="col-12 col-md-3 text-md-end">
                          <div class="w-100 d-block">
                            <img
                              src="https://toku.co/wp-content/uploads/2022/12/Frame-46311.png"
                              alt="Celine Que"
                              class="mx-auto ms-md-auto me-md-2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="slide px-md-5 slick-slide slick-cloned"
                    data-slick-index="4"
                    id=""
                    aria-hidden="true"
                    tabindex="-1"
                    style={{ width: "944px" }}
                  >
                    <div class="w-100 d-block px-md-5">
                      <blockquote class="text-center text-md-start mb-5 mb-md-3 px-md-5 px-lg-1 style-turquoise">
                        <p>
                          Thanks to Kasookoo’s deep knowledge of our business
                          needs, they have helped create bespoke solutions for a
                          complex foodpanda ecosystem across 11 markets and
                          different business lines. Many more exciting solutions
                          are in the works for our partners and customers, and
                          we look forward to implementing them with Kasookoo’s
                          technical support.
                        </p>
                      </blockquote>
                    </div>
                    <div class="w-100 d-block px-md-3 px-lg-5">
                      <div class="row text-center">
                        <div class="col-12 col-md-6 text-md-end">
                          <div class="w-100 d-block">
                            <div class="name">Hossein Entekhabi</div>
                          </div>
                          <div class="w-100 d-block">
                            <div class="role">
                              Senior Director, Central Operations (APAC) at
                              foodpanda
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 order-md-first">
                          <img
                            src="https://toku.co/wp-content/uploads/2023/01/Group-6722.svg"
                            alt="Group 6722"
                            class="m-auto mt-1 ms-md-0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="slide px-md-5 slick-slide slick-cloned"
                    data-slick-index="5"
                    id=""
                    aria-hidden="true"
                    tabindex="-1"
                    style={{ width: "944px" }}
                  >
                    <div class="w-100 d-block px-md-5">
                      <blockquote class="text-center text-md-start mb-5 mb-md-3 px-md-5 px-lg-1 style-blue">
                        <p>
                          For most cases, Kasookoo has successfully kept the
                          undelivered SMS rate to between 5-6%. This is a good
                          number compared to other delivery rates I’ve seen in
                          my experience. I think it’s because Kasookoo has a
                          better presence in Southeast Asia. That means they
                          have better reach, and higher deliverability. And we
                          are seeing that across our campaigns.
                        </p>
                      </blockquote>
                    </div>
                    <div class="w-100 d-block px-md-3 px-lg-5">
                      <div class="row text-center">
                        <div class="col-12 col-md-6 text-md-end">
                          <div class="w-100 d-block">
                            <div class="name">Ajith Kumar</div>
                          </div>
                          <div class="w-100 d-block">
                            <div class="role">
                              CRM Lead, SEA at Lenskart at Lenskart
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 order-md-first">
                          <img
                            src="https://toku.co/wp-content/uploads/2023/01/Group-6720.svg"
                            alt="Group 6720"
                            class="m-auto mt-1 ms-md-0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                class="slick-next slick-arrow"
                aria-label="Next"
                type="button"
              >
                Next
              </button>
              <ul class="slick-dots" role="tablist">
                <li class="" role="presentation">
                  <button
                    type="button"
                    role="tab"
                    id="slick-slide-control10"
                    aria-controls="slick-slide10"
                    aria-label="1 of 3"
                    tabindex="-1"
                  >
                    1
                  </button>
                </li>
                <li role="presentation" class="">
                  <button
                    type="button"
                    role="tab"
                    id="slick-slide-control11"
                    aria-controls="slick-slide11"
                    aria-label="2 of 3"
                    tabindex="-1"
                  >
                    2
                  </button>
                </li>
                <li role="presentation" class="slick-active">
                  <button
                    type="button"
                    role="tab"
                    id="slick-slide-control12"
                    aria-controls="slick-slide12"
                    aria-label="3 of 3"
                    tabindex="0"
                    aria-selected="true"
                  >
                    3
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-21"
        class="w-100 position-relative block-top_title pt-5"
      >
        <style></style>
        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 text-center ">
                <div class="top-title ms-0 mx-md-auto">
                  <img
                    src="https://toku.co/wp-content/uploads/2023/01/Group-1000004749.svg"
                    alt="OUR AWARDS"
                    class="me-2"
                  />
                  OUR AWARDS{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-22"
        class="w-100 position-relative block-centered_title pb-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  An award-winning cloud communications provider in Africa,
                  recognized for our innovative solutions and outstanding
                  customer service.
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-23"
        class="w-100 position-relative block-2_items_in_row_with_image_title_text_link pb-4"
      >
        <style></style>
        <style></style>
        <div class="container section-2_items_in_row_with_image_title_text_link style-default">
          <div class="row rseh-md pb-4">
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href=""
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "159px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img
                        src="https://toku.co/wp-content/uploads/2022/12/sbr-tea-03-1.png"
                        alt="Business Technology Excellence Awards"
                      />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        Business Technology Excellence Awards{" "}
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        API - Telecommunications SBR Technology Excellence
                        Awards 2022{" "}
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href=""
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "159px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img
                        src="https://toku.co/wp-content/uploads/2022/12/2022-Award-Logo-Toku-1.png"
                        alt="2022 Southeast Asia Cloud Contact Center Services"
                      />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        2022 Southeast Asia Cloud Contact Center Services{" "}
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Entrepreneurial Company of the Year Award{" "}
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-24"
        class="w-100 position-relative block-cta_strip_no_image text-white "
      >
        <style></style>

        <div class="w-100 section-cta_strip_no_image">
          <div class="container">
            <div class="row text-white py-4">
              <div class="col-12 text-center p-md-5">
                <div class="d-block w-100">
                  <h2 class="style-h2 mb-4">
                    Looking to reimagine your customer experience?
                  </h2>
                </div>
                <div class="d-block w-100">
                  <a
                    href="#Solutions"
                    class="mx-auto btn btn-yellow"
                    target=""
                    data-bs-toggle="modal"
                    data-bs-target="#Solutions"
                  >
                    Talk to an Expert{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export function AccordionComponent() {
  const list_accordion_6 = [
    {
      title: "Building a connected Africa",
      text: "<p>Simplifying your expansion into Africa, Kasookoo serves as your trusted communications technology provider, granting you access to our extensive enterprise telco network across the region. Seamlessly expand your operations with ease and efficiency.</a></p>\n",
      image: { NET },
      bottom_arrow_link: "",
    },
    {
      title: "Tailored to your requirement",
      text: "<p>Collaborate with our team of technology experts to tailor our contact center platform or voice and messaging APIs, creating a fully customized customer engagement solution that precisely aligns with your unique requirements</a></p>\n",
      image: "{ curve }",
      bottom_arrow_link: "",
    },
    {
      title: "Programmable Communications",
      text: "<p>Unleash innovative communication channels for your customers through our comprehensive suite of programmable APIs, seamlessly integrated with your platform and our robust telco network</a></p>\n<p>&nbsp;</p>\n",
      image: { PROG },
      bottom_arrow_link: "",
    },
    {
      title: "On-demand support, no matter where you are",
      text: "<p>No matter the nature of your requirements, our team of dedicated experts based in Africa is available 24/7 to assist you with your communication needs. Count on us to ensure you can consistently deliver the best possible customer experience.</a></p>\n",
      image: { HELP },
      bottom_arrow_link: "",
    },
  ];
  const [activeItem, setActiveItem] = useState(0);

  const showItem = (index) => {
    setActiveItem(index);
  };
  return (
    <div
      id="num-section-6"
      className="w-100 position-relative block-accordion_list py-5"
    >
      <style>{`
        /* CSS styles here */
      `}</style>
      <div className="w-100 section-accordion_list ng-scope">
        <div className="container">
          <div className="row d-md-none">
            <div className="accordion accordion-flush" id="accordionFlush-6">
              {list_accordion_6.map((item, index) => (
                <div className="accordion-item" key={index}>
                  <h2
                    className="accordion-header"
                    id={`flush-heading${index + 1}`}
                  >
                    <button
                      className={`accordion-button ${
                        index === activeItem ? "style-h4" : "collapsed style-h4"
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#flush-collapse${index + 1}`}
                      aria-expanded={index === 0 ? "true" : "false"}
                      aria-controls={`flush-collapse${index + 1}`}
                    >
                      {item.title}
                    </button>
                  </h2>
                  <div
                    id={`flush-collapse${index + 1}`}
                    className={`accordion-collapse collapse ${
                      index === 0 ? "show" : ""
                    }`}
                    aria-labelledby={`flush-heading${index + 1}`}
                    data-bs-parent="#accordionFlush-6"
                  >
                    <div className="accordion-body">
                      <div className="d-block w-100">
                        <img
                          decoding="async"
                          loading="lazy"
                          className="aligncenter size-full size-full mb-3 mb-md-0"
                          src={item.image}
                          alt={item.title}
                        />
                      </div>
                      <div
                        className="d-block w-100 p-small"
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="row d-none d-md-flex">
            <div className="col-6">
              <h3 className="style-h3 mb-5">Our approach to delivery</h3>
              {list_accordion_6.map((item, index) => (
                <button
                  className={`list-accordion style-h4 w-100 ng-binding ng-scope ${
                    index === activeItem ? "active" : "text-dark"
                  }`}
                  key={index}
                  onClick={() => showItem(index)}
                  style={{ boxShadow: "none" }}
                >
                  {item.title}
                </button>
              ))}
            </div>
            <div className="col-6">
              <div className="d-block w-100 mb-2">
                <img
                  decoding="async"
                  loading="lazy"
                  className="size-full"
                  src={list_accordion_6[activeItem].image}
                  alt={list_accordion_6[activeItem].title}
                />
              </div>
              <div className="d-block w-100">
                <div className="style-h4 mb-2">
                  {list_accordion_6[activeItem].title}
                </div>
              </div>
              <div
                className="d-block w-100 ng-binding"
                dangerouslySetInnerHTML={{
                  __html: list_accordion_6[activeItem].text,
                }}
              />
              <a
                className="btn btn-arrow text-start ng-binding ng-hide"
                href={list_accordion_6[activeItem].bottom_arrow_link}
                target=""
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
