import React from "react";
import "./contactcenter.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import optimize_customer_img from "../../assets/images/optimize-customer-img.png";
import bottomleftimg from "../../assets/images/7-2.png"
import bottomrightimg from "../../assets/images/7-1.png"

import designedforyou from "../../assets/images/designedforyourbusiness.svg";
import scalingsolution from "../../assets/images/scalingsolution.svg";
import securitypriority from "../../assets/images/securitypriority.svg";
import dedicatedsupport from "../../assets/images/dedicatedsupport.svg";

import contactcenterhelpdesk from "../../assets/images/contact-center-help-desk.svg"
import targeticon from "../../assets/images/target.svg"
import worldwideicon from "../../assets/images/worldwide-contact-center-icon.svg"
import interactionicon from "../../assets/images/contact-center-interaction-icon.svg"
export default function ContactCenter() {
  return (
    <div className="contact-center">
      <Navbar
        heading="Optimize customer engagement on a large scale."
        text="With our tailored cloud communication and customer engagement solutions, Kasookoo empowers businesses to create personalized and seamless customer experiences (CX) that are perfectly suited for the digital era."
        img={optimize_customer_img}
      ></Navbar>
      <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title "
      >
        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h6 class="style-h6">
                  TRUSTED BY LEADING ORGANISATIONS WORLDWIDE
                </h6>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-2"
        class="w-100 position-relative block-logos pb-3 hide-slick-dots-on-desktop"
      >
        <style></style>

        <div class="w-100 section-logos-slider py-3 py-md-5 bg- ">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12 text-center slider-logos slick-initialized slick-slider slick-dotted d-flex">
                <div class="slick-list draggable">
                  <div
                    class="slick-track"
                    style={{
                      opacity: "1",
                      width: "1060px",
                      transform: "translate3d(0px, 0px, 0px)",
                    }}
                  >
                    <div
                      class="slide-logo px-3 slick-slide slick-current slick-active"
                      data-slick-index="0"
                      aria-hidden="false"
                      style={{ width: "212px" }}
                      tabindex="0"
                      role="tabpanel"
                      id="slick-slide00"
                      aria-describedby="slick-slide-control00"
                    >
                      <img
                        loading="lazy"
                        src="https://toku.co/wp-content/uploads/2022/10/2.png"
                        alt="2"
                        class="m-auto d-block w-100 h-auto middle-box"
                      />
                    </div>
                    <div
                      class="slide-logo px-3 slick-slide slick-active"
                      data-slick-index="1"
                      aria-hidden="false"
                      tabindex="0"
                      role="tabpanel"
                      id="slick-slide01"
                      aria-describedby="slick-slide-control01"
                      style={{ width: "212px" }}
                    >
                      <img
                        loading="lazy"
                        src="https://toku.co/wp-content/uploads/2022/10/5.png"
                        alt="5"
                        class="m-auto d-block w-100 h-auto middle-box"
                      />
                    </div>
                    <div
                      class="slide-logo px-3 slick-slide slick-active"
                      data-slick-index="2"
                      aria-hidden="false"
                      tabindex="0"
                      role="tabpanel"
                      id="slick-slide02"
                      aria-describedby="slick-slide-control02"
                      style={{ width: "212px" }}
                    >
                      <img
                        loading="lazy"
                        src="https://toku.co/wp-content/uploads/2022/10/6.png"
                        alt="6"
                        class="m-auto d-block w-100 h-auto middle-box"
                      />
                    </div>
                    <div
                      class="slide-logo px-3 slick-slide slick-active"
                      data-slick-index="3"
                      aria-hidden="false"
                      tabindex="0"
                      role="tabpanel"
                      id="slick-slide03"
                      aria-describedby="slick-slide-control03"
                      style={{ width: "212px" }}
                    >
                      <img
                        loading="lazy"
                        src="https://toku.co/wp-content/uploads/2022/10/3-2.png"
                        alt="3-2"
                        class="m-auto d-block w-100 h-auto middle-box"
                      />
                    </div>
                    <div
                      class="slide-logo px-3 slick-slide slick-active"
                      data-slick-index="4"
                      aria-hidden="false"
                      tabindex="0"
                      role="tabpanel"
                      id="slick-slide04"
                      aria-describedby="slick-slide-control04"
                      style={{ width: "212px" }}
                    >
                      <img
                        loading="lazy"
                        src="https://toku.co/wp-content/uploads/2022/10/4-2.png"
                        alt="4-2"
                        class="m-auto d-block w-100 h-auto middle-box"
                      />
                    </div>
                  </div>
                </div>

                <ul class="slick-dots" role="tablist">
                  <li class="slick-active" role="presentation">
                    <button
                      type="button"
                      role="tab"
                      id="slick-slide-control00"
                      aria-controls="slick-slide00"
                      aria-label="1 of 1"
                      tabindex="0"
                      aria-selected="true"
                    >
                      1
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-3"
        class="w-100 position-relative block-centered_title align-items-center text-center pt-5 pb-2"
      >
        <style></style>

        <div class="w-100 section-centered_title align-items-center text-center pt-5 pb-2 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  An exclusive contact center solution designed specifically for
                  enterprises.
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-4"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary pt-5"
      >
        <style></style>

        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-4">
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "328px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={designedforyou}
                      alt="Group 1000005103"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Designed for your Enterprise</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      We empower you to construct the ideal solution that
                      perfectly aligns with your business requirements
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "328px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={scalingsolution}
                      alt="Group 1000005101"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">
                      Scaling Solutions, Connecting Globally
                    </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Attain swift market penetration, heightened operational
                      effectiveness, and reduced latency across Africa and
                      beyond, harnessing virtual numbers that cover over 120
                      countries, ensuring worldwide accessibility.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "328px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={dedicatedsupport}
                      alt="Group 1000004943"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Dedicated Customer Support</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Our team provides comprehensive assistance, meaning that
                      whether you require support for your platform or network,
                      you only need to reach out to us.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "328px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={securitypriority}
                      alt="Group 1000005102"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Making security a top priority</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      When we build our solutions, we take encryption and
                      security very seriously. We use industry-standard
                      encryption methods and security measures to protect our
                      users' data.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 d-md-none bg-archer bg-archer-up">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div
        id="num-section-5"
        class="w-100 position-relative block-topic_center_repeater "
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-turquoise">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Vector-6.svg"
                      alt="ADMIN VIEW"
                    />{" "}
                    ADMIN VIEW{" "}
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Managing a contact center has never been simpler than this
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Effortlessly handle your agents, customize call flows,
                      access real-time productivity reports, and supervise
                      recorded and live calls through a centralized admin view.
                    </p>
                    <p>
                      <a
                        href="#ContactCentre"
                        data-bs-toggle="modal"
                        data-bs-target="#ContactCentre"
                      >
                        Schedule a demo today!
                      </a>
                    </p>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Frame-46308-2.png"
                  alt="Frame 46308"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>

            <div class="row small_issues pt-5 py-md-5 style-turquoise">
              <div class="col-12 col-md-4 pb-4 pb-md-1 px-4 px-md-3">
                <div class="title-entry px-md-4 mb-3">
                  Experience real-time visibility into your call center
                  operations.
                </div>
                <div class="text-entry px-md-4">
                  Experience real-time visibility into your call center
                  operations.
                </div>
              </div>
              <div class="col-12 col-md-4 pb-4 pb-md-1 px-4 px-md-3">
                <div class="title-entry px-md-4 mb-3">
                  Integrated Resource Management
                </div>
                <div class="text-entry px-md-4">
                  Streamline your resource management by easily configuring call
                  routing, distribution, and agent status.
                </div>
              </div>
              <div class="col-12 col-md-4 pb-4 pb-md-1 px-4 px-md-3">
                <div class="title-entry px-md-4 mb-3">
                  Effortless Monitoring
                </div>
                <div class="text-entry px-md-4">
                  Enable whispering, eavesdropping, and recording capabilities
                  to enhance agent development and improve customer experience.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-6"
        class="w-100 position-relative block-top_title pt-5 pb-2"
      >
        <style></style>
        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-purple">
              <div class="col-12 text-center ">
                <div class="top-title ms-0 mx-md-auto">
                  <img
                    src={contactcenterhelpdesk}
                    alt="AGENT VIEW"
                    class="me-2"
                  />
                  AGENT VIEW{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-7"
        class="w-100 position-relative block-centered_title text-start text-md-center"
      >
        <style></style>

        <div class="w-100 section-centered_title text-start text-md-center py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Equip your workforce with the <br /> right communication tools
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-8"
        class="w-100 position-relative block-centered_title text-start text-md-center"
      >
        <style></style>

        <div class="w-100 section-centered_title text-start text-md-center py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Engage with customers using various communication channels
                  such as calls, SMS, WhatsApp, and other over-the-top (OTT) and
                  in-app platforms, while seamlessly documenting all
                  interactions through a browser-based Contact Centre as a
                  Service (CCaaS) solution.
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-9"
        class="w-100 position-relative block-block_text pb-5 pt-4"
      >
        <style></style>
        <div class="container section-block_text">
          <div class="row">
            <div class="col-12">
              <p>&nbsp;</p>
              <p>
                <img
                  decoding="async"
                  loading="lazy"
                  class="aligncenter wp-image-5198 size-full"
                  src="https://toku.co/wp-content/uploads/2023/01/Group-1000004945.png"
                  alt=""
                  srcset="https://toku.co/wp-content/uploads/2023/01/Group-1000004945.png 1860w, https://toku.co/wp-content/uploads/2023/01/Group-1000004945-300x131.png 300w, https://toku.co/wp-content/uploads/2023/01/Group-1000004945-1024x448.png 1024w, https://toku.co/wp-content/uploads/2023/01/Group-1000004945-768x336.png 768w, https://toku.co/wp-content/uploads/2023/01/Group-1000004945-1536x671.png 1536w"
                  sizes="(max-width: 1860px) 100vw, 1860px"
                />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-10"
        class="w-100 position-relative block-centered_title text-start text-md-center pb-3"
      >
        <style></style>

        <div class="w-100 section-centered_title text-start text-md-center pb-3 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h3 class="style-h3">
                  Enhance customer engagement <br /> through desired
                  communication methods
                </h3>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-11"
        class="w-100 position-relative block-items_left_icon pb-5"
      >
        <style></style>
        <div class="w-100 section-items_left_icon">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 ">
                <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                  <img
                    src={targeticon}
                    alt="Connect with customers on multiple channels."
                    class="middle-box"
                  />
                </div>
                <div class="col pe-1">
                  <p class="p-small mb-0">
                    Engage with customers across a multitude of channels
                  </p>
                </div>
              </div>
              <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 ">
                <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                  <img
                    src={worldwideicon}
                    alt="Enable agents to work from anywhere. "
                    class="middle-box"
                  />
                </div>
                <div class="col pe-1">
                  <p class="p-small mb-0">
                    Empower workforce with the flexibility to work from any
                    location
                  </p>
                </div>
              </div>
              <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 ">
                <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                  <img
                    src={interactionicon}
                    alt="Seamless interactions for customers and agents."
                    class="middle-box"
                  />
                </div>
                <div class="col pe-1">
                  <p class="p-small mb-0">
                    Smooth and effortless interactions for both customers and
                    agents
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* for
        on click
        change








 */}
      <div class="w-100 d-md-none bg-archer bg-archer-up">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div
        id="num-section-13"
        class="w-100 position-relative block-topic_center_repeater "
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-green">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src={"https://toku.co/wp-content/uploads/2023/01/Vector-5.svg"}
                      alt="CRM INTEGRATION"
                    />{" "}
                    CRM INTEGRATION{" "}
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Access all your customer info instantly and make calls with
                    ease
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Access all your customer info instantly and make calls
                      with ease Leverage our seamless CRM integration to empower
                      agents with the same comprehensive call functionality
                      found within the embedded agent view in Salesforce.
                    </p>
                    <p>
                      <a href="#">Discover kosooko for Salesforce.</a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Image-42.png"
                  alt="Image 42"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>

            <div class="row small_issues pt-5 py-md-5 style-green">
              <div class="col-12 col-md-4 pb-4 pb-md-1 px-4 px-md-3">
                <div class="title-entry px-md-4 mb-3">
                  Instant <br /> Updates
                </div>
                <div class="text-entry px-md-4">
                  Keep your customer records up-to-date by updating them during
                  calls and syncing them automatically with your CRM.
                </div>
              </div>
              <div class="col-12 col-md-4 pb-4 pb-md-1 px-4 px-md-3">
                <div class="title-entry px-md-4 mb-3">
                  Process <br /> Integration
                </div>
                <div class="text-entry px-md-4">
                  Simple workflow management and calling schedule with instantly
                  joining of your call queues.
                </div>
              </div>
              <div class="col-12 col-md-4 pb-4 pb-md-1 px-4 px-md-3">
                <div class="title-entry px-md-4 mb-3">
                  Integrating Kasookoo <br /> Features in your CRM
                </div>
                <div class="text-entry px-md-4">
                  Stay organized and efficient by completing all of your
                  call-related tasks in one place
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 d-sm-none bg-archer bg-archer-down">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 87C0 87 80.5 46.5 200 46.5C319.5 46.5 400 87 400 87V0H0V87Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div class="w-100 d-none d-sm-block bg-archer bg-archer-down">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div
        id="num-section-14"
        class="w-100 position-relative block-top_title pt-5"
      >
        <style></style>
        <div class="w-100 section-top_title">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 text-center ">
                <div class="top-title ms-0 mx-md-auto">
                  <img
                    src={contactcenterhelpdesk}
                    alt="CONTACT CENTRE ENRICHMENT"
                    class="me-2"
                  />
                  CONTACT CENTRE ENRICHMENT{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-15"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">Boost your Contact Center </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-16"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Enhance customer communications and deliver a seamless
                  customer experience by integrating our all-inclusive suite of
                  communication solutions into your contact center platform.
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-17"
        class="w-100 position-relative block-image_side_and_text_side_repeater "
      >
        <style></style>
        <div class="w-100 section-row_repeater">
          <div class="container">
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/In-app-voice.png"
                  alt="In app voice"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Embedded Voice Calling </h3>{" "}
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Transform the way customers connect with your contact
                      center by integrating inbound and outbound VoIP calls into
                      your application. With our voice SDK, you can offer an
                      alternative to PSTN calls, allowing customers to call your
                      contact center directly from your app, without the need to
                      switch interfaces.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block">
                  <ul class="ul-v mb-4">
                    <li class="p-small mb-3">
                      Get rid of from unwanted calls{" "}
                    </li>
                    <li class="p-small mb-3">Boost app engagement </li>
                    <li class="p-small mb-3">
                      Ensure security of customer data{" "}
                    </li>
                  </ul>{" "}
                </div>
                <div class="w-100 d-block">
                  <a href="#" class="btn btn-arrow text-start" target="">
                    Start Embedding Calls Into Your App{" "}
                  </a>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  pe-lg-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Campaign-Manager.png"
                  alt="Campaign Manager"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  ps-lg-5">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Organizing Campaigns</h3>{" "}
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Our Campaign Manager is a powerful tool that allows you to
                      send and manage SMS campaigns from your contact center.
                    </p>
                    <p>&nbsp;</p>
                  </div>
                </div>
                <div class="w-100 d-block">
                  <ul class="ul-v mb-4">
                    <li class="p-small mb-3">Bulk SMS feature</li>
                    <li class="p-small mb-3">
                      Use tokens to draft personalized messages
                    </li>
                    <li class="p-small mb-3">
                      Uploading contact details at a large scale
                    </li>
                  </ul>{" "}
                </div>
                <div class="w-100 d-block">
                  <a href="" class="btn btn-arrow text-start" target="">
                    Launch a New SMS Campaign Today!{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 d-md-none bg-archer bg-archer-up">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div
        id="num-section-18"
        class="w-100 position-relative block-recommendations_slider py-4"
      >
        <style></style>
        <div class="w-100 section-recommendations_slider">
          <div class="container py-5">
            <div
              class="col-12 mx-auto slider-recommendations col-lg-9 slick-initialized slick-slider"
              data-slider-autoplay=""
              data-slider-autoplay_speed="2000"
              data-slider-arrows="1"
              data-slider-dots=""
              data-slider-infinite_scroll=""
              data-slider-slides_to_show="1"
              data-slider-slides_to_scroll="1"
            >
              <button
                class="slick-prev slick-arrow slick-disabled"
                aria-label="Previous"
                type="button"
                aria-disabled="true"
              >
                Previous
              </button>
              <div class="slick-list draggable">
                <div
                  class="slick-track"
                  style={{
                    opacity: "1",
                    width: "1944px",
                    transform: "translate3d(0px, 0px, 0px)",
                  }}
                >
                  <div
                    class="slide px-md-5 slick-slide slick-current slick-active"
                    data-slick-index="0"
                    aria-hidden="false"
                    style={{ width: "972px" }}
                    tabindex="0"
                  >
                    <div class="w-100 d-block px-md-5">
                      <blockquote class="text-center text-md-start mb-5 mb-md-3 px-md-5 px-lg-1 style-blue">
                        <p>
                          Thanks to Toku’s deep knowledge of our business needs,
                          they have helped create bespoke solutions for a
                          complex foodpanda ecosystem across 11 markets and
                          different business lines. Many more exciting solutions
                          are in the works for our partners and customers, and
                          we look forward to implementing them with Toku’s
                          technical support.
                        </p>
                      </blockquote>
                    </div>
                    <div class="w-100 d-block px-md-3 px-lg-5">
                      <div class="row text-center">
                        <div class="col-12 col-md-6 text-md-end">
                          <div class="w-100 d-block">
                            <div class="name">Hossein Entekhabi</div>
                          </div>
                          <div class="w-100 d-block">
                            <div class="role">
                              Senior Director, Central Operations (APAC) at
                              foodpanda
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 order-md-first">
                          <img
                            src="https://toku.co/wp-content/uploads/2022/10/6.png"
                            alt="6"
                            class="m-auto mt-1 ms-md-0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="slide px-md-5 slick-slide"
                    data-slick-index="1"
                    aria-hidden="true"
                    tabindex="-1"
                    style={{ width: "972px" }}
                  >
                    <div class="w-100 d-block px-md-5">
                      <blockquote class="text-center text-md-start mb-5 mb-md-3 px-md-5 px-lg-1 style-blue">
                        <p>
                          Toku is one of the few companies in the region with a
                          contact center platform powered by their own telephony
                          network. Its flexible and scalable Omnichannel Contact
                          Center offers clients a complete and localized
                          solution to enhance their customer engagement.
                        </p>
                      </blockquote>
                    </div>
                    <div class="w-100 d-block px-md-3 px-lg-5">
                      <div class="row text-center">
                        <div class="col-12 col-md-6 text-md-end">
                          <div class="w-100 d-block">
                            <div class="name">Krishna Baidya</div>
                          </div>
                          <div class="w-100 d-block">
                            <div class="role">
                              Industry Analyst at Frost &amp; Sullivan
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 order-md-first">
                          <img
                            src="https://toku.co/wp-content/uploads/2022/12/FS-Blue-Text.png"
                            alt="F&amp;S Blue Text"
                            class="m-auto mt-1 ms-md-0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                class="slick-next slick-arrow"
                aria-label="Next"
                type="button"
                aria-disabled="false"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-19"
        class="w-100 position-relative block-cta_strip_2_images "
      >
        <style></style>

        <div class="w-100 section-cta_strip text-white py-0 py-md-3">
          <div class="container pt-5 pt-md-0">
            <div class="row align-items-center py-4 py-md-5">
              <div class="col-12 col-md-3 d-none d-md-flex image-one ">
                <img
                  src={bottomleftimg}
                  alt="Ready for a CCaaS that scales with your growth plans?"
                  class=""
                />
              </div>

              <div class="col-12 col-md-3 image-two  order-md-last ">
                <img
                  src={bottomrightimg}
                  alt="Ready for a CCaaS that scales with your growth plans?"
                  class="d-block mx-auto mx-md-0 mb-4 mb-md-0 w-75 w-md-auto"
                />
              </div>

              <div class="col-12 col-md-6 text-center">
                <div class="d-block w-100 px-md-5">
                  <h2 class="style-h2">
                    Looking for a CCaaS solution that can grow with your
                    business aspirations?
                  </h2>
                </div>
                <div class="d-block w-100 px-md-5">
                  <div class="p-big"></div>
                </div>
                <div class="d-block w-100 px-md-5 mt-3 text-center">
                  <a
                    href="#ContactCentre"
                    class="blact_btn mb-5 mb-md-0 mx-auto"
                    target=""
                    data-bs-toggle="modal"
                    data-bs-target="#ContactCentre"
                  >
                    Connect With Us{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
