import React, { useState } from 'react'
import './careers.css'
import Footer from '../../components/footer/Footer'
import customer_support from "../../assets/images/customer-support.jpeg";

import Navbar from "../../components/navbar/Navbar";
export default function Careers() {
    return (
        <div className='careers'>

            <Navbar
                heading="Optimize customer engagement on a large scale."
                text="With our tailored cloud communication and customer engagement solutions, Kasookoo empowers businesses to create personalized and seamless customer experiences (CX) that are perfectly suited for the digital era."
                img={customer_support}
                contact_center_form="false"
                first_section={true}
            ></Navbar>


            <div id="num-section-1" class="w-100 position-relative block-centered_title ">
                <style>
                </style>

                <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
                    <div class="container">
                        <div class="row ">
                            <div class="col-12 mx-auto col-md-8">
                                <h2 class="style-h2">Current Openings</h2>            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="num-section-2" class="w-100 position-relative block-block_text pb-0 text-left text-md-center">
                <style>
                </style>
                <div class="container section-block_text">
                    <div class="row">
                        <div class="col-12">
                            <p>Where can you make the most impact at Toku? If you’re not sure, or don’t see an available position that’s 100% up your alley, don’t worry! Just <a href="https://edit-the-link.toku.com">apply by dropping us a note</a>. We’ll be in touch once we have an opportunity for you.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="num-section-3" class="w-100 position-relative block-block_text mt-4">
                <style>
                </style>
                <div class="container careers-shortcode"><div class="row careers-shortcode-row "><div class="col-12">
                    <div class="careers-shortcode-item mb-4 p-3 p-md-4 d-block d-md-flex">
                        <div class="cs-content mb-4 mb-md-0">
                            <div class="p-small cs-title mb-3">Senior Marketing Executive</div>
                            <div class="cs-data">
                                <span class="department me-2 p-2">Marketing</span>
                                <span>CBD, Singapore</span>
                            </div>
                        </div>
                        <div class="cs-button my-auto">
                            <a href="https://toku.co/careers/senior-marketing-executive/" class="btn btn-yellow">View job</a>
                        </div>
                    </div>
                </div></div><div class="row careers-shortcode-row "><div class="col-12">
                    <div class="careers-shortcode-item mb-4 p-3 p-md-4 d-block d-md-flex">
                        <div class="cs-content mb-4 mb-md-0">
                            <div class="p-small cs-title mb-3">VP, Solution Architect</div>
                            <div class="cs-data">
                                <span class="department me-2 p-2">Product</span>
                                <span>KL Sentral, Malaysia</span>
                            </div>
                        </div>
                        <div class="cs-button my-auto">
                            <a href="https://toku.co/careers/solution-architect/" class="btn btn-yellow">View job</a>
                        </div>
                    </div>
                </div></div><div class="row careers-shortcode-row "><div class="col-12">
                    <div class="careers-shortcode-item mb-4 p-3 p-md-4 d-block d-md-flex">
                        <div class="cs-content mb-4 mb-md-0">
                            <div class="p-small cs-title mb-3">Digital Workplace Technician</div>
                            <div class="cs-data">
                                <span class="department me-2 p-2">Delivery</span>
                                <span>CBD, Singapore</span>
                            </div>
                        </div>
                        <div class="cs-button my-auto">
                            <a href="https://toku.co/careers/digital-workplace-technician/" class="btn btn-yellow">View job</a>
                        </div>
                    </div>
                </div></div><div class="row careers-shortcode-row  d-none "><div class="col-12">
                    <div class="careers-shortcode-item mb-4 p-3 p-md-4 d-block d-md-flex">
                        <div class="cs-content mb-4 mb-md-0">
                            <div class="p-small cs-title mb-3">Voice Engineer</div>
                            <div class="cs-data">
                                <span class="department me-2 p-2">Delivery</span>
                                <span>CBD, Singapore</span>
                            </div>
                        </div>
                        <div class="cs-button my-auto">
                            <a href="https://toku.co/careers/voice-engineer/" class="btn btn-yellow">View job</a>
                        </div>
                    </div>
                </div></div><div class="row careers-shortcode-row  d-none "><div class="col-12">
                    <div class="careers-shortcode-item mb-4 p-3 p-md-4 d-block d-md-flex">
                        <div class="cs-content mb-4 mb-md-0">
                            <div class="p-small cs-title mb-3">Software Developer</div>
                            <div class="cs-data">
                                <span class="department me-2 p-2">Delivery</span>
                                <span>CBD, Malaysia</span>
                            </div>
                        </div>
                        <div class="cs-button my-auto">
                            <a href="https://toku.co/careers/amazon-connect-developer/" class="btn btn-yellow">View job</a>
                        </div>
                    </div>
                </div></div><div class="row careers-shortcode-row  d-none "><div class="col-12">
                    <div class="careers-shortcode-item mb-4 p-3 p-md-4 d-block d-md-flex">
                        <div class="cs-content mb-4 mb-md-0">
                            <div class="p-small cs-title mb-3">QA Lead Engineer</div>
                            <div class="cs-data">
                                <span class="department me-2 p-2">Product</span>
                                <span>CBD, Singapore</span>
                            </div>
                        </div>
                        <div class="cs-button my-auto">
                            <a href="https://toku.co/careers/qa-engineer/" class="btn btn-yellow">View job</a>
                        </div>
                    </div>
                </div></div><div class="row careers-shortcode-row  d-none "><div class="col-12">
                    <div class="careers-shortcode-item mb-4 p-3 p-md-4 d-block d-md-flex">
                        <div class="cs-content mb-4 mb-md-0">
                            <div class="p-small cs-title mb-3">System Administrator</div>
                            <div class="cs-data">
                                <span class="department me-2 p-2">Infrastructure</span>
                                <span>CBD, Singapore</span>
                            </div>
                        </div>
                        <div class="cs-button my-auto">
                            <a href="https://toku.co/careers/system-administrator/" class="btn btn-yellow">View job</a>
                        </div>
                    </div>
                </div></div>
                    <div class="row">
                        <div class="col-12 text-center"><button class="btn btn-blue careers-show-more">Show more</button></div>
                    </div>
                </div><div class="container section-block_text">
                    <div class="row">
                        <div class="col-12">

                        </div>
                    </div>
                </div>
            </div>
            <div class="w-100 d-md-none bg-archer bg-archer-up">
                <svg width="400" height="87" viewBox="0 0 400 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z" fill="#f1f5fe"></path>
                </svg>
            </div>
            <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
                <svg width="2500" height="178" viewBox="0 0 2500 178" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z" fill="#f1f5fe"></path>
                </svg>
            </div>
            <div id="num-section-4" class="w-100 position-relative block-recommendations_slider py-5">



                

                <RecommendationSlider/>
            </div>
            <div class="w-100 d-sm-none bg-archer bg-archer-down">
                <svg width="400" height="87" viewBox="0 0 400 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 87C0 87 80.5 46.5 200 46.5C319.5 46.5 400 87 400 87V0H0V87Z" fill="#f1f5fe"></path>
                </svg>
            </div>
            <div class="w-100 d-none d-sm-block bg-archer bg-archer-down">
                <svg width="2500" height="178" viewBox="0 0 2500 178" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z" fill="#f1f5fe"></path>
                </svg>
            </div>
            <div id="num-section-5" class="w-100 position-relative block-centered_title ">
                <style>
                </style>

                <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
                    <div class="container">
                        <div class="row ">
                            <div class="col-12 mx-auto col-md-8">
                                <h2 class="style-h2">More than Just a Career</h2>            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="num-section-6" class="w-100 position-relative block-centered_title ">
                <style>
                </style>

                <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
                    <div class="container">
                        <div class="row ">
                            <div class="col-12 mx-auto col-md-8">
                                <p class="p-small">We are the future of work. At Toku, we believe in work policies that are forward-looking, flexible and inclusive. That's why we offer some attractive benefits that go beyond your job – like our hybrid work policy, comprehensive training and development scheme, and Pawternity leave. Here’s a taste of the smorgasbord of Toku benefits that await you when you join us.</p>            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="num-section-7" class="w-100 position-relative block-block_text pb-5">
                <style>
                </style>
                <div class="container section-block_text">
                    <div class="row">
                        <div class="col-12">
                            <p><img decoding="async" loading="lazy" class="aligncenter wp-image-2520 size-full" src="https://toku.co/wp-content/uploads/2023/01/Frame-46348-1.png" alt="" width="871" height="352" srcset="https://toku.co/wp-content/uploads/2023/01/Frame-46348-1.png 871w, https://toku.co/wp-content/uploads/2023/01/Frame-46348-1-300x121.png 300w, https://toku.co/wp-content/uploads/2023/01/Frame-46348-1-768x310.png 768w" sizes="(max-width: 871px) 100vw, 871px" /></p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="num-section-8" class="w-100 position-relative block-items_left_icon ">
                <style>
                </style>
                <div class="w-100 section-items_left_icon">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 pb-3">
                                <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                                    <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000005173.svg" alt="Healthcare 
coverage" class="middle-box" />
                                </div>
                                <div class="col pe-1">
                                    <p class="p-small mb-0">Healthcare
                                        coverage</p>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 pb-3">
                                <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                                    <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000005174.svg" alt="Learning &amp; 
development" class="middle-box" />
                                </div>
                                <div class="col pe-1">
                                    <p class="p-small mb-0">Learning &amp;
                                        development</p>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 pb-3">
                                <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                                    <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000005176-2.svg" alt="Flexible work 
environment" class="middle-box" />
                                </div>
                                <div class="col pe-1">
                                    <p class="p-small mb-0">Flexible work
                                        environment</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="num-section-9" class="w-100 position-relative block-items_left_icon ">
                <style>
                </style>
                <div class="w-100 section-items_left_icon">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 pb-3">
                                <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                                    <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000005172.svg" alt="Yearly bonus &amp; 
salary review" class="middle-box" />
                                </div>
                                <div class="col pe-1">
                                    <p class="p-small mb-0">Yearly bonus &amp;
                                        salary review</p>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 pb-3">
                                <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                                    <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000005175.svg" alt="Pawternity
leave" class="middle-box" />
                                </div>
                                <div class="col pe-1">
                                    <p class="p-small mb-0">Pawternity
                                        leave</p>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 pb-3">
                                <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                                    <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000005177.svg" alt="Wedding 
leave" class="middle-box" />
                                </div>
                                <div class="col pe-1">
                                    <p class="p-small mb-0">Wedding
                                        leave</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-100 d-md-none bg-archer bg-archer-up">
                <svg width="400" height="87" viewBox="0 0 400 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z" fill="#f1f5fe"></path>
                </svg>
            </div>
            <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
                <svg width="2500" height="178" viewBox="0 0 2500 178" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z" fill="#f1f5fe"></path>
                </svg>
            </div>
            <div id="num-section-10" class="w-100 position-relative block-topic_center_repeater ">
                <style>
                </style>
                <div class="w-100 section-topic_center_repeater">
                    <div class="container">
                        <div class="row align-items-center style-purple">
                            <div class="col-12 col-md-6 col-lg-4 side-one">
                                <div class="w-100 d-block">
                                    <div class="title-section-h2">Toku Culture Club</div>
                                </div>

                                <div class="w-100 d-block">
                                    <div class="entry p-small"><p>We’re serious about building a truly inclusive, productive, and welcoming culture, because we know this is what ultimately defines our company and shapes its future. The club has three core values shaping its identity to ensure that the company’s mission, vision, and values are integrated into everything that we do.</p>
                                    </div>
                                </div>

                                <div class="w-100 d-block mb-3 mb-md-0">
                                    <a href="#" class="btn btn-arrow text-start" target="">
                                        Join Us!                            </a>
                                </div>

                            </div>
                            <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                                <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000004919-1.png" alt="Group 1000004919" class="w-100 h-auto mx-auto my-2 img-class" />
                            </div>
                        </div>

                        <div class="row small_issues pt-5 py-md-5 style-purple">
                            <div class="col-12 col-md-4 pb-4 pb-md-1 px-4 px-md-3">
                                <div class="title-entry px-md-4 mb-3">
                                    Learning and development                            </div>
                                <div class="text-entry px-md-4">
                                </div>
                            </div>
                            <div class="col-12 col-md-4 pb-4 pb-md-1 px-4 px-md-3">
                                <div class="title-entry px-md-4 mb-3">
                                    Diversity, equality, and inclusion                            </div>
                                <div class="text-entry px-md-4">
                                </div>
                            </div>
                            <div class="col-12 col-md-4 pb-4 pb-md-1 px-4 px-md-3">
                                <div class="title-entry px-md-4 mb-3">
                                    Social responsibility                            </div>
                                <div class="text-entry px-md-4">
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div id="num-section-11" class="w-100 position-relative block-centered_title pt-4 pb-0">
                <style>
                </style>

                <div class="w-100 section-centered_title pt-4 pb-0 py-3 align-items-center text-left text-md-center">
                    <div class="container">
                        <div class="row ">
                            <div class="col-12 mx-auto col-md-8">
                                <h2 class="style-h2">Stuff We Believe In</h2>            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="num-section-12" class="w-100 position-relative block-centered_title pb-4">
                <style>
                </style>

                <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
                    <div class="container">
                        <div class="row ">
                            <div class="col-12 mx-auto col-md-8">
                                <p class="p-small">A little more about what gets us out of bed every morning – you know, the stuff that makes us tick.</p>            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="num-section-13" class="w-100 position-relative block-accordion_list pb-5">
                <AccordionComponent />
            </div>
            {/* <div id="num-section-14" class="w-100 position-relative block-centered_title pt-4">
            <style>
                            </style>
            
<div class="w-100 section-centered_title pt-4 py-3 align-items-center text-left text-md-center">
    <div class="container">
        <div class="row ">
            <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">Life at Toku</h2>            </div>
        </div>
    </div>
</div>
        </div> */}
            {/* <div id="num-section-15" class="w-100 position-relative block-slider_center ">
            <style>
                            </style>
            <div class="w-100 section-slider_center">
	<div class="container">
		<div class="row align-items-center swiper-container swiper-container-coverflow swiper-container-3d swiper-container-horizontal" style={{cursor: 'grab'}}>
            <div class="col-12 text-center slider_center swiper-wrapper " style={{transitionDuration: '0ms', transform: 'translate3d(-6200px, 0px, 0px)', perspectiveOrigin: '6800px 50%'}}><div class="slide-center swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active" style={{transitionDuration: '0ms', transform: 'translate3d(4400px, 0px, -2400px)rotateX(0deg) rotateY(0deg); zIndex: -7'}} data-swiper-slide-index="0">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Slide-Item-—-1.png" alt="Slide Item — 1" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div><div class="slide-center swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next" style={{transitionDuration: '0ms', transform: 'translate3d(3850px, 0px, -2100px) rotateX(0deg) rotateY(0deg)', zIndex: '-6'}} data-swiper-slide-index="1">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Slide-Item-—-1-1.png" alt="Slide Item — 1" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div><div class="slide-center swiper-slide swiper-slide-duplicate" style={{transitionDuration: '0ms', transform: 'translate3d(3300px, 0px, -1800px) rotateX(0deg) rotateY(0deg)', zIndex: '-5'}} data-swiper-slide-index="2">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Slide-Item-—-1-2.png" alt="Slide Item — 1" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div><div class="slide-center swiper-slide swiper-slide-duplicate" style={{transitionFuration: '0ms', transform: 'translate3d(2750px, 0px, -1500px) rotateX(0deg) rotateY(0deg)', zzIndex: '-4'}} data-swiper-slide-index="3">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Slide-Item-—-1-3.png" alt="Slide Item — 1" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div><div class="slide-center swiper-slide swiper-slide-duplicate" style={{transitionDuration:' 0ms', transform: 'translate3d(2200px, 0px, -1200px) rotateX(0deg) rotateY(0deg)', zIndex: '-3'}} data-swiper-slide-index="4">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Slide-Item-—-1-4.png" alt="Slide Item — 1" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div><div class="slide-center swiper-slide swiper-slide-duplicate" style={{transitionDuration: '0ms', transform: 'translate3d(1650px, 0px, -900px) rotateX(0deg) rotateY(0deg)', zIndex: '-2'}} data-swiper-slide-index="5">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Image-354.png" alt="Image 354" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div><div class="slide-center swiper-slide swiper-slide-duplicate" style={{transitionDuration: '0ms', transform: 'translate3d(1100px, 0px, -600px) rotateX(0deg) rotateY(0deg)', zIndex: '-1'}} data-swiper-slide-index="6">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Image-564.png" alt="Image 564" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div><div class="slide-center swiper-slide swiper-slide-duplicate swiper-slide-prev" style={{transitionDuration: '0ms', transform: 'translate3d(550px, 0px, -300px) rotateX(0deg) rotateY(0deg)', zIndex: '0'}} data-swiper-slide-index="7">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Image-234-1.png" alt="Image 234" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div>
                                <div class="slide-center swiper-slide swiper-slide-active" style={{transitionDuration: '0m', transform: 'translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg)', zIndex: '1'}} data-swiper-slide-index="0">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Slide-Item-—-1.png" alt="Slide Item — 1" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div>
                                    <div class="slide-center swiper-slide swiper-slide-next" style={{transitionDuration: '0ms', transform: 'translate3d(-550px, 0px, -300px) rotateX(0deg) rotateY(0deg); ',Index: '0'}} data-swiper-slide-index="1">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Slide-Item-—-1-1.png" alt="Slide Item — 1" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div>
                                    <div class="slide-center swiper-slide" style={{transitionDuration: '0ms', transform: 'translate3d(-1100px, 0px, -600px) rotateX(0deg) rotateY(0deg);',zIndex: '-1'}} data-swiper-slide-index="2">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Slide-Item-—-1-2.png" alt="Slide Item — 1" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div>
                                    <div class="slide-center swiper-slide" style={{transitionDuration: '0ms', transform: 'translate3d(-1650px, 0px, -900px) rotateX(0deg) rotateY(0deg);',zIndex: '-2'}} data-swiper-slide-index="3">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Slide-Item-—-1-3.png" alt="Slide Item — 1" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div>
                                    <div class="slide-center swiper-slide" style={{transitionDuration: '0ms', transform: 'translate3d(-2200px, 0px, -1200px) rotateX(0deg) rotateY(0deg)', zIndex: '-3'}} data-swiper-slide-index="4">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Slide-Item-—-1-4.png" alt="Slide Item — 1" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div>
                                    <div class="slide-center swiper-slide" style={{transitionDuration: '0ms', transform: 'translate3d(-2750px, 0px, -1500px) rotateX(0deg) rotateY(0deg)', zIndex: '-4'}} data-swiper-slide-index="5">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Image-354.png" alt="Image 354" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div>
                                    <div class="slide-center swiper-slide" style={{transitionDuration: '0ms', transform: 'translate3d(-3300px, 0px, -1800px) rotateX(0deg) rotateY(0deg)', zIndex: '-5'}} data-swiper-slide-index="6">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Image-564.png" alt="Image 564" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div>
                                    <div class="slide-center swiper-slide swiper-slide-duplicate-prev" style={{transitionDuration: '0ms', transform: 'translate3d(-3850px, 0px, -2100px) rotateX(0deg) rotateY(0deg)', zIndex: '-6'}} data-swiper-slide-index="7">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Image-234-1.png" alt="Image 234" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div>
                		    <div class="slide-center swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active" style={{transitionDuration: '0ms', transform: 'translate3d(-4400px, 0px, -2400px) rotateX(0deg) rotateY(0deg)', zIndex: '-7'}} data-swiper-slide-index="0">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Slide-Item-—-1.png" alt="Slide Item — 1" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div><div class="slide-center swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next" style={{transitionDuration: '0ms', transform: 'translate3d(-4950px, 0px, -2700px) rotateX(0deg) rotateY(0deg)', zIndex: '-8'}} data-swiper-slide-index="1">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Slide-Item-—-1-1.png" alt="Slide Item — 1" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div><div class="slide-center swiper-slide swiper-slide-duplicate" style={{transitionDuration: '0ms', transform: 'translate3d(-5500px, 0px, -3000px) rotateX(0deg) rotateY(0deg)', zIndex: '-9'}} data-swiper-slide-index="2">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Slide-Item-—-1-2.png" alt="Slide Item — 1" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div><div class="slide-center swiper-slide swiper-slide-duplicate" style={{transitionDuration: '0ms', transform: 'translate3d(-6050px, 0px, -3300px) rotateX(0deg) rotateY(0deg)', zIndex: '-10'}} data-swiper-slide-index="3">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Slide-Item-—-1-3.png" alt="Slide Item — 1" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div><div class="slide-center swiper-slide swiper-slide-duplicate" style={{transitionDuration: '0ms', transform: 'translate3d(-6600px, 0px, -3600px) rotateX(0deg) rotateY(0deg)', zIndex: '-11'}} data-swiper-slide-index="4">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Slide-Item-—-1-4.png" alt="Slide Item — 1" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div><div class="slide-center swiper-slide swiper-slide-duplicate" style={{transitionDuration: '0ms', transform: 'translate3d(-7150px, 0px, -3900px) rotateX(0deg) rotateY(0deg)', zIndex: '-12'}} data-swiper-slide-index="5">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Image-354.png" alt="Image 354" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div><div class="slide-center swiper-slide swiper-slide-duplicate" style={{transitionDuration: '0ms', transform: 'translate3d(-7700px, 0px, -4200px) rotateX(0deg) rotateY(0deg)', zIndex: '-13'}} data-swiper-slide-index="6">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Image-564.png" alt="Image 564" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div><div class="slide-center swiper-slide swiper-slide-duplicate" style={{transitionDuration: '0ms', transform: 'translate3d(-8250px, 0px, -4500px) rotateX(0deg) rotateY(0deg)', zIndex: '-14'}} data-swiper-slide-index="7">
                        <img loading="lazy" src="https://toku.co/wp-content/uploads/2023/01/Image-234-1.png" alt="Image 234" class="m-auto d-block w-100 h-auto middle-box"/>
                    </div></div>
            <div class="w-100 mx-auto d-flex position-relative justify-content-center">
                <div class="swiper-button swiper-button-prev" tabindex="0" role="button" aria-label="Previous slide"></div>
                <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets"><span class="dot swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button" aria-label="Go to slide 1">1</span><span class="dot swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 2">2</span><span class="dot swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 3">3</span><span class="dot swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 4">4</span><span class="dot swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 5">5</span><span class="dot swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 6">6</span><span class="dot swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 7">7</span><span class="dot swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 8">8</span></div>
                <div class="swiper-button swiper-button-next" tabindex="0" role="button" aria-label="Next slide"></div>
            </div>
        <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
        </div>
    </div>
</div>
        </div> */}
            {/* <div id="num-section-16" class="w-100 position-relative block-center_button pb-5 pt-4 align-items-center text-center">
            <style>
                            </style>
            <div class="container section-center_button">
	<div class="row align-items-center text-left text-md-center">
		<div class="col-12 ">
            <a href="/about-us/" class="btn btn-blue" target="">
				Learn more about us            </a>
		</div>
	</div>
</div>
        </div> */}
            <div id="num-section-17" class="w-100 position-relative block-cta_strip_2 " style={{ backgroundColor: '#7DBAAB' }}>
                <style>

                </style>

                <div class="w-100 section-cta_strip_2 text-white text-center text-md-start ">
                    <div class="container pt-5 pt-md-0">
                        <div class="row py-md-5">
                            <div class="col-12 col-md-6 d-flex ps-md-5  order-last order-md-last" style={{ verticalAlign: 'bottom' }}>
                                <img src="https://toku.co/wp-content/uploads/2023/01/Frame-46345.png" alt="Join Toku Today" class="w-100 img-class" style={{ objectFit: 'contain' }} />
                            </div>
                            <div class="col-12 col-md-6 py-4 py-md-5 my-auto px-lg-4 ">
                                <div class="d-block w-100">
                                    <h2 class="style-h2">Join Toku Today</h2>
                                </div>
                                <div class="d-block w-100 mt-4">
                                    <a href="#" class="btn btn-yellow border-radius-50 mx-auto ms-0" target="">
                                        See our open positions                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />
        </div>
    )
}

const list_accordion_13 = [
    {
        title: "In-App Voice Calling",
        text: "<p>Make it easy to connect with you or third parties via voice calls, and create a secure user experience within your app. Gather valuable data you can turn into powerful business insights.</p>",
        image:
            "https://toku.co/wp-content/uploads/2023/01/Work-hard-play-hard-5.png",
        bottom_arrow_link: {
            title: "Add In-App Voice Calling Today",
            url: "/in-app-voice-calling/",
            target: "",
        },
    },
    {
        title: "Number Masking",
        text: "<p>Give your customers and users a secure and easy way to make or receive calls without revealing their personal info, whilst keeping transactions within your platform.</p>",
        image: "https://toku.co/wp-content/uploads/2023/01/Image-532.png",
        bottom_arrow_link: {
            title: "Learn More About Number Masking",
            url: "/number-masking",
            target: "",
        },
    },
    {
        title: "Programmable Voice",
        text: "<p>Discover new voice capabilities including Call Forwarding, IVR, Alerts &amp; Notifications.</p>",
        image:
            "https://toku.co/wp-content/uploads/2023/01/Programmable-Voice-2.png",
        bottom_arrow_link: {
            title: "Integrate Voice Within Your Platform",
            url: "/programmable-voice/",
            target: "",
        },
    },
    {
        title: "Programmable Messaging",
        text: "<p>Add advanced messaging features and capabilities to your platform for transformative customer interactions.</p>",
        image:
            "https://toku.co/wp-content/uploads/2023/01/Programmable-Messaging-23.png",
        bottom_arrow_link: {
            title: "Explore More About Programmable Messaging",
            url: "/programmable-messaging/",
            target: "",
        },
    },
    {
        title: "User Verification",
        text: "<p>It's easy with multi-channel authentication options including voice calls, messages, and silent verification.</p>",
        image: "https://toku.co/wp-content/uploads/2023/01/Image-23456.png",
        bottom_arrow_link: {
            title: "Discover New Ways to Verify Your Customers",
            url: "/user-verification/",
            target: "",
        },
    },
];

export function AccordionComponent() {
    const list_accordion_6 = [
        {
            title: "Global Connectivity, Local To APAC",
            text: '<p>Expanding into APAC has never been easier. Using Toku as your communications technology provider means you get access to our APAC enterprise telco network for easy expansion into the region.</p><p><a href="https://toku.co/blog/how-to-connect-apac-business-globally/">Learn More About Our Connectivity to APAC</a></p>',
            image:
                "https://toku.co/wp-content/uploads/2023/01/Global-Connectivity-Local-To-APAC-image.png",
            bottom_arrow_link: "",
        },
        {
            title: "Customised to your needs",
            text: '<p>Work with our team of technology experts to customise our contact centre platform or voice and messaging APIs, for a truly bespoke customer engagement solution that meets your needs.</p><p><a href="https://toku.co/">Check Out Our Products</a></p>',
            image:
                "https://toku.co/wp-content/uploads/2023/01/Work-with-us-to-create-your-ideal-solution-image.png",
            bottom_arrow_link: "",
        },
        {
            title: "Programmable Communications",
            text: '<p>Create new ways to communicate with your customers with our suite of programmable APIs designed to work with your platform and our telco network.</p><p><a href="https://toku.co/programmable-voice/">Learn More About Our Programmable Voice Solutions</a></p><p>&nbsp;</p>',
            image:
                "https://toku.co/wp-content/uploads/2023/01/Programmable-Communications-image.png",
            bottom_arrow_link: "",
        },
        {
            title: "Regional support, whenever you need it",
            text: '<p>Whatever your needs, our team of APAC-based experts are on call 24/7 to help you with your communications and ensure you can provide the best possible customer experience.</p><p><a href="https://toku.co/about-us/">Learn More About Us</a></p>',
            image:
                "https://toku.co/wp-content/uploads/2023/01/Regional-support-whenever-you-need-it-image-1.png",
            bottom_arrow_link: "",
        },
    ];
    const [activeItem, setActiveItem] = useState(0);

    const showItem = (index) => {
        setActiveItem(index);
    };
    return (
        <div
            id="num-section-13"
            className="w-100 position-relative block-accordion_list py-5"
        >
            <style>{`
          /* CSS styles here */
        `}</style>
            <div className="w-100 section-accordion_list ng-scope">
                <div className="container">
                    <div className="row d-md-none">
                        <div className="accordion accordion-flush" id="accordionFlush-6">
                            {list_accordion_6.map((item, index) => (
                                <div className="accordion-item" key={index}>
                                    <h2
                                        className="accordion-header"
                                        id={`flush-heading${index + 1}`}
                                    >
                                        <button
                                            className={`accordion-button ${index === activeItem ? "style-h4" : "collapsed style-h4"
                                                }`}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#flush-collapse${index + 1}`}
                                            aria-expanded={index === 0 ? "true" : "false"}
                                            aria-controls={`flush-collapse${index + 1}`}
                                        >
                                            {item.title}
                                        </button>
                                    </h2>
                                    <div
                                        id={`flush-collapse${index + 1}`}
                                        className={`accordion-collapse collapse ${index === 0 ? "show" : ""
                                            }`}
                                        aria-labelledby={`flush-heading${index + 1}`}
                                        data-bs-parent="#accordionFlush-6"
                                    >
                                        <div className="accordion-body">
                                            <div className="d-block w-100">
                                                <img
                                                    decoding="async"
                                                    loading="lazy"
                                                    className="aligncenter size-full size-full mb-3 mb-md-0"
                                                    src={item.image}
                                                    alt={item.title}
                                                />
                                            </div>
                                            <div
                                                className="d-block w-100 p-small"
                                                dangerouslySetInnerHTML={{ __html: item.text }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="row d-none d-md-flex">
                        <div className="col-6">
                            <h3 className="style-h3 mb-5">How we deliver our offering</h3>
                            {list_accordion_6.map((item, index) => (
                                <button
                                    className={`list-accordion style-h4 w-100 ng-binding ng-scope ${index === activeItem ? "active" : "text-dark"
                                        }`}
                                    key={index}
                                    onClick={() => showItem(index)}
                                    style={{ boxShadow: "none" }}
                                >
                                    {item.title}
                                </button>
                            ))}
                        </div>
                        <div className="col-6">
                            <div className="d-block w-100 mb-2">
                                <img
                                    decoding="async"
                                    loading="lazy"
                                    className="size-full"
                                    src={list_accordion_6[activeItem].image}
                                    alt={list_accordion_6[activeItem].title}
                                />
                            </div>
                            <div className="d-block w-100">
                                <div className="style-h4 mb-2">
                                    {list_accordion_6[activeItem].title}
                                </div>
                            </div>
                            <div
                                className="d-block w-100 ng-binding"
                                dangerouslySetInnerHTML={{
                                    __html: list_accordion_6[activeItem].text,
                                }}
                            />
                            <a
                                className="btn btn-arrow text-start ng-binding ng-hide"
                                href={list_accordion_6[activeItem].bottom_arrow_link}
                                target=""
                            ></a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}





const RecommendationSlider = () => {


    const [currentSlide,setCurrentSlide] =useState(0)

    const slides =[
        {
        question : 'What made you decide to join Toku?',
        answer : 'The opportunity to excel in personal growth and open-door policy practices.',
        name :'Chok Seng',
        role : 'Senior Network Engineer',
        img : 'https://toku.co/wp-content/uploads/2023/01/Group-1000005008-4.png'
    },
    {
        question : 'What made you decide to join kasookoo?',
        answer : 'The opportunity to excel in personal growth and open-door policy practices.',
        name :'Chok Seng',
        role : 'Senior Network Engineer',
        img : 'https://toku.co/wp-content/uploads/2023/01/Group-1000005008-4.png'
    }
]



    return (
        <div class="w-100 section-recommendations_slider">
                    <div class="container py-5" style={{backgroundColor:'#7DBAAB'}}>
                        <div class="col-12 mx-auto slider-recommendations dark-slider text-white col-lg-11 slick-initialized slick-slider slick-dotted" data-slider-autoplay="1" data-slider-autoplay_speed="3000" data-slider-arrows="1" data-slider-dots="1" data-slider-infinite_scroll="1" data-slider-slides_to_show="1" data-slider-slides_to_scroll="1"><button class="slick-prev slick-arrow" aria-label="Previous" type="button" onClick={()=>{setCurrentSlide((currentSlide) => (currentSlide === 0 ? slides.length - 1 : currentSlide - 1))}} >Previous</button>
                            <div class=" draggable"><div class="slick-track" style={{ opacity: '1', width: '13068px' }}><div class="slide px-xl-5 d-flex style-people slick-slide slick-cloned" data-slick-index="-1" id="" aria-hidden="true" tabindex="-1" style={{ width: '1188px' }}>

                                <div class="w-50 d-none d-lg-inline-block">
                                    <div class="w-75 d-block p-4 border-radius-50 border-color border-color-blue">
                                        <img src={slides[currentSlide].img} alt={slides[currentSlide].name} class="w-100 border-radius-50" />
                                    </div>
                                </div>

                                <div class="w-100 w-lg-50 text-center text-lg-start pe-md-3">

                                    <div class="w-100 d-block">
                                        <h4 class="style-h2 mb-4">{slides[currentSlide].question}</h4>
                                    </div>

                                    <div class="w-100 d-block mx-auto mb-lg-4">
                                        <blockquote class="mb-lg-3 style-blue"><p>{slides[currentSlide].answer}</p>
                                        </blockquote>
                                    </div>

                                    <div class="w-100 d-block d-lg-none text-center">
                                        <div class="w-auto d-inline-block mx-auto mb-4 p-4 p-lg-5 border-radius-50 border-color border-color-blue">
                                            <img src={slides[0].img} alt={slides[currentSlide].name} class="border-radius-50" />
                                        </div>
                                    </div>

                                    <div class="w-100 d-block mb-4">
                                        <div class="w-100 d-block">
                                            <div class="name">{slides[currentSlide].name}</div>
                                        </div>
                                        <div class="w-100 d-block">
                                            <div class="role">{slides[currentSlide].role}</div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            </div></div>




                            <button class="slick-next slick-arrow" aria-label="Next" type="button" onClick={()=>{setCurrentSlide((currentSlide+1)%slides.length)}} >Next</button>
                            <ul class="slick-dots" role="tablist">
                                {
                                    slides.map((slide,index)=>{
                                        return <li class={currentSlide===index ? "slick-active" :''} role="presentation">
                                        <button type="button" value={index}  onClick={()=>{setCurrentSlide(index)}} >{index}</button>
                                        </li>
                                    })
                                }
                                    </ul>
                                    
                                    
                                    </div>
                    </div>
                </div>
    );
};




