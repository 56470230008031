import React, { useEffect, useState } from 'react'
import './contactform.css'
import emailjs from '@emailjs/browser';
import close_icon from './../../assets/images/close.svg'
export default function ContactForm(props) {
    const [steps, setSteps] = useState([1, 2, 3])
    const [currentStep, setCurrentStep] = useState(1)
    const [buttonText , setButtonText] = useState('Next')
    const [headingText,setHeadingText] = useState('A little bit about you...')


    useEffect(() => {
        if (props.ContactCenter ==="true") {
          setHeadingText("Learn more about Kasookoo Contact Centre");
        }
      }, [props.ContactCenter]);




    const productsolutionoption = ['I’m looking for an omnichannel cloud-based contact centre solution','I’m looking for a cloud-based call management system','I’m looking for a cloud-based call management system','I’d like to enable global phone calling in Microsoft Teams','I’d like to learn more about Toku’s connectivity for Zoom Phone','I’m interested in an SMS management system','I’d like to embed a calling function within my app','I wish to know more about your APIs','I want to get virtual numbers','I’d like to know about your carrier services']
    const partnershipoptions =['Becoming a Referral Partner','Becoming a Technology Partner']
    const [formData,setFormData] =  useState({
        firstname : '',
        secondname : '',
        companyname :'',
        phonenumber :'',
        email :'',
        howwehelp:{
            selected : '',
            subselected:''

        },
        moredetail:'',
        noofcontactcenter : '',
        crm:'',
        agents:'',
        

    })
    const handleOnChange = (e) =>{
        const {name,value} = e.target

        if(name==="selected" || name==="subselected"){
            if(name==='selected'){
                setFormData({
                    ...formData,
                    howwehelp: {
                      ...formData.howwehelp,
                      subselected: ''
                    }
                  });

            }
            setFormData({
                ...formData,
                howwehelp: {
                  ...formData.howwehelp,
                  [name]: value
                }
              });
        }
        else{
            setFormData(prevState => ({
                ...prevState ,[name] : value
            }))
        }
        

        
        

        // setFormData({
        //     ...formData,
        //     howwehelp: {
        //       [name]: value
        //     }
        //   });
        
    }

    const sendEmail = () => {
        

        emailjs.send('service_1otg21v', 'template_8x3knnk', {from_name:'first_name',from_email:"test@gmail.com",message:'test'}, 'oO37cEDSuCSUKKNtT')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };

    

    const handleOnClick = (e) =>{
        // sendEmail()
        console.log(currentStep)
        if(currentStep===1){
            if(formData.firstname===''){
                console.log('test')
                alert('First Name is required')
            }
            else if(formData.lastname === ''){
                alert('Last Name is required')
            }
            else if(formData.companyname === ''){
                alert('Company Name is required')
            }
            else if(formData.email === ''){
                alert('Email is required')
            }
            else if(formData.phonenumber === ''){
                alert('Phone number is required')
            }
            else{
                setCurrentStep(currentStep+1)
                if(props.ContactCenter){
                    setHeadingText('Thanks for your interest in our Contact Centre solution')
                }
                else{
                    setHeadingText('Please answer these quick questions so we can connect you with the right team at Kasookoo.')
                }
            }
        }
        else if(currentStep===2){
            if(formData.howwehelp.selected === ""){
                alert('Please fill the form')
            }
            
            else{
                if((formData.howwehelp.selected === "Products and Solutions" || formData.howwehelp.selected ==="Partnerships")&& formData.howwehelp.subselected===""){
                    alert('Please fill the form')
                }
                else{
                    setCurrentStep(currentStep+1)
                    setButtonText("Submit")
                    setHeadingText('Just one last thing...')
                }
                
            }
        }
        else if(currentStep==3){
            if(formData.moredetail===''){
                alert('More Detail is required')
            }
            else{
                setCurrentStep(1)
                setButtonText('Next')
                props.setIsFormOpen(false)
                setFormData({
                    firstname: '',
                    secondname: '',
                    companyname: '',
                    phonenumber: '',
                    email: '',
                    howwehelp: {
                      selected: '',
                      subselected: ''
                    },
                    moredetail:'',
        noofcontactcenter : '',
        crm:'',
        agents:'',
                  });
                
            }
        }
    }


    return (
        <div className='contact-from h-100' style={{position:'absolute',zIndex:"1000"}}>
            <div className="row justify-content-center align-items-center h-100">
                <div className="col-4 bg-white text-dark p-5" style={{borderRadius:'10px'}}>
                    <div className='text-end'><button style={{border:'none',background:'transparent'}} onClick={()=>{props.setIsFormOpen(false)}}><img style={{width:'15px'}} src={close_icon} alt="" /></button></div>
                    <div className='mt-4 mb-4'>
                    <div className="row">
                        <div className="col text-center">
                            <p className='vsm-font m-0'>Step {currentStep} of {steps.length}</p>
                            <div className="d-flex justify-content-center">
                                {
                                    steps.map((step_no) => {
                                        return <div className={step_no===currentStep ? "circle current":"circle"}>

                                        </div>
                                    })
                                }
                                
                            </div>
                            <h3 className='mb-3'>{headingText }</h3>
                            
                            {
                               currentStep===1 && <AboutYouForm formData={formData} handleOnChange ={handleOnChange} />

                            }
                            {
                                currentStep===2 && (props.ContactCenter ? <ContactCenterForm formData={formData} handleOnChange ={handleOnChange} productsolutionoption={productsolutionoption} partnershipoptions={partnershipoptions}/>:<HelpForm formData={formData} handleOnChange ={handleOnChange} productsolutionoption={productsolutionoption} partnershipoptions={partnershipoptions}/>)
                            }
                            {
                                currentStep === 3 && <MoreDetialForm formData={formData} handleOnChange ={handleOnChange} />
                            }
                            
                        </div>
                    </div>

                    <button className='mt-3 contact-btn' onClick={handleOnClick} style={{border:'none',color:'white'}}>{buttonText}</button>
                    </div>


                 
                </div>
            </div>
        </div>
    )
}


export function AboutYouForm(props){
    return(
        <div className='contact-form1'>
            <div className="row">
                <div className="col">
                    <div className="row justify-content-between">
                        <div className="col-5 text-start">
                            <label htmlFor="">First Name</label>
                            <input className='w-100' type="text" name="firstname" value={props.formData.firstname} onChange={props.handleOnChange} />
                        </div>
                        <div className="col-5 text-start">
                            <label htmlFor="">Last Name</label>
                            <input className='w-100' type="text" name="lastname" value={props.formData.lastname} onChange={props.handleOnChange} />
                        </div>
                    </div>

                    <div className="row mt-1">
                        <div className="col text-start">
                            <label htmlFor="">Company name</label>
                            <input className='w-100 mt-2' type="text" name="companyname" value={props.formData.companyname} onChange={props.handleOnChange} />
                        </div>
                    </div>

                    <div className="row mt-1">
                        <div className="col text-start">
                            <label htmlFor="">Phone number</label>
                            <input className='w-100 mt-2' type="text" name="phonenumber" value={props.formData.phonenumber} onChange={props.handleOnChange} />
                        </div>
                    </div>

                    <div className="row mt-1">
                        <div className="col text-start">
                            <label htmlFor="">Email</label>
                            <input className='w-100 mt-2' type="email" name="email" value={props.formData.email} onChange={props.handleOnChange} />
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    )
}

export function HelpForm(props){
    return (
        <div className='contact-form1'>
            <div className="row text-start">
                <div className="col">
                   <div className="row mt-1">
                    <div className="col">
                    <label htmlFor="">How can we help you today?*</label>
                    <select className='mt-2 w-100' name="selected" id="" onChange={props.handleOnChange}>
                        <option value="">Select the best option that fits you</option>
                        <option value="Products and Solutions">Products and Solutions</option>
                        <option value="Consulting">Consulting</option>
                        <option value="Partnerships">Partnerships</option>
                        <option value="Other">Other</option>
                    </select>
                    </div>
                   </div>

                   {
                    props.formData.howwehelp.selected === "Products and Solutions" && <div className="row mt-1">
                    <div className="col">
                    <label htmlFor="">Which Toku product or solutions are you interested in?</label>
                    <select className='mt-2 w-100' name="subselected" id="" onChange={props.handleOnChange}>
                    <option value="">Please Select option</option>
                        {
                            props.productsolutionoption.map((value)=>{
                                    return <option  value={value}>{value}</option>
                                
                            })
                            
                        }
                        
                        
                    </select>
                    </div>
                   </div>

                   
                    
                   }

{
                    props.formData.howwehelp.selected === "Partnerships" && <div className="row mt-1">
                    <div className="col">
                    <label htmlFor="">I'd like to know more about</label>
                    <select className='mt-2 w-100' name="subselected" id="" onChange={props.handleOnChange}>
                    <option value="">Please Select option</option>
                        {
                            props.partnershipoptions.map((value)=>{
                                

                                    return <option  value={value}>{value}</option>
                                
                            })
                            
                        }
                        
                        
                    </select>
                    </div>
                   </div>

                   
                    
                   }



                </div>
            </div>
        </div>
    )
}


export function MoreDetialForm(props){
    return(
        <div className='contact-form1'>
            <div className="row">
                <div className="col">
                    <div className="row justify-content-between">
                        <div className="col text-start">
                            <label htmlFor="">Please provide us with more details on your enquiry here!</label>
                            <input className='w-100' type="text" name="moredetail" value={props.formData.moredetail} onChange={props.handleOnChange} />
                        </div>
                    </div>

            
                </div>
            </div>
        </div>
    )
}


export function ContactCenterForm(props){
    return (
        <div className='contact-form1'>
            <div className="row">
                <div className="col">
                    

                    <div className="row mt-1">
                        <div className="col text-start">
                            <label htmlFor="">How many countries does your contact centre operate or is planning to operate in?*</label>
                            <input className='w-100 mt-2' type="text" name="noofcontactcenter" value={props.formData.noofcontactcenter} onChange={props.handleOnChange} />
                        </div>
                    </div>

                    <div className="row mt-1">
                        <div className="col text-start">
                            <label htmlFor="">What CRM is your team currently using?*</label>
                            <input className='w-100 mt-2' type="text" name="crm" value={props.formData.crm} onChange={props.handleOnChange} />
                        </div>
                    </div>

                    <div className="row mt-1">
                        <div className="col text-start">
                            <label htmlFor="">How many agents are you expected to have?*</label>
                            <input className='w-100 mt-2' type="text" name="agents" value={props.formData.agents} onChange={props.handleOnChange} />
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    )
}
