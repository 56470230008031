import React from "react";
import "./resources.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import comapaign_img from "../../assets/images/consulting-img.png";

export default function Resources() {
  return (
    <div className="resource">
      <Navbar
        heading="Holistic business transformation solutions"
        text="At Toku Consulting, we understand that successful digital transformation requires much more than a one-size-fits-all approach. That’s why we adopt an end-to-end consultancy approach, guiding your enterprise on every step of the way from vision to conception and implementation of the right solution."
        img={comapaign_img}
        extra_heading="KASOOKOO CONSULTING"
        first_section = {true}
      ></Navbar>

      <div id="num-section-1" class="w-100 position-relative block-block_text ">
        <style></style>

        <div class="section-cs-main">
          <div class="" style={{ background: "#fff" }}>
            <div class="container py-5">
              <div class="row p-0 py-md-5 py-3 py-md-0">
                <div class="col-12 col-md-6 my-auto mb-4 mb-md-auto order-0 order-md-0">
                  <div class="title mb-3">
                    <h3>
                      How Gojek Applied Toku’s Communications APIs To Boost
                      Customer Experience
                    </h3>
                  </div>
                  <div class="excerpt mb-3">
                    Gojek is a Southeast Asian company that began its online
                    journey focusing on motorcycle ride-hailing services, but
                    has since expanded to become a multi-service platform
                    offering a wide range of services from transportation and
                    food delivery to digital payments, logistics and many other
                    on-demand services. It’s fair to say that Gojek takes
                    customer experience very […]
                  </div>
                  <div class="link">
                    <a
                      href="https://toku.co/customer-stories/how-gojek-uses-communication-apis/"
                      title="How Gojek Applied Toku’s Communications APIs To Boost Customer Experience"
                      class="btn btn-arrow"
                    >
                      Read GOJEK's Story
                    </a>
                  </div>
                </div>
                <div class="col-12 col-md-6 my-auto text-center">
                  <a
                    href="https://toku.co/customer-stories/how-gojek-uses-communication-apis/"
                    title="How Gojek Applied Toku’s Communications APIs To Boost Customer Experience"
                  >
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Gojek_logo_2019-2-2.png"
                      alt="GOJEK Logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="" style={{ background: "#F1F5FE" }}>
            <div class="container py-5">
              <div class="row p-0 py-md-5 py-3 py-md-0">
                <div class="col-12 col-md-6 my-auto mb-4 mb-md-auto order-0 order-md-1">
                  <div class="title mb-3">
                    <h3>
                      How Lenskart Improved their SMS Campaign Operational
                      Efficiency with Toku
                    </h3>
                  </div>
                  <div class="excerpt mb-3">
                    Lenskart is a rapidly growing Indian omnichannel
                    direct-to-consumer eyewear retailer with its eyes set on
                    expanding operations worldwide. Presently, the company is
                    using its headquarters in Singapore to expand into Southeast
                    Asia, and gearing up to scale in the Middle East from its
                    headquarters in UAE. With an expanding chain of 24 stores in
                    Singapore, […]
                  </div>
                  <div class="link">
                    <a
                      href="https://toku.co/customer-stories/how-lenskart-improved-their-sms-campaign-operational-efficiency-with-toku/"
                      title="How Lenskart Improved their SMS Campaign Operational Efficiency with Toku"
                      class="btn btn-arrow"
                    >
                      Read LENSKART's Story
                    </a>
                  </div>
                </div>
                <div class="col-12 col-md-6 my-auto text-center">
                  <a
                    href="https://toku.co/customer-stories/how-lenskart-improved-their-sms-campaign-operational-efficiency-with-toku/"
                    title="How Lenskart Improved their SMS Campaign Operational Efficiency with Toku"
                  >
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Group-6720.png"
                      alt="LENSKART Logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="" style={{ background: "#fff" }}>
            <div class="container py-5">
              <div class="row p-0 py-md-5 py-3 py-md-0">
                <div class="col-12 col-md-6 my-auto mb-4 mb-md-auto order-0 order-md-0">
                  <div class="title mb-3">
                    <h3>
                      Cloud Phone System Review: How RMA Contracts Set Up A
                      Full-Featured System In Less Than 20 Minutes
                    </h3>
                  </div>
                  <div class="excerpt mb-3">
                    RMA Contracts is a leading BPO service provider based in
                    Singapore that uses cutting-edge technology to streamline
                    core business practices for success. As a one-stop
                    integrated BPO partner, RMA specialises in helping
                    organisations improve operational efficiency in the areas of
                    Customer Relations, Survey and Market Research, Managed
                    Services, HR Consultancy, Recruitment and Manpower
                    Outsourcing, and […]
                  </div>
                  <div class="link">
                    <a
                      href="https://toku.co/customer-stories/cloud-phone-system-review-rma/"
                      title="Cloud Phone System Review: How RMA Contracts Set Up A Full-Featured System In Less Than 20 Minutes"
                      class="btn btn-arrow"
                    >
                      Read RMA's Story
                    </a>
                  </div>
                </div>
                <div class="col-12 col-md-6 my-auto text-center">
                  <a
                    href="https://toku.co/customer-stories/cloud-phone-system-review-rma/"
                    title="Cloud Phone System Review: How RMA Contracts Set Up A Full-Featured System In Less Than 20 Minutes"
                  >
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/305653841_753256572313496_8644153897561576457_n-1.png"
                      alt="RMA Logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="" style={{ background: "#F1F5FE" }}>
            <div class="container py-5">
              <div class="row p-0 py-md-5 py-3 py-md-0">
                <div class="col-12 col-md-6 my-auto mb-4 mb-md-auto order-0 order-md-1">
                  <div class="title mb-3">
                    <h3>
                      Piing! Powers Up its Customer Service with Cloud PBX
                    </h3>
                  </div>
                  <div class="excerpt mb-3">
                    As an innovative, on-demand, app-based dry-cleaning and
                    laundry service, Piing! aims to be customers’ trusted
                    laundry partner for life. Offering convenience, quality and
                    speed, in just one tap Piing! picks up and delivers clean
                    and freshly-pressed clothes and other laundry anytime, no
                    matter where you are in as little as 60 minutes. In a world
                    […]
                  </div>
                  <div class="link">
                    <a
                      href="https://toku.co/customer-stories/piing-powers-up-its-customer-service-with-cloud-pbx-toku/"
                      title="Piing! Powers Up its Customer Service with Cloud PBX"
                      class="btn btn-arrow"
                    >
                      Read PIING!'s Story
                    </a>
                  </div>
                </div>
                <div class="col-12 col-md-6 my-auto text-center">
                  <a
                    href="https://toku.co/customer-stories/piing-powers-up-its-customer-service-with-cloud-pbx-toku/"
                    title="Piing! Powers Up its Customer Service with Cloud PBX"
                  >
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/piing-greyd-1.png"
                      alt="PIING! Logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="" style={{ background: " #fff" }}>
            <div class="container py-5">
              <div class="row p-0 py-md-5 py-3 py-md-0">
                <div class="col-12 col-md-6 my-auto mb-4 mb-md-auto order-0 order-md-0">
                  <div class="title mb-3">
                    <h3>
                      How BLACAZ. Transformed its Digital Operations for a
                      Seamless Customer Experience
                    </h3>
                  </div>
                  <div class="excerpt mb-3">
                    The rise of insurtech companies is giving traditional
                    companies a run for their money by empowering customers to
                    make informed purchases seamlessly online. As the first and
                    only insurance partner in Asia that offers customisable
                    insurance policies 100% online, BLACAZ. is all about
                    simplifying their customers’ journey and helping them
                    navigate through the complex industry. BLACAZ. manages
                    online […]
                  </div>
                  <div class="link">
                    <a
                      href="https://toku.co/customer-stories/blacaz-transformed-digital-operations-for-seamless-customer-experience/"
                      title="How BLACAZ. Transformed its Digital Operations for a Seamless Customer Experience"
                      class="btn btn-arrow"
                    >
                      Read BLACAZ's Story
                    </a>
                  </div>
                </div>
                <div class="col-12 col-md-6 my-auto text-center">
                  <a
                    href="https://toku.co/customer-stories/blacaz-transformed-digital-operations-for-seamless-customer-experience/"
                    title="How BLACAZ. Transformed its Digital Operations for a Seamless Customer Experience"
                  >
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/62f9ddd5b6e9ac1bde652279_Logo_Blacaz-1.png"
                      alt="BLACAZ Logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container section-block_text">
          <div class="row">
            <div class="col-12"></div>
          </div>
        </div>
      </div>
      <div
        id="num-section-2"
        class="w-100 position-relative block-cta_strip_2 "
      >
        <style></style>

        <div class="w-100 section-cta_strip_2 text-white text-center text-md-start ">
          <div class="container pt-5 pt-md-0">
            <div class="row py-md-5">
              <div
                class="col-12 col-md-6 d-flex ps-md-5  order-last order-md-last"
                style={{ verticalAlign: "bottom" }}
              >
                <img
                  src="https://toku.co/wp-content/uploads/2023/05/Frame-46324.png"
                  alt="Ready to meet your customers where they’re at?"
                  class="w-100 img-class"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div class="col-12 col-md-6 py-4 py-md-5 my-auto px-lg-4 ">
                <div class="d-block w-100">
                  <h2 class="style-h2">
                    Ready to meet your customers where they’re at?
                  </h2>
                </div>
                <div class="d-block w-100">
                  <div class="p-big">
                    <p>
                      Toku Solutions are designed to help make communications
                      smarter, and more seamless.
                    </p>
                  </div>
                </div>
                <div class="d-block w-100 mt-4">
                  <a
                    href="#contactModal"
                    class="btn btn-yellow border-radius-50 mx-auto ms-0"
                    target=""
                    data-bs-toggle="modal"
                    data-bs-target="#contactModal"
                  >
                    Connect With Us{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
