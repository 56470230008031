import React from "react";
import "./inappvoicecalling.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import first_section_left_image from "../../assets/images/first-section-left.png";

import bankicon from "../../assets/images/bank.svg"
import dollaricon from "../../assets/images/dollar.svg"
import grocerystoreicon from "../../assets/images/grocery-store.svg"
import insuranceicon from "../../assets/images/insurance.svg"
import planeicon from "../../assets/images/plane.svg"
import sendicon from "../../assets/images/send.svg"

import boosticon from "../../assets/images/in-app-vc-booster.svg"
import protectedDataicon from "../../assets/images/protected-data-icon.svg"
import highercallconicone from "../../assets/images/in-app-hcc-icon.svg"

import bottomLeftImg from "../../assets/images/7-2.png"



export default function InAppVoiceCalling() {
  return (
    <div className="voice-calling">
      <Navbar
        heading="Engage with your app users and foster strong connections"
        text="Enhance your app's user experience by seamlessly integrating powerful voice capabilities and ensuring utmost security. Leverage our high-quality call connectivity in Africa through our advanced voice SDK"
        img={first_section_left_image}
        extra_heading="IN-APP VOICE CALLING "
      ></Navbar>

      <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title pb-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Unlock the full potential of voice technology by seamlessly
                  integrating its benefits directly into your application.
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-2"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary pb-5"
      >
        <style></style>

        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "349.5px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={boosticon}
                      alt="Group 7329"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Boost app utilization </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      By enabling in-app customer communications, you can
                      prolong user engagement and eliminate the need for users
                      to switch platforms or channels to resolve issues, thus
                      enhancing their overall experience within your
                      application.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "349.5px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={protectedDataicon}
                      alt="Group 1000005102"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Protected data exchange</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Establish a highly secure environment for customer
                      communications within your ecosystem, safeguarding
                      personal information and effectively eliminating the risk
                      of fraud.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "349.5px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={highercallconicone}
                      alt="Group 1000005021"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Higher call connect rates</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      With the knowledge that incoming calls are from your
                      actual customers, you can effectively eliminate the
                      occurrence of spoof calls and robocalling, leading to
                      higher pick-up rates among your team.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-3"
        class="w-100 position-relative block-centered_title pt-5"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">Nurture customer relationships</h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-4"
        class="w-100 position-relative block-centered_title pb-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Customers prefer to call customer service, and a lack of app
                  functionality could be costing your business.
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-5"
        class="w-100 position-relative block-4_columns_with_counters pb-4"
      >
        <style></style>
        <style></style>
        <div class="container section-4_columns_with_counters strip-counter">
          <div class="row strip-counter-style-centered pb-4">
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">67</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers express a strong preference for conducting all
                company communications within the app environment.
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">45</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers cite the lack of functionality for resolving
                inquiries as the primary reason for not using an app.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-6"
        class="w-100 position-relative block-blog_cta pb-5"
      >
        <style></style>

        <div class="w-100 section-blog_cta pt-3 pb-md-3">
          <div class="container">
            <div class="w-100 cta-row my-4">
              <div
                class="cta-we-can-help px-4 px-md-5 py-3 text-white mx-auto"
                style={{
                  backgroundImage:
                    "url('https://toku.co/wp-content/uploads/2023/01/Frame-46326.png')",
                }}
              >
                <div class="d-block w-100">
                  <h4 class="style-h4 mb-3">
                    Want to know what Singapore consumers expect in an
                    interaction?
                  </h4>
                  <div class="description mb-3">
                    *Get the full details in Kasooko Singapore Consumer
                    Engagement Report 2022.
                  </div>
                </div>
                <div class="d-block w-100">
                  <a class="btn btn-yellow" target="" href="/">
                    Download Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-7"
        class="w-100 position-relative block-centered_title pt-5 pb-5"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-5 pb-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Integrate voice calling seamlessly for unparalleled
                  convenience.
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-8"
        class="w-100 position-relative block-block_text pt-4 pb-5"
      >
        <style></style>
        <div class="container section-block_text">
          <div class="row">
            <div class="col-12">
              <p>
                <img
                  decoding="async"
                  loading="lazy"
                  class="aligncenter wp-image-4484"
                  src="https://toku.co/wp-content/uploads/2023/01/Group-1000005004-2-300x104.png"
                  alt=""
                  width="2800"
                  height="975"
                  srcset="https://toku.co/wp-content/uploads/2023/01/Group-1000005004-2-300x104.png 300w, https://toku.co/wp-content/uploads/2023/01/Group-1000005004-2-1024x356.png 1024w, https://toku.co/wp-content/uploads/2023/01/Group-1000005004-2-768x267.png 768w, https://toku.co/wp-content/uploads/2023/01/Group-1000005004-2-1536x535.png 1536w, https://toku.co/wp-content/uploads/2023/01/Group-1000005004-2-2048x713.png 2048w"
                  sizes="(max-width: 2800px) 100vw, 2800px"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-9"
        class="w-100 position-relative block-centered_title pt-5"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h3 class="style-h3">
                  Seamless call connections, no matter the distance
                </h3>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-10"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Leverage our number-masking capabilities and in-app calling to
                  establish a hybrid call setup that offers reliable, redundant
                  connectivity throughout Africa.
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-11"
        class="w-100 position-relative block-image_side_and_text_side_repeater pb-5"
      >
        <style></style>
        <div class="w-100 section-row_repeater">
          <div class="container">
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Frame-465304.png"
                  alt="Frame 465304"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">
                    Connect your app data to your contact centre
                  </h3>{" "}
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Maintain a seamless customer experience by ensuring
                      continuous connectivity between customer conversations and
                      app data.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a
                    href="#InAppCalling"
                    class="btn btn-arrow text-start"
                    target=""
                    data-bs-toggle="modal"
                    data-bs-target="#InAppCalling"
                  >
                    Talk to an Expert!{" "}
                  </a>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  pe-lg-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Frame-463056-2.png"
                  alt="Frame 463056 2"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  ps-lg-5">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Voice-Activated Control</h3>{" "}
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Deliver optimal calling experiences using our advanced
                      voice SDK and establish a PSTN failover system for
                      uninterrupted connectivity during calls.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a
                    href="/programmable-voice/"
                    class="btn btn-arrow text-start"
                    target=""
                  >
                    Learn More About Our Voice APIs{" "}
                  </a>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Frame-463002.png"
                  alt="Frame 463002"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Call insights at your disposal</h3>{" "}
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Efficiently resolve issues and gain valuable insights into
                      customer calls by tracking both call and application data,
                      utilizing network quality information, and leveraging AI
                      sentiment analysis for a comprehensive understanding of
                      your customer interactions.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a href="/" class="btn btn-arrow text-start" target="">
                    Learn More About Kasooko Contact Centre{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-12"
        class="w-100 position-relative block-centered_title pt-5"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Looking to integrate calls into your platform while
                  maintaining greater control?
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-13"
        class="w-100 position-relative block-image_side_and_text_side_repeater yellow-arrow-btn text-white"
      >
        <style></style>
        <div class="w-100 section-row_repeater">
          <div class="container">
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Number-masking-image-23.png"
                  alt="Number masking image 23"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Programmable Voice</h3>{" "}
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Enhance control and integrability by incorporating voice
                      calling capabilities and expanding your range of voice
                      functionalities through our programmable voice APIs
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a href="/" class="btn btn-arrow text-start" target="">
                    Learn More About Our Voice APIs{" "}
                  </a>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  pe-lg-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Frame-46307-7.png"
                  alt="Frame 46307"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  ps-lg-5">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">A solution for any environment </h3>{" "}
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Our versatile Voice APIs and SDK are designed to
                      seamlessly integrate with any setup, whether you have an
                      on-site PBX, a cloud-based solution, or a combination of
                      both.
                    </p>
                    <p>
                      With Kasookoo's consultative approach, our dedicated team
                      takes the time to understand your specific integration
                      requirements. We will guide you through the process,
                      ensuring that we create a customized voice solution that
                      perfectly aligns with your needs and objectives.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a
                    href="#InAppCalling"
                    class="btn btn-arrow text-start"
                    target=""
                    data-bs-toggle="modal"
                    data-bs-target="#InAppCalling"
                  >
                    Talk to an Expert!{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-sm-none bg-archer bg-archer-down">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 87C0 87 80.5 46.5 200 46.5C319.5 46.5 400 87 400 87V0H0V87Z"
            fill=" rgba(125, 186, 171, 1)"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-sm-block bg-archer bg-archer-down">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z"
            fill=" rgba(125, 186, 171, 1)"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-14"
        class="w-100 position-relative block-titles_background_and_repeater_subjects "
      >
        <style></style>
        <div class="w-100 section-titles_background_and_repeater_subjects">
          <div class="container">
            <div class="row px-md-5 pb-5 pt-3">
              <div class="col-12 col-md-10 col-lg-8 mx-auto text-md-center">
                <div class="d-block w-100">
                  <h2 class="style-h2">
                    Unveil the transformative power of application evolution
                    within industries similar to yours
                  </h2>
                </div>
              </div>
            </div>
            <div class="row px-md-3 rseh">
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-turquoise">
                  <a href="/government-solutions/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={bankicon}
                        alt="Government Solutions"
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/government-solutions" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/government-solutions/"
                      title=""
                      target=""
                      style={{ height: "33px" }}
                    >
                      Government Solutions
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-turquoise">
                  <a href="/fintech" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={dollaricon}
                        alt="Fintech "
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/fintech" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/fintech"
                      title=""
                      target=""
                      style={{ height: "33px" }}
                    >
                      Fintech{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-turquoise">
                  <a href="/sharing-economy" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={sendicon}
                        alt="Sharing Economy "
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/sharing-economy" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/sharing-economy"
                      title=""
                      target=""
                      style={{ height: "33px" }}
                    >
                      Sharing Economy{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-turquoise">
                  <a href="/insurance-services/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={insuranceicon}
                        alt="Insurance Services "
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/insurance-services" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/insurance-services"
                      title=""
                      target=""
                      style={{ height: "33px" }}
                    >
                      Insurance Services{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-turquoise">
                  <a href="/hostpitality" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={planeicon}
                        alt="Travel &amp; Hospitality "
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/hostpitality" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/hostpitality"
                      title=""
                      target=""
                      style={{ height: "33px" }}
                    >
                      Travel &amp; Hospitality{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-turquoise">
                  <a href="/retail-ecommerce" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={grocerystoreicon}
                        alt="Retail &amp; eCommerce "
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/retail-ecommerce" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/retail-ecommerce"
                      title=""
                      target=""
                      style={{ height: "33px" }}
                    >
                      Retail &amp; eCommerce{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-15"
        class="w-100 position-relative block-cta_strip_2 pt-0 pb-4"
      >
        <style></style>

        <div class="w-100 section-cta_strip_2 text-white text-center text-md-start center p-md">
          <div class="container pt-5 pt-md-0">
            <div class="row py-md-5">
              <div
                class="col-12 col-md-6 d-flex ps-md-5  order-first order-md-first"
                style={{ verticalAlign: "bottom" }}
              >
                <img
                  src={bottomLeftImg}
                  alt="Want to develop calling capabilities for your app?"
                  class="w-100 img-class"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div class="col-12 col-md-6 py-4 py-md-5 my-auto px-lg-4 pt-0 pb-4">
                <div class="d-block w-100">
                  <h2 class="style-h2">
                    Looking to incorporate calling capabilities into your app
                    development?
                  </h2>
                </div>
                <div class="d-block w-100 mt-4">
                  <a
                    href="#InAppCalling"
                    class="blact_btn"
                    target=""
                    data-bs-toggle="modal"
                    data-bs-target="#InAppCalling"
                  >
                    Talk to an Expert{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
