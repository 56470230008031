import React, {useState} from 'react'
export default function Feedback() {
    const [currentFeedback, setCurrentFeedback] = useState(0);

    const nextFeedback = () => {
        if(currentFeedback<feedbackData.length -1)
        setCurrentFeedback(currentFeedback + 1);
    };

    const prevFeedback = () => {
        if(currentFeedback !=0)
        setCurrentFeedback(currentFeedback - 1);
    };

    const feedbackData = [
        {
            quote:
                'For most cases, Toku has successfully kept the undelivered SMS rate to between 5-6%. This is a good number compared to other delivery rates I’ve seen in my experience. I think it’s because Toku has a better presence in Southeast Asia. That means they have better reach, and higher deliverability. And we are seeing that across our campaigns.',
            name: 'Ajith Kumar',
            role: 'CRM Lead, SEA at Lenskart',
            image: 'https://toku.co/wp-content/uploads/2023/01/Group-6720.svg',
        },
        {
            quote: 'Toku’s solution helped us to scale down on our manpower requirements, which was very helpful for us.',
            name: 'Celine Que',
            role: 'Head of Service Excellence, Singapore',
            image: 'https://toku.co/wp-content/uploads/2023/01/Gojek_logo_2019-2.png',
        },
        {
            quote:
                'Thanks to Toku’s deep knowledge of our business needs, they have helped create bespoke solutions for a complex foodpanda ecosystem across 11 markets and different business lines. Many more exciting solutions are in the works for our partners and customers, and we look forward to implementing them with Toku’s technical support',
            name: 'Hossein Entekhabi',
            role: 'Senior Director, Central Operations (APAC) at foodpanda',
            image: 'https://toku.co/wp-content/uploads/2023/01/Group-6722.svg',
        },
    ];

    const feedback = feedbackData[currentFeedback];
    

    return (
        
            
            <div class="w-100 section-recommendations_slider">
                <div class="container py-5">
                    <div class="col-12 mx-auto slider-recommendations col-lg-9 slick-initialized slick-slider slick-dotted" data-slider-autoplay="1" data-slider-autoplay_speed="5001" data-slider-arrows="1" data-slider-dots="1" data-slider-infinite_scroll="1" data-slider-slides_to_show="1" data-slider-slides_to_scroll="1">
                        <button class="slick-prev slick-arrow" aria-label="Previous" type="button" onClick={prevFeedback}>Previous</button>
                        <div class="slick-list draggable">
                            <div class="slick-track" style={{ opacity: '1', width: '6608px', transform: 'translate3d(-944px, 0px, 0px)' }}>
                                <div class="slide px-md-5 slick-slide slick-cloned" data-slick-index="-1" id="" aria-hidden="true" tabindex="-1" style={{ width: '944px' }}>
                            
                            
                        </div><div class="slide px-md-5 slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style={{ width: '944px' }} tabindex="0" role="tabpanel" id="slick-slide10" aria-describedby="slick-slide-control10">
                                <div class="w-100 d-block px-md-5">
                                    <blockquote class="text-center text-md-start mb-5 mb-md-3 px-md-5 px-lg-1 style-turquoise"><p>{feedback.quote}</p>
                                    </blockquote>
                                </div>
                                <div class="w-100 d-block px-md-3 px-lg-5">
                                    <div class="row text-center">
                                        <div class="col-12 col-md-4 text-md-start order-md-last">
                                            <div class="w-100 d-block">
                                                <div class="name">{feedback.name}</div>
                                            </div>
                                            <div class="w-100 d-block">
                                                <div class="role">{feedback.role}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-5 order-md-first pb-3 pb-md-0">
                                            <img src="https://toku.co/wp-content/uploads/2023/01/Gojek_logo_2019-2.png" alt="Gojek_logo_2019 2" class="m-auto mt-1 ms-md-0" />
                                        </div>
                                        <div class="col-12 col-md-3 text-md-end">
                                            <div class="w-100 d-block">
                                                <img src="https://toku.co/wp-content/uploads/2023/01/Frame-46374.png" alt="Celine Que" class="mx-auto ms-md-auto me-md-2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>


                        <button class="slick-next slick-arrow" aria-label="Next" type="button" onClick={nextFeedback}>Next</button><ul class="slick-dots" role="tablist"><li class="slick-active" role="presentation"><button type="button" role="tab" id="slick-slide-control10" aria-controls="slick-slide10" aria-label="1 of 3" tabindex="0" aria-selected="true">1</button></li><li role="presentation" class=""><button type="button" role="tab" id="slick-slide-control11" aria-controls="slick-slide11" aria-label="2 of 3" tabindex="-1">2</button></li><li role="presentation" class=""><button type="button" role="tab" id="slick-slide-control12" aria-controls="slick-slide12" aria-label="3 of 3" tabindex="-1">3</button></li></ul></div>
                </div>
            </div>
        
    );
}