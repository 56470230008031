import React,{useState} from 'react'
import './carrier.css'
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import optimize_customer_img from "../../assets/images/optimize-customer-img.png";
import ContactForm from '../../components/ContactForm/ContactForm';
export default function Carrier() {
  return (
    <div className='carrier'>
      <Navbar
        extra_heading = "KASOOKOO CONTACT CENTRE"
        heading="A highly efficient Contact Center for Increased Productivity"
        text="Enable smooth and effortless customer interactions by leveraging a comprehensive cloud-based contact center solution that seamlessly integrates calls, messages, and OTT conversations. Empower your agents and managers with robust integrations that enhance customer engagement to an unprecedented level."
        img={optimize_customer_img}
        contact_center_form = "true"
        first_section = {true}
      ></Navbar>
  

         <div id="num-section-1" class="w-100 position-relative block-centered_title pb-4">
            <style>
                         </style>
            
<div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
    <div class="container">
        <div class="row ">
            <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">A robust network – Built to last</h2>            </div>
        </div>
    </div>
</div>
        </div>
                <div id="num-section-2" class="w-100 position-relative block-4_main_topics_icon_title_and_summary pb-5">
            <style>
                            </style>
            
<div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
	<div class="container">
		<div class="row rseh-md justify-content-center fix-style-4">
                            <div class="col-12 item-box item-box-4 p-0 p-md-2">
                    <div class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight" style={{height: '355px'}}>
                        <div class="d-block w-100 mb-3">
                            <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000005100-1.svg" alt="Group 1000005100"/>
                        </div>
                        <div class="d-block w-100 mb-3">
                            <h4 class="style-h4">Redundant infrastructure</h4>
                        </div>
                        <div class="d-block w-100">
                            <div class="p-small">With a cloud PoP network collocated in Singapore, we offer fully redundant tier-1 and tier-2 connections for reliable and redundant enterprise-grade infrastructure.</div>
                        </div>
                    </div>
                </div>
                            <div class="col-12 item-box item-box-4 p-0 p-md-2">
                    <div class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight" style={{height: '355px'}}>
                        <div class="d-block w-100 mb-3">
                            <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000005101-1.svg" alt="Group 1000005101"/>
                        </div>
                        <div class="d-block w-100 mb-3">
                            <h4 class="style-h4">Regional on-site  support</h4>
                        </div>
                        <div class="d-block w-100">
                            <div class="p-small">Our network operations centre (NOC) team is on standby 24/7, with dedicated L2 engineers available round the clock to handle any unexpected issues.</div>
                        </div>
                    </div>
                </div>
                            <div class="col-12 item-box item-box-4 p-0 p-md-2">
                    <div class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight" style={{height: '355px'}}>
                        <div class="d-block w-100 mb-3">
                            <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000005404.svg" alt="Group 1000005404"/>
                        </div>
                        <div class="d-block w-100 mb-3">
                            <h4 class="style-h4">Compliance at the core</h4>
                        </div>
                        <div class="d-block w-100">
                            <div class="p-small">We built our network infrastructure focused on adherence to data privacy laws with data processor control methods when dealing with subscriber information.</div>
                        </div>
                    </div>
                </div>
                            <div class="col-12 item-box item-box-4 p-0 p-md-2">
                    <div class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight" style={{height: '355px'}}>
                        <div class="d-block w-100 mb-3">
                            <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000004944.svg" alt="Group 1000004944"/>
                        </div>
                        <div class="d-block w-100 mb-3">
                            <h4 class="style-h4">Security &amp; Peace of mind</h4>
                        </div>
                        <div class="d-block w-100">
                            <div class="p-small">Our carrier connections come with variety of encryption methods to ensure your comms are secure no matter where your conversations are heading.</div>
                        </div>
                    </div>
                </div>
            		</div>
	</div>
</div>
        </div>
                <div id="num-section-3" class="w-100 position-relative block-top_title pt-5">
            <style>
                            </style>
            <div class="w-100 section-top_title">
	<div class="container">
        <div class="row align-items-center style-blue">
            <div class="col-12 text-center ">
                <div class="top-title ms-0 mx-md-auto">
                    <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000005372.svg" alt="TOKU VOICE SERVICES" class="me-2"/>TOKU VOICE SERVICES                </div>
            </div>
        </div>
	</div>
</div>
        </div>
                <div id="num-section-4" class="w-100 position-relative block-centered_title ">
            <style>
                           </style>
            
<div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
    <div class="container">
        <div class="row ">
            <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">Consolidate your connectivity</h2>            </div>
        </div>
    </div>
</div>
        </div>
                <div id="num-section-5" class="w-100 position-relative block-centered_title pb-3">
            <style>
                           </style>
            
<div class="w-100 section-centered_title pb-3 py-3 align-items-center text-left text-md-center">
    <div class="container">
        <div class="row ">
            <div class="col-12 mx-auto col-md-8">
                <p class="p-small">Our voice services were designed to offer providers &amp; resellers a single partner to unlock the APAC market. Do away with difficult paperwork and language barriers for quick access to high-quality voice connections in APAC.</p>            </div>
        </div>
    </div>
</div>
        </div>
                <div id="num-section-6" class="w-100 position-relative block-accordion_list pb-5 pt-5">
            <AccordionComponent/>
        </div>
                <div id="num-section-7" class="w-100 position-relative block-centered_title ">
            <style>
                           </style>
            
<div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
    <div class="container">
        <div class="row ">
            <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">Don’t struggle with your network woes</h2>            </div>
        </div>
    </div>
</div>
        </div>
                <div id="num-section-8" class="w-100 position-relative block-centered_title pb-4">
            <style>
                           </style>
            
<div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
    <div class="container">
        <div class="row ">
            <div class="col-12 mx-auto col-md-8">
                <p class="p-small">As a provider with full ownership and control over our connectivity, we offer several supplementary services for enterprises that need that more help getting set up.</p>            </div>
        </div>
    </div>
</div>
        </div>
                <div id="num-section-9" class="w-100 position-relative block-icon_boxes ">
            <style>
                

</style>
<div class="w-100 section-icon_boxes">
	<div class="container">
		<div class="row">
            			<div class="col-12 col-md-6 mb-5">
                <div class="box d-block d-lg-flex">
                    <div class="icon p-3 my-auto d-none d-lg-flex" style={{background: '#f9f6ff'}}>
                        <img src="https://toku.co/wp-content/uploads/2023/01/Group-hand.svg" class="mx-auto" alt="Group hand"/>
                    </div>
                    <div class="mobile-icon w-100 d-block d-lg-none text-end p-3">
                        <img src="https://toku.co/wp-content/uploads/2023/01/Group-hand.svg" class="ms-auto me-0" alt="Group hand"/>
                    </div>
                    <div class="content p-3">
                        <h4 class="style-h4">Portability &amp; Emergency Services in certain markets</h4>
                        <span class="p-small"></span>
                    </div>
                </div>
			</div>
            			<div class="col-12 col-md-6 mb-5">
                <div class="box d-block d-lg-flex">
                    <div class="icon p-3 my-auto d-none d-lg-flex" style={{background: '#f9f6ff'}}>
                        <img src="https://toku.co/wp-content/uploads/2023/01/Clip-path-group.svg" class="mx-auto" alt="Clip path group"/>
                    </div>
                    <div class="mobile-icon w-100 d-block d-lg-none text-end p-3">
                        <img src="https://toku.co/wp-content/uploads/2023/01/Clip-path-group.svg" class="ms-auto me-0" alt="Clip path group"/>
                    </div>
                    <div class="content p-3">
                        <h4 class="style-h4">Number masking inbound call data for marketing analytics</h4>
                        <span class="p-small"></span>
                    </div>
                </div>
			</div>
            			<div class="col-12 col-md-6 mb-5">
                <div class="box d-block d-lg-flex">
                    <div class="icon p-3 my-auto d-none d-lg-flex" style={{background: '#f9f6ff'}}>
                        <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000005408.svg" class="mx-auto" alt="Group 1000005408"/>
                    </div>
                    <div class="mobile-icon w-100 d-block d-lg-none text-end p-3">
                        <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000005408.svg" class="ms-auto me-0" alt="Group 1000005408"/>
                    </div>
                    <div class="content p-3">
                        <h4 class="style-h4">Numbering block consumption commitments</h4>
                        <span class="p-small"></span>
                    </div>
                </div>
			</div>
            			<div class="col-12 col-md-6 mb-5">
                <div class="box d-block d-lg-flex">
                    <div class="icon p-3 my-auto d-none d-lg-flex" style={{background: '#f9f6ff'}}>
                        <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000005398.svg" class="mx-auto" alt="Group 1000005398"/>
                    </div>
                    <div class="mobile-icon w-100 d-block d-lg-none text-end p-3">
                        <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000005398.svg" class="ms-auto me-0" alt="Group 1000005398"/>
                    </div>
                    <div class="content p-3">
                        <h4 class="style-h4">Peak/Mass calling event management</h4>
                        <span class="p-small"></span>
                    </div>
                </div>
			</div>
            		</div>
	</div>
</div>
        </div>
                    <div class="w-100 d-sm-none bg-archer bg-archer-down">
                <svg width="400" height="87" viewBox="0 0 400 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 87C0 87 80.5 46.5 200 46.5C319.5 46.5 400 87 400 87V0H0V87Z" fill="#f1f5fe"></path>
                </svg>
            </div>
            <div class="w-100 d-none d-sm-block bg-archer bg-archer-down">
                <svg width="2500" height="178" viewBox="0 0 2500 178" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z" fill="#f1f5fe"></path>
                </svg>
            </div>
                <div id="num-section-10" class="w-100 position-relative block-top_title ">
            <style>
                           </style>
            <div class="w-100 section-top_title">
	<div class="container">
        <div class="row align-items-center style-turquoise">
            <div class="col-12 text-center pt-3">
                <div class="top-title ms-0 mx-md-auto">
                    <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000004614.svg" alt="TOKU MESSAGING SERVICES" class="me-2"/>TOKU MESSAGING SERVICES                </div>
            </div>
        </div>
	</div>
</div>
        </div>
                <div id="num-section-11" class="w-100 position-relative block-centered_title ">
            <style>
                           </style>
            
<div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
    <div class="container">
        <div class="row ">
            <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2"> There's more to SMS than just hitting "send"</h2>            </div>
        </div>
    </div>
</div>
        </div>
                <div id="num-section-12" class="w-100 position-relative block-centered_title ">
            <style>
                            </style>
            
<div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
    <div class="container">
        <div class="row ">
            <div class="col-12 mx-auto col-md-8">
                <p class="p-small">Reliable messaging and deliverability can be challenging to access, and many regional markets can be a tough nut to crack. Toku is a licensed tier-1 aggregator in Singapore, with direct connectivity to APAC mobile network operators and beyond for carrier-grade messaging.</p>            </div>
        </div>
    </div>
</div>
        </div>
                <div id="num-section-13" class="w-100 position-relative block-block_text pb-5">
            <style>
                            </style>
            <div class="container section-block_text">
	<div class="row">
		<div class="col-12">
			<p><img decoding="async" loading="lazy" class="aligncenter wp-image-5433 size-large" src="https://toku.co/wp-content/uploads/2023/02/Group-1000005412-1024x529.png" alt="" width="1024" height="529" srcset="https://toku.co/wp-content/uploads/2023/02/Group-1000005412-1024x529.png 1024w, https://toku.co/wp-content/uploads/2023/02/Group-1000005412-300x155.png 300w, https://toku.co/wp-content/uploads/2023/02/Group-1000005412-768x397.png 768w, https://toku.co/wp-content/uploads/2023/02/Group-1000005412.png 1134w" sizes="(max-width: 1024px) 100vw, 1024px"/></p>
		</div>
	</div>
</div>
        </div>
                <div id="num-section-14" class="w-100 position-relative block-centered_title pb-5">
            <style>
                           </style>
            
<div class="w-100 section-centered_title pb-5 py-3 align-items-center text-left text-md-center">
    <div class="container">
        <div class="row ">
            <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">Reliable SMS backed by rock-solid infrastructure</h2>            </div>
        </div>
    </div>
</div>
        </div>
                <div id="num-section-15" class="w-100 position-relative block-items_left_icon pb-3">
            <style>
                           </style>
            <div class="w-100 section-items_left_icon">
	<div class="container">
        <div class="row align-items-center">
            	            <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 ">
                    <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                        <img src="https://toku.co/wp-content/uploads/2023/01/Frame-46386.svg" alt="Long code 
resources" class="middle-box"/>
                    </div>
                    <div class="col pe-1">
                        <p class="p-small mb-0">Long code 
resources</p>
                    </div>
                </div>
            	            <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 ">
                    <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                        <img src="https://toku.co/wp-content/uploads/2023/01/Frame-46387.svg" alt="Sender ID name registration support" class="middle-box"/>
                    </div>
                    <div class="col pe-1">
                        <p class="p-small mb-0">Sender ID name registration support</p>
                    </div>
                </div>
            	            <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 ">
                    <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                        <img src="https://toku.co/wp-content/uploads/2023/01/Frame-46388.svg" alt="Both-way (MO/MT) 
services" class="middle-box"/>
                    </div>
                    <div class="col pe-1">
                        <p class="p-small mb-0">Both-way (MO/MT) 
services</p>
                    </div>
                </div>
                    </div>
	</div>
</div>
        </div>
                <div id="num-section-16" class="w-100 position-relative block-items_left_icon pb-5">
            <style>
                            </style>
            <div class="w-100 section-items_left_icon">
	<div class="container">
        <div class="row align-items-center">
            	            <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 ">
                    <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                        <img src="https://toku.co/wp-content/uploads/2023/01/Frame-46389.svg" alt="API methods 
supported" class="middle-box"/>
                    </div>
                    <div class="col pe-1">
                        <p class="p-small mb-0">API methods 
supported</p>
                    </div>
                </div>
            	            <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 ">
                    <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                        <img src="https://toku.co/wp-content/uploads/2023/02/Frame-46364.svg" alt="Local transit in APAC countries" class="middle-box"/>
                    </div>
                    <div class="col pe-1">
                        <p class="p-small mb-0">Local transit in APAC countries</p>
                    </div>
                </div>
            	            <div class="col-12 col-lg-4 d-flex item-box px-sm-5 mb-4 mb-sm-1 ">
                    <div class="col-auto ps-sm-2 pe-4 pe-sm-5">
                        <img src="https://toku.co/wp-content/uploads/2023/01/Frame-46391.svg" alt="Verification &amp; mass 
marketing expertise" class="middle-box"/>
                    </div>
                    <div class="col pe-1">
                        <p class="p-small mb-0">Verification &amp; mass 
marketing expertise</p>
                    </div>
                </div>
                    </div>
	</div>
</div>
        </div>
                <div id="num-section-17" class="w-100 position-relative block-cta_strip_no_image text-white py-md-5" style={{backgroundColor:'#7DBAAB'}}>
            <style>
                          </style>
            
<div class="w-100 section-cta_strip_no_image">
	<div class="container">
		<div class="row text-white py-md-5">
			<div class="col-12 text-center p-md-5">
				<div class="d-block w-100">
					<h2 class="style-h2 mb-4">Want to expand your reach in APAC?</h2>
				</div>
								<div class="d-block w-100">
					<button  class="mx-auto btn btn-yellow"  >
						Talk to an Expert 					</button>
            
				</div>
			</div>
		</div>
	</div>
</div>
{/* <ContactForm/> */}

        </div>
        <Footer />
    </div>
  )
}


const list_accordion_13 = [
    {
      title: "In-App Voice Calling",
      text: "<p>Make it easy to connect with you or third parties via voice calls, and create a secure user experience within your app. Gather valuable data you can turn into powerful business insights.</p>",
      image:
        "https://toku.co/wp-content/uploads/2023/01/Work-hard-play-hard-5.png",
      bottom_arrow_link: {
        title: "Add In-App Voice Calling Today",
        url: "/in-app-voice-calling/",
        target: "",
      },
    },
    {
      title: "Number Masking",
      text: "<p>Give your customers and users a secure and easy way to make or receive calls without revealing their personal info, whilst keeping transactions within your platform.</p>",
      image: "https://toku.co/wp-content/uploads/2023/01/Image-532.png",
      bottom_arrow_link: {
        title: "Learn More About Number Masking",
        url: "/number-masking",
        target: "",
      },
    },
    {
      title: "Programmable Voice",
      text: "<p>Discover new voice capabilities including Call Forwarding, IVR, Alerts &amp; Notifications.</p>",
      image:
        "https://toku.co/wp-content/uploads/2023/01/Programmable-Voice-2.png",
      bottom_arrow_link: {
        title: "Integrate Voice Within Your Platform",
        url: "/programmable-voice/",
        target: "",
      },
    },
    {
      title: "Programmable Messaging",
      text: "<p>Add advanced messaging features and capabilities to your platform for transformative customer interactions.</p>",
      image:
        "https://toku.co/wp-content/uploads/2023/01/Programmable-Messaging-23.png",
      bottom_arrow_link: {
        title: "Explore More About Programmable Messaging",
        url: "/programmable-messaging/",
        target: "",
      },
    },
    {
      title: "User Verification",
      text: "<p>It's easy with multi-channel authentication options including voice calls, messages, and silent verification.</p>",
      image: "https://toku.co/wp-content/uploads/2023/01/Image-23456.png",
      bottom_arrow_link: {
        title: "Discover New Ways to Verify Your Customers",
        url: "/user-verification/",
        target: "",
      },
    },
  ];
  
  export function AccordionComponent() {
    const list_accordion_6 = [
      {
        title: "Global Connectivity, Local To APAC",
        text: '<p>Expanding into APAC has never been easier. Using Toku as your communications technology provider means you get access to our APAC enterprise telco network for easy expansion into the region.</p><p><a href="https://toku.co/blog/how-to-connect-apac-business-globally/">Learn More About Our Connectivity to APAC</a></p>',
        image:
          "https://toku.co/wp-content/uploads/2023/01/Global-Connectivity-Local-To-APAC-image.png",
        bottom_arrow_link: "",
      },
      {
        title: "Customised to your needs",
        text: '<p>Work with our team of technology experts to customise our contact centre platform or voice and messaging APIs, for a truly bespoke customer engagement solution that meets your needs.</p><p><a href="https://toku.co/">Check Out Our Products</a></p>',
        image:
          "https://toku.co/wp-content/uploads/2023/01/Work-with-us-to-create-your-ideal-solution-image.png",
        bottom_arrow_link: "",
      },
      {
        title: "Programmable Communications",
        text: '<p>Create new ways to communicate with your customers with our suite of programmable APIs designed to work with your platform and our telco network.</p><p><a href="https://toku.co/programmable-voice/">Learn More About Our Programmable Voice Solutions</a></p><p>&nbsp;</p>',
        image:
          "https://toku.co/wp-content/uploads/2023/01/Programmable-Communications-image.png",
        bottom_arrow_link: "",
      },
      {
        title: "Regional support, whenever you need it",
        text: '<p>Whatever your needs, our team of APAC-based experts are on call 24/7 to help you with your communications and ensure you can provide the best possible customer experience.</p><p><a href="https://toku.co/about-us/">Learn More About Us</a></p>',
        image:
          "https://toku.co/wp-content/uploads/2023/01/Regional-support-whenever-you-need-it-image-1.png",
        bottom_arrow_link: "",
      },
    ];
    const [activeItem, setActiveItem] = useState(0);
  
    const showItem = (index) => {
      setActiveItem(index);
    };
    return (
      <div
        id="num-section-13"
        className="w-100 position-relative block-accordion_list py-5"
      >
        <style>{`
          /* CSS styles here */
        `}</style>
        <div className="w-100 section-accordion_list ng-scope">
          <div className="container">
            <div className="row d-md-none">
              <div className="accordion accordion-flush" id="accordionFlush-6">
                {list_accordion_6.map((item, index) => (
                  <div className="accordion-item" key={index}>
                    <h2
                      className="accordion-header"
                      id={`flush-heading${index + 1}`}
                    >
                      <button
                        className={`accordion-button ${
                          index === activeItem ? "style-h4" : "collapsed style-h4"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${index + 1}`}
                        aria-expanded={index === 0 ? "true" : "false"}
                        aria-controls={`flush-collapse${index + 1}`}
                      >
                        {item.title}
                      </button>
                    </h2>
                    <div
                      id={`flush-collapse${index + 1}`}
                      className={`accordion-collapse collapse ${
                        index === 0 ? "show" : ""
                      }`}
                      aria-labelledby={`flush-heading${index + 1}`}
                      data-bs-parent="#accordionFlush-6"
                    >
                      <div className="accordion-body">
                        <div className="d-block w-100">
                          <img
                            decoding="async"
                            loading="lazy"
                            className="aligncenter size-full size-full mb-3 mb-md-0"
                            src={item.image}
                            alt={item.title}
                          />
                        </div>
                        <div
                          className="d-block w-100 p-small"
                          dangerouslySetInnerHTML={{ __html: item.text }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="row d-none d-md-flex">
              <div className="col-6">
                <h3 className="style-h3 mb-5">How we deliver our offering</h3>
                {list_accordion_6.map((item, index) => (
                  <button
                    className={`list-accordion style-h4 w-100 ng-binding ng-scope ${
                      index === activeItem ? "active" : "text-dark"
                    }`}
                    key={index}
                    onClick={() => showItem(index)}
                    style={{ boxShadow: "none" }}
                  >
                    {item.title}
                  </button>
                ))}
              </div>
              <div className="col-6">
                <div className="d-block w-100 mb-2">
                  <img
                    decoding="async"
                    loading="lazy"
                    className="size-full"
                    src={list_accordion_6[activeItem].image}
                    alt={list_accordion_6[activeItem].title}
                  />
                </div>
                <div className="d-block w-100">
                  <div className="style-h4 mb-2">
                    {list_accordion_6[activeItem].title}
                  </div>
                </div>
                <div
                  className="d-block w-100 ng-binding"
                  dangerouslySetInnerHTML={{
                    __html: list_accordion_6[activeItem].text,
                  }}
                />
                <a
                  className="btn btn-arrow text-start ng-binding ng-hide"
                  href={list_accordion_6[activeItem].bottom_arrow_link}
                  target=""
                ></a>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
