import React from 'react'
import './partners.css'
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import optimize_customer_img from "../../assets/images/optimize-customer-img.png";
export default function 
() {
  return (
    <div className='partners'>
        <Navbar
        extra_heading = "KASOOKOO CONTACT CENTRE"
        heading="A highly efficient Contact Center for Increased Productivity"
        text="Enable smooth and effortless customer interactions by leveraging a comprehensive cloud-based contact center solution that seamlessly integrates calls, messages, and OTT conversations. Empower your agents and managers with robust integrations that enhance customer engagement to an unprecedented level."
        img={optimize_customer_img}
        contact_center_form = "true"
        first_section = {true}
      ></Navbar>
           <div id="num-section-1" class="w-100 position-relative block-centered_title pb-4">
            <style>
                            </style>
            
<div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
    <div class="container">
        <div class="row ">
            <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">Why become a Toku partner?</h2>            </div>
        </div>
    </div>
</div>
        </div>
                <div id="num-section-2" class="w-100 position-relative block-4_main_topics_icon_title_and_summary mw-976-c--disabled">
            <style>
                            </style>
            
<div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
	<div class="container">
		<div class="row rseh-md justify-content-center fix-style-any">
                            <div class="col-12 item-box item-box-any p-0 p-md-2">
                    <div class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight" style={{height: '301px'}}>
                        <div class="d-block w-100 mb-3">
                            <img src="https://toku.co/wp-content/uploads/2023/01/More-value-v2.svg" alt="More value v2"/>
                        </div>
                        <div class="d-block w-100 mb-3">
                            <h4 class="style-h4">Better Value</h4>
                        </div>
                        <div class="d-block w-100">
                            <div class="p-small">Offer our Contact Centre and Communication API solutions to help your clients enhance customer engagement in APAC.</div>
                        </div>
                    </div>
                </div>
                            <div class="col-12 item-box item-box-any p-0 p-md-2">
                    <div class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight" style={{height: '301px'}}>
                        <div class="d-block w-100 mb-3">
                            <img src="https://toku.co/wp-content/uploads/2023/01/New-Revenue.svg" alt="New Revenue"/>
                        </div>
                        <div class="d-block w-100 mb-3">
                            <h4 class="style-h4">New Revenue</h4>
                        </div>
                        <div class="d-block w-100">
                            <div class="p-small">Create new streams of revenue to diversify your business, while helping your customers improve their customer experience.   </div>
                        </div>
                    </div>
                </div>
                            <div class="col-12 item-box item-box-any p-0 p-md-2">
                    <div class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight" style={{height: '301px'}}>
                        <div class="d-block w-100 mb-3">
                            <img src="https://toku.co/wp-content/uploads/2023/01/Lasting-Relationships-icon.svg" alt="Lasting Relationships icon"/>
                        </div>
                        <div class="d-block w-100 mb-3">
                            <h4 class="style-h4">Lasting Relationships</h4>
                        </div>
                        <div class="d-block w-100">
                            <div class="p-small">Get APAC expertise and get access to experts who can customise our solutions to meet your long-term goals.</div>
                        </div>
                    </div>
                </div>
            		</div>
	</div>
</div>
        </div>
                    <div class="w-100 d-md-none bg-archer bg-archer-up">
                <svg width="400" height="87" viewBox="0 0 400 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z" fill="#f1f5fe"></path>
                </svg>
            </div>
            <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
                <svg width="2500" height="178" viewBox="0 0 2500 178" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z" fill="#f1f5fe"></path>
                </svg>
            </div>
                <div id="num-section-3" class="w-100 position-relative block-image_side_and_text_side_repeater py-3">
            <style>
                            </style>
            <div class="w-100 section-row_repeater">
	<div class="container">
                    <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
                <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                    <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000004486.png" alt="Group 1000004486" class="mx-auto mb-4 my-md-2"/>
                </div>
                <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                    <div class="w-100 d-block mb-3">
	                    <h2 class="style-h2">Grow your APAC business as a Channel Partner</h2>                    </div>
                                            <div class="w-100 d-block">
                            <div class="p-small"><p>Many enterprises operating in APAC need enterprise-grade cloud connectivity and expertise to grow.</p>
<p>If you’re a System Integrator, Reseller, Consultant, ISV (Independent Software Vendor), or BPO, you can resell and refer Toku’s cloud communication solutions to offer a more robust service and attract more regional business.</p>
</div>
                        </div>
                                        <div class="w-100 d-block">
                                            </div>
                    <div class="w-100 d-block">
                                                    <a href="#Partners" class="btn btn-arrow text-start" target="" data-bs-toggle="modal" data-bs-target="#Partners">
                                Join us as a Channel Partner                            </a>
                                            </div>
                </div>
            </div>
            	</div>
</div>
        </div>
                    <div class="w-100 d-sm-none bg-archer bg-archer-down">
                <svg width="400" height="87" viewBox="0 0 400 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 87C0 87 80.5 46.5 200 46.5C319.5 46.5 400 87 400 87V0H0V87Z" fill="#f1f5fe"></path>
                </svg>
            </div>
            <div class="w-100 d-none d-sm-block bg-archer bg-archer-down">
                <svg width="2500" height="178" viewBox="0 0 2500 178" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z" fill="#f1f5fe"></path>
                </svg>
            </div>
                <div id="num-section-4" class="w-100 position-relative block-image_side_and_text_side_repeater py-3">
            <style>
                            </style>
            <div class="w-100 section-row_repeater">
	<div class="container">
                    <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
                <div class="col-12 col-md-6 text-center text-md-start  pe-lg-5">
                    <img src="https://toku.co/wp-content/uploads/2023/01/Image-3-2.png" alt="Image 3" class="mx-auto mb-4 my-md-2"/>
                </div>
                <div class="col-12 col-md-6 mt-3 mt-md-0  ps-lg-5">
                    <div class="w-100 d-block mb-3">
	                    <h2 class="style-h2">Add value to your comms solutions as a Technology Partner</h2>                    </div>
                                            <div class="w-100 d-block">
                            <div class="p-small"><p>Integrate Toku’s (CX) customer experience solutions with your products to build truly engaging buyer journeys and connect with new growth.</p>
</div>
                        </div>
                                        <div class="w-100 d-block">
                                            </div>
                    <div class="w-100 d-block">
                                                    <a href="#Partners" class="btn btn-arrow text-start" target="" data-bs-toggle="modal" data-bs-target="#Partners">
                                Become a Technology Partner                            </a>
                                            </div>
                </div>
            </div>
            	</div>
</div>
        </div>
                    <div class="w-100 d-md-none bg-archer bg-archer-up">
                <svg width="400" height="87" viewBox="0 0 400 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z" fill="#f1f5fe"></path>
                </svg>
            </div>
            <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
                <svg width="2500" height="178" viewBox="0 0 2500 178" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z" fill="#f1f5fe"></path>
                </svg>
            </div>
                <div id="num-section-5" class="w-100 position-relative block-image_side_and_text_side_repeater pb-4 pt-3">
            <style>
                          </style>
            <div class="w-100 section-row_repeater">
	<div class="container">
                    <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
                <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                    <img src="https://toku.co/wp-content/uploads/2023/01/Frame-46201-1.png" alt="Frame 46201" class="mx-auto mb-4 my-md-2"/>
                </div>
                <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                    <div class="w-100 d-block mb-3">
	                    <h2 class="style-h2">Get access to multiple Customer Experience solutions </h2>                    </div>
                                            <div class="w-100 d-block">
                            <div class="p-small"><p>As a Toku Partner, you get access to a full range of robust solutions designed to supercharge your customer experience, including cloud contact centre, communication APIs, cloud telephony, and other cloud communication solutions working together seamlessly.</p>
</div>
                        </div>
                                        <div class="w-100 d-block">
                                            </div>
                    <div class="w-100 d-block">
                                                    <a href="#Partners" class="btn btn-arrow text-start" target="" data-bs-toggle="modal" data-bs-target="#Partners">
                                Become a Partner Today                            </a>
                                            </div>
                </div>
            </div>
            	</div>
</div>
        </div>
                <div id="num-section-6" class="w-100 position-relative block-cta_strip_2 ">
            <style>
                            </style>
            
<div class="w-100 section-cta_strip_2 text-white text-center text-md-start " style={{backgroundColor:'#7DBAAB'}}>
	<div class="container pt-5 pt-md-0">
		<div class="row py-md-5">
            <div class="col-12 col-md-6 d-flex ps-md-5  order-last order-md-first" style={{verticalAlign: 'bottom'}}>
                <img src="https://toku.co/wp-content/uploads/2023/01/Frame-46200-1.png" alt="Go further, together with Toku" class="w-100 img-class" style={{objectFit: 'contain'}}/>
            </div>
			<div class="col-12 col-md-6 py-4 py-md-5 my-auto px-lg-4 ">
				<div class="d-block w-100">
					<h2 class="style-h2">Go further, together with Toku</h2>
				</div>
                                    <div class="d-block w-100">
                        <div class="p-big"><p>Join a tech and channel partner ecosystem for better tech, growth, and value for customers.</p>
</div>
                    </div>
                                				<div class="d-block w-100 mt-4">
                    <a href="#Partners" class="btn btn-yellow border-radius-50 mx-auto ms-0" target="" data-bs-toggle="modal" data-bs-target="#Partners" >
						Become a Partner Today                    </a>
				</div>
                			</div>
		</div>
	</div>
</div>

        </div>
        <Footer />
    </div>
  )
}
