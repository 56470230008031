import React from "react";
import "./numbermasking.css";

import Footer from "../../components/footer/Footer";

import Navbar from "../../components/navbar/Navbar";
import number_masking_header_img from "../../assets/images/Number-Masking-header-image-2.png";
import security_icon from "../../assets/images/Icon-security-1.svg";
import trust_icon from "../../assets/images/trust-img.svg";
import local_presence_icon from "../../assets/images/local-presence-icon.svg";
import call_or_message from "../../assets/images/call-or-message.png";
import connect_img from "../../assets/images/connect-img.png";
import conversation_img from "../../assets/images/conversation-img.png";
import end_call from "../../assets/images/end-call-img.png";
import superdpowerd_by_img from "../../assets/images/superpowerd-by-img.png";
import deeper_eng_img from "../../assets/images/deeper-eng-img.png";
export default function NumberMasking() {
  return (
    <div className="number-masking">
      <Navbar
        heading="Protect users & improve privacy"
        text="Keep the phone numbers of your customers and users private. Improve the privacy of your calls and messages, avoid off-platform transactions and seamlessly facilitate communication."
        img={number_masking_header_img}
        extra_heading="KASOOKOO NUMBER MASKING"
        first_section = {true}
      ></Navbar>

      <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title pb-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Communicate seamlessly without compromising privacy
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="num-section-2" class="w-100 position-relative block-4_main_topics_icon_title_and_summary pb-5">
            <style>
                         </style>
            
<div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
	<div class="container">
		<div class="row rseh-md justify-content-center fix-style-any">
                            <div class="col-12 item-box item-box-any p-0 p-md-2">
                    <div class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight" style={{height: '328px'}}>
                        <div class="d-block w-100 mb-3">
                            <img src="https://toku.co/wp-content/uploads/2023/01/Icon-security-1.svg" alt="Icon-security"/>
                        </div>
                        <div class="d-block w-100 mb-3">
                            <h4 class="style-h4">Improve security</h4>
                        </div>
                        <div class="d-block w-100">
                            <div class="p-small">Create an added layer of security for your team, users, and business and ensure you stay compliant in markets that require it.</div>
                        </div>
                    </div>
                </div>
                            <div class="col-12 item-box item-box-any p-0 p-md-2">
                    <div class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight" style={{height: '328px'}}>
                        <div class="d-block w-100 mb-3">
                            <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000005021.svg" alt="Group 1000005021"/>
                        </div>
                        <div class="d-block w-100 mb-3">
                            <h4 class="style-h4">Increase trust</h4>
                        </div>
                        <div class="d-block w-100">
                            <div class="p-small">Utilise secure communications, so customers know their data is protected, and their information is safe on your platform.</div>
                        </div>
                    </div>
                </div>
                            <div class="col-12 item-box item-box-any p-0 p-md-2">
                    <div class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight" style={{height: '328px'}}>
                        <div class="d-block w-100 mb-3">
                            <img src="https://toku.co/wp-content/uploads/2023/01/Group-1000005022.svg" alt="Group 1000005022"/>
                        </div>
                        <div class="d-block w-100 mb-3">
                            <h4 class="style-h4">Create a local  presence</h4>
                        </div>
                        <div class="d-block w-100">
                            <div class="p-small">Adapt to your markets and present local numbers to your customers for increased answer rates without the physical infrastructure.</div>
                        </div>
                    </div>
                </div>
            		</div>
	</div>
</div>
        </div>
      

      <div
        id="num-section-3"
        class="w-100 position-relative block-centered_title pt-5"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">How does number masking work?</h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-4"
        class="w-100 position-relative block-image_side_and_text_side_repeater "
      >
        <style></style>
        <div class="w-100 section-row_repeater">
          <div class="container">
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src={call_or_message}
                  alt=""
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Step 1: Call or Message</h3>{" "}
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      A customer makes a call or sends a message to your
                      business from their private number to speak to a driver,
                      book a place to stay, or to purchase an item.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block"></div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  pe-lg-5">
                <img src={connect_img} alt="" class="mx-auto mb-4 my-md-2" />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  ps-lg-5">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Step 2: Connect</h3>{" "}
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      KASOOKOO connects the customer with the driver, and they
                      are both assigned a virtual number. Neither sees the
                      other’s phone number; they see only the local virtual
                      number.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block"></div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src={conversation_img}
                  alt=""
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Step 3: Conversation</h3>{" "}
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      The customer and the driver can now communicate
                      anonymously without either user seeing the other’s
                      personal number.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block"></div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  pe-lg-5">
                <img src={end_call} alt="" class="mx-auto mb-4 my-md-2" />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  ps-lg-5">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Step 4: End Call</h3>{" "}
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      The transaction ends, and the agent or customer cannot be
                      reached using the same virtual number.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-5"
        class="w-100 position-relative block-center_button "
      >
        <style></style>
        <div class="container section-center_button">
          <div class="row ">
            <div class="col-12 text-start text-md-center">
              <a
                href="#NumberMasking"
                class="btn btn-arrow"
                target=""
                data-bs-toggle="modal"
                data-bs-target="#NumberMasking"
              >
                Turn User Privacy Into a Competitive Differentiator Today!{" "}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 d-md-none bg-archer bg-archer-up">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div class="w-100 d-none d-md-block bg-archer bg-archer-up p-0">
        <svg
          className="p-0"
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div
        id="num-section-6"
        class="w-100 position-relative block-centered_title pt-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pt-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Learn how ride-hailing giant Gojek uses KASOOKOO APIs to
                  superpower their App{" "}
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-7"
        class="w-100 position-relative block-centered_title "
      >
        <style></style>

        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Gojek, the ride-hailing transport service, transformed the way
                  they handle lost and found co-operation between drivers and
                  customers by adding calling, messaging, and number masking
                  capabilities to their app.{" "}
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-8"
        class="w-100 position-relative block-center_button pb-4"
      >
        <style></style>
        <div class="container ">
          <div class="row pb-4">
            <div class="col-12 text-start text-md-center">
              <a href="" class="btn btn-arrow" target="">
                Get the Full Story!{" "}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-9"
        class="w-100 position-relative block-block_text text-center px-md-4 px-xl-5 pb-4 pb-md-5 pt-3 pt-md-4 d-none d-md-block"
      >
        <style></style>
        <div class="container section-block_text">
          <div class="row">
            <div class="col-12">
              <p>
                <img
                  decoding="async"
                  loading="lazy"
                  class="aligncenter wp-image-3799 size-large"
                  src={superdpowerd_by_img}
                  alt=""
                  width="1024"
                  height="432"
                  sizes="(max-width: 1024px) 100vw, 1024px"
                />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-11"
        class="w-100 position-relative block-cta_strip_2 "
      >
        <style></style>

        <div class="w-100 section-cta_strip_2 text-white text-center text-md-start pb-md-3 ">
          <div class="container pt-5 pt-md-0">
            <div class="row py-md-5">
              <div
                class="col-12 col-md-6 d-flex ps-md-5  order-first order-md-first"
                style={{ verticalAlign: "bottom" }}
              >
                <img
                  src={deeper_eng_img}
                  alt="Deeper engagements begin with secure communications"
                  class="w-100 img-class"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div class="col-12 col-md-6 py-4 py-md-5 my-auto px-lg-4 ">
                <div class="d-block w-100">
                  <h2 class="style-h2">
                    Deeper engagements begin with secure communications
                  </h2>
                </div>
                <div class="d-block w-100 mt-4">
                  <a
                    href="#NumberMasking"
                    class="btn btn-yellow border-radius-50 mx-auto ms-0"
                    target=""
                    data-bs-toggle="modal"
                    data-bs-target="#NumberMasking"
                  >
                    Talk to an Expert{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}
