import React from "react";
import "./programmablevoice.css";
import Navbar from "../../components/navbar/Navbar";

import Footer from "../../components/footer/Footer";
import optimize_customer_img from "../../assets/images/optimize-customer-img.png";
import comapaign_img from "../../assets/images/consulting-img.png";
import programmableVoiceImg6 from "../../assets/images/programmable-voice-Img6.png"
import programmableVoiceImg8 from "../../assets/images/programmable-voice-img8.png"

import userFreindlyAPiicon from "../../assets/images/user-frinedly-api-cione.svg"
import scalingsolutionsiocn from "../../assets/images/scalingsolution.svg"
import compititivepricesicon from "../../assets/images/compititive-price-icon.svg"
import premiumQcallicon from "../../assets/images/premium-q-calls-icon.svg"

import bankicon from "../../assets/images/bank.svg"
import dollaricon from "../../assets/images/dollar.svg"
import grocerystoreicon from "../../assets/images/grocery-store.svg"
import insuranceicon from "../../assets/images/insurance.svg"
import planeicon from "../../assets/images/plane.svg"
import sendicon from "../../assets/images/send.svg"

export default function ProgrammableVoice() {
  return (
    <div className="programvoice">
      <Navbar
        heading="Improve connectivity with advanced voice capabilities "
        text="Elevate your customer experience by incorporating a scalable and programmable carrier-grade voice experience, empowering your product or platform. Unlock innovative methods to engage with your customers and personalize their interactions with your brand."
        img={comapaign_img}
        extra_heading="KASOOKOO PROGRAMMABLE VOICE "
      ></Navbar>

      {/* <div id="num-section-1" class="w-100 position-relative block-recommendations_slider ">

        <div class="w-100 section-recommendations_slider">
          <div class="container">
            <div class="col-12 mx-auto slider-recommendations col-lg-9 slick-initialized slick-slider" data-slider-autoplay="1" data-slider-autoplay_speed="4000" data-slider-arrows="" data-slider-dots="" data-slider-infinite_scroll="1" data-slider-slides_to_show="1" data-slider-slides_to_scroll="1">
              <div class="slick-list draggable"><div class="slick-track" style={{ opacity: "1", width: "696px", transform: "translate3d(0px, 0px, 0px)" }}>
                <div class="px-md-5 slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style={{ width: "696px", tabindex: "0" }}>
                  <div class="w-100 d-block px-md-5">
                    <blockquote class="text-center text-md-start mb-5 mb-md-3 px-md-5 px-lg-1 style-turquoise">
                      <p>Toku’s solution helped us to scale down on<br></br>
                        our manpower requirements, which was very helpful for us.</p>
                    </blockquote>
                  </div>
                  <div class="w-100 d-block px-md-3 px-lg-5">
                    <div class="row text-center">
                      <div class="col-12 col-md-4 text-md-start order-md-last">
                        <div class="w-100 d-block">
                          <div class="name">Celine Que</div>
                        </div>
                        <div class="w-100 d-block">
                          <div class="role">Head of Service Excellence,  Singapore</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-5 order-md-first pb-3 pb-md-0">
                        <img src="https://toku.co/wp-content/uploads/2023/01/Gojek_logo_2019-2-1.png" alt="Gojek_logo_2019 2" class="m-auto mt-1 ms-md-0" />
                      </div>
                      <div class="col-12 col-md-3 text-md-end">
                        <div class="w-100 d-block">
                          <img src="https://toku.co/wp-content/uploads/2023/01/Frame-46246.png" alt="Celine Que" class="mx-auto ms-md-auto me-md-2" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div></div></div>
            </div>
          </div>
        </div>
      </div> */}

      <div
        id="num-section-1"
        class="w-100 position-relative block-recommendations_slider "
      >
        <div class="w-100 section-recommendations_slider">
          <div class="container py-5">
            <div
              class="col-12 mx-auto slider-recommendations col-lg-9 slick-initialized slick-slider"
              data-slider-autoplay="1"
              data-slider-autoplay_speed="4000"
              data-slider-arrows=""
              data-slider-dots=""
              data-slider-infinite_scroll="1"
              data-slider-slides_to_show="1"
              data-slider-slides_to_scroll="1"
            >
              <div class="slick-list draggable">
                <div
                  class="slick-track"
                  style={{
                    opacity: 1,
                    width: "696px",
                    transform: "translate3d(0px, 0px, 0px)",
                  }}
                >
                  <div
                    class="slide px-md-5 slick-slide slick-current slick-active"
                    data-slick-index="0"
                    aria-hidden="false"
                    style={{ width: "696px" }}
                    tabindex="0"
                  >
                    <div class="w-100 d-block px-md-5">
                      <blockquote class="text-center text-md-start mb-5 mb-md-3 px-md-5 px-lg-1 style-turquoise">
                        <p>
                          Kasooko solution helped us to scale down on<br></br>
                          our manpower requirements, which was very helpful for
                          us.
                        </p>
                      </blockquote>
                    </div>
                    <div class="w-100 d-block px-md-3 px-lg-5">
                      <div class="row text-center">
                        <div class="col-12 col-md-4 text-md-start order-md-last">
                          <div class="w-100 d-block">
                            <div class="name">Celine Que</div>
                          </div>
                          <div class="w-100 d-block">
                            <div class="role">
                              Head of Service Excellence, Singapore
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-5 order-md-first pb-3 pb-md-0">
                          <img
                            src="https://toku.co/wp-content/uploads/2023/01/Gojek_logo_2019-2-1.png"
                            alt="Gojek_logo_2019 2"
                            class="m-auto mt-1 ms-md-0"
                          />
                        </div>
                        <div class="col-12 col-md-3 text-md-end">
                          <div class="w-100 d-block">
                            <img
                              src="https://toku.co/wp-content/uploads/2023/01/Frame-46246.png"
                              alt="Celine Que"
                              class="mx-auto ms-md-auto me-md-2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-2"
        class="w-100 position-relative block-centered_title pt-5 pb-4"
      >
        <div class="w-100 section-centered_title pt-5 pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Seamlessly integrate voice anywhere within your platform or
                  mobile applications
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-3"
        class="w-100 position-relative block-4_main_topics_icon_title_and_summary "
      >
        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-4">
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "350px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={userFreindlyAPiicon}
                      alt="Group 1000004359"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">User-Friendly APIs</h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Craft a tailored solution for your business with
                      user-friendly voice APIs that are simple to implement,
                      utilize, and seamlessly integrate with other essential
                      business tools.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "350px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={scalingsolutionsiocn}
                      alt="Group"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">
                      Scaling Solutions, Connecting Globally
                    </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Effortlessly incorporate PSTN calling into any
                      application, ensuring low latency, accelerated
                      time-to-market, and global reach. Benefit from virtual
                      numbers available in 120+ countries.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "350px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={compititivepricesicon}
                      alt="Group 1000004766"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Competitive Pricing </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Maximize savings with the most competitive rates in
                      Africa. Establish direct connectivity with our
                      carrier-grade network and pay only for the services you
                      utilize.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-4 p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "350px" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={premiumQcallicon}
                      alt="Group 1000004769"
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Premium Quality Calls </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Experience uninterrupted connectivity and exceptional
                      voice call quality with our dedicated carrier-grade
                      African telco network, ensuring 99.5% uptime.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-sm-none bg-archer bg-archer-down">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 87C0 87 80.5 46.5 200 46.5C319.5 46.5 400 87 400 87V0H0V87Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-sm-block bg-archer bg-archer-down">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>

      <div
        id="num-section-4"
        class="w-100 position-relative block-centered_title "
      >
        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row pb-0">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Unleash the Potential of <br /> Programmable Calling
                  Capabilities
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-5"
        class="w-100 position-relative block-centered_title pb-5"
      >
        <div class="w-100 section-centered_title pb-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Seamlessly integrate voice calls into your platform and embed
                  the ability to engage with your customers in the most suitable
                  manner.
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="num-section-6"
        class="w-100 position-relative block-accordion_list "
      >
        {/*      
             <script>
          var list_accordion_6 = [{"title":"Call Forwarding ","text":"<p>Make sure you\u2019re providing the best possible experience and ensure customer calls get to the right place by adding call-forwarding capabilities.<\/p>\n<p><a href=\"#ProgrammableVoice\">Learn more about Programmable Voice<\/a><\/p>\n","image":"https:\/\/toku.co\/wp-content\/uploads\/2023\/03\/Frame-46250.png","bottom_arrow_link":""},{"title":"IVR ","text":"<p>Create the ideal customer experience and make your agents\u2019 lives easier with customisable menus and call flows.<\/p>\n<p><a href=\"#ProgrammableVoice\">Discover more ways you can use IVR.<\/a><\/p>\n","image":"https:\/\/toku.co\/wp-content\/uploads\/2023\/01\/IVR-image.png","bottom_arrow_link":""},{"title":"Alerts & Notifications ","text":"<p>Add a new dynamic to your customer notifications by turning them into voice-based messages with text-to-speech.<\/p>\n<p><a href=\"#ProgrammableVoice\">Discover more about our Voice APIs.<\/a><\/p>\n","image":"https:\/\/toku.co\/wp-content\/uploads\/2023\/01\/Alerts-Notifications-image.png","bottom_arrow_link":""}];
          </script> */}
        <div
          class="w-100 section-accordion_list ng-scope"
          ng-controller="AccordionController"
          ng-init="load('list_accordion_6')"
        >
          <div class="container ">
            <div class="row d-md-none">
              <div class="accordion accordion-flush" id="accordionFlush-6">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-heading1">
                    <button
                      class="accordion-button  style-h4"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse1"
                      aria-expanded="true"
                      aria-controls="flush-collapse1"
                    >
                      Call Forwarding{" "}
                    </button>
                  </h2>
                  <div
                    id="flush-collapse1"
                    class="accordion-collapse collapse show"
                    aria-labelledby="flush-heading1"
                    data-bs-parent="#accordionFlush-6"
                  >
                    <div class="accordion-body">
                      <div class="d-block w-100">
                        <img
                          decoding="async"
                          loading="lazy"
                          class="aligncenter size-full size-full mb-3 mb-md-0"
                          src="https://toku.co/wp-content/uploads/2023/03/Frame-46250.png"
                          alt="Call Forwarding "
                        />
                      </div>
                      <div class="d-block w-100 p-small">
                        <p>
                          Make sure you’re providing the best possible
                          experience and ensure customer calls get to the right
                          place by adding call-forwarding capabilities.
                        </p>
                        <p>
                          <a
                            href="/"
                            data-bs-toggle="modal"
                            data-bs-target="#ProgrammableVoice"
                          >
                            Learn more about Programmable Voice
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-heading2">
                    <button
                      class="accordion-button  collapsed style-h4"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse2"
                      aria-expanded="false"
                      aria-controls="flush-collapse2"
                    >
                      IVR{" "}
                    </button>
                  </h2>
                  <div
                    id="flush-collapse2"
                    class="accordion-collapse collapse "
                    aria-labelledby="flush-heading2"
                    data-bs-parent="#accordionFlush-6"
                  >
                    <div class="accordion-body">
                      <div class="d-block w-100">
                        <img
                          decoding="async"
                          loading="lazy"
                          class="aligncenter size-full size-full mb-3 mb-md-0"
                          src="https://toku.co/wp-content/uploads/2023/01/IVR-image.png"
                          alt="IVR "
                        />
                      </div>
                      <div class="d-block w-100 p-small">
                        <p>
                          Create the ideal customer experience and make your
                          agents’ lives easier with customisable menus and call
                          flows.
                        </p>
                        <p>
                          <a
                            href="#ProgrammableVoice"
                            data-bs-toggle="modal"
                            data-bs-target="#ProgrammableVoice"
                          >
                            Discover more ways you can use IVR.
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-heading3">
                    <button
                      class="accordion-button  collapsed style-h4"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse3"
                      aria-expanded="false"
                      aria-controls="flush-collapse3"
                    >
                      Alerts &amp; Notifications{" "}
                    </button>
                  </h2>
                  <div
                    id="flush-collapse3"
                    class="accordion-collapse collapse "
                    aria-labelledby="flush-heading3"
                    data-bs-parent="#accordionFlush-6"
                  >
                    <div class="accordion-body">
                      <div class="d-block w-100">
                        <img
                          decoding="async"
                          loading="lazy"
                          class="aligncenter size-full size-full mb-3 mb-md-0"
                          src="https://toku.co/wp-content/uploads/2023/01/Alerts-Notifications-image.png"
                          alt="Alerts &amp; Notifications "
                        />
                      </div>
                      <div class="d-block w-100 p-small">
                        <p>
                          Add a new dynamic to your customer notifications by
                          turning them into voice-based messages with
                          text-to-speech.
                        </p>
                        <p>
                          <a
                            href="#ProgrammableVoice"
                            data-bs-toggle="modal"
                            data-bs-target="#ProgrammableVoice"
                          >
                            Discover more about our Voice APIs.
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row d-none d-md-flex">
              <div class="col-6">
                <h3 class="style-h3 mb-5">
                  Explore innovative applications of voice technology{" "}
                </h3>
                <button
                  ng-repeat="item in list_accordion"
                  class="list-accordion style-h4 w-100 ng-binding ng-scope active"
                  ng-class="{'active':item===selected_item}"
                  ng-click="showItem(item, 'item')"
                >
                  Call Forwarding
                </button>
                <button
                  ng-repeat="item in list_accordion"
                  class="list-accordion style-h4 w-100 ng-binding ng-scope"
                  ng-class="{'active':item===selected_item}"
                  ng-click="showItem(item, 'item')"
                >
                  IVR
                </button>
                <button
                  ng-repeat="item in list_accordion"
                  class="list-accordion style-h4 w-100 ng-binding ng-scope"
                  ng-class="{'active':item===selected_item}"
                  ng-click="showItem(item, 'item')"
                >
                  Alerts &amp; Notifications
                </button>
              </div>
              <div class="col-6">
                <div class="d-block w-100 mb-2">
                  <img
                    decoding="async"
                    loading="lazy"
                    class="size-full"
                    ng-src="https://toku.co/wp-content/uploads/2023/03/Frame-46250.png"
                    alt="Call Forwarding "
                    ng-show="selected_item.image !== false"
                    src="https://toku.co/wp-content/uploads/2023/03/Frame-46250.png"
                  />
                </div>
                <div class="d-block w-100">
                  <div class="style-h4 mb-2 ng-binding">Call Forwarding</div>
                </div>
                <div
                  class="d-block w-100 ng-binding"
                  ng-bind-html="htmlTrusted(selected_item.text)"
                >
                  <p>
                    Make sure you’re providing the best possible experience and
                    ensure customer calls get to the right place by adding
                    call-forwarding capabilities.
                  </p>
                  <p>
                    <a
                      href="#ProgrammableVoice"
                      data-bs-toggle="modal"
                      data-bs-target="#ProgrammableVoice"
                    >
                      Learn more about Programmable Voice
                    </a>
                  </p>
                </div>
                <a
                  class="btn btn-arrow text-start ng-binding ng-hide"
                  ng-show="selected_item.bottom_arrow_link"
                  target=""
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-7"
        class="w-100 position-relative block-centered_title "
      >
        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Providing an exceptional customer <br /> experience with the
                  power of communication technology
                </h2>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-8"
        class="w-100 position-relative block-centered_title "
      >
        <div class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <p class="p-small">
                  Develop an immersive and expandable voice experience on your
                  platform, equipped with a comprehensive set of features to
                  deliver the optimal calling experience in Africa.
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-9"
        class="w-100 position-relative block-image_side_and_text_side_repeater "
      >
        <div class="w-100 section-row_repeater">
          <div class="container">
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Number-masking-image-1.png"
                  alt="Number masking image"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Enhanced Caller Privacy </h3>{" "}
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Safeguard your users, agents, sellers, drivers, and
                      service providers with a number masking API, enabling
                      anonymization or customization of caller ids for enhanced
                      user privacy.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a href="/" class="btn btn-arrow text-start" target="">
                    Learn How to Enable Anonymised Communications{" "}
                  </a>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  pe-lg-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/User-Verification-illustration-1.png"
                  alt="User Verification illustration"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  ps-lg-5">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">User Authentication </h3>{" "}
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Authenticate genuine customers, boost conversion rates,
                      and mitigate fraud using a straightforward suite of APIS
                      tailored for global enterprises.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a href="" class="btn btn-arrow text-start" target="">
                    Discover Our Robust User Verification{" "}
                  </a>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/In-app.png"
                  alt="In app"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">Integrated Voice Calling </h3>{" "}
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Establish a reliable backup for PSTN calls or integrate a
                      new communication channel into your mobile or web
                      application with a robust in-app voice SDK.
                    </p>
                  </div>
                </div>
                <div class="w-100 d-block"></div>
                <div class="w-100 d-block">
                  <a href="" class="btn btn-arrow text-start" target="">
                    Start Building an In-App Voice Calling Solution{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-10"
        class="w-100 position-relative block-cta_strip_2 "
      >
        <div class="w-100 section-cta_strip_2 text-white text-center text-md-start ">
          <div class="container pt-5 pt-md-0">
            <div class="row py-md-5">
              <div
                class="col-12 col-md-6 d-flex ps-md-5  order-last order-md-first"
                style={{ verticalAlign: "bottom" }}
              >
                <img
                  src={programmableVoiceImg6}
                  alt="A solution for every setup "
                  class="w-100 img-class"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div class="col-12 col-md-6 py-4 py-md-5 my-auto px-lg-4 ">
                <div class="d-block w-100">
                  <h2 class="style-h2">
                    Tailored Solutions for Every Configuration
                  </h2>
                </div>
                <div class="d-block w-100">
                  <div class="p-big">
                    <p>
                      No matter if you have an on-site PBX, a cloud-based
                      solution, or a combination of both, our Voice APIs and SDK
                      are purposefully crafted to seamlessly integrate with any
                      setup.
                    </p>
                    <p>
                      With Kasookoo's consultative approach, our dedicated team
                      will take the time to understand your integration needs
                      and collaborate with you to design a customized voice
                      solution that perfectly aligns with your requirements.
                    </p>
                  </div>
                </div>
                <div class="d-block w-100 mt-4">
                  <a
                    href="/contact"
                    class="btn btn-arrow border-radius-50 mx-auto ms-0"
                    target=""
                    data-bs-toggle="modal"
                    data-bs-target="#ProgrammableVoice"
                  >
                    Talk to an Expert{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-11"
        class="w-100 position-relative block-titles_background_and_repeater_subjects "
      >
        <div class="w-100 section-titles_background_and_repeater_subjects">
          <div class="container">
            <div class="row px-md-5 pb-5 pt-3">
              <div class="col-12 col-md-10 col-lg-8 mx-auto text-md-center">
                <div class="d-block w-100">
                  <h2 class="style-h2">
                    Uncover how similar industries are empowering their
                    platforms with voice capabilities
                  </h2>
                </div>
              </div>
            </div>
            <div class="row px-md-3 rseh">
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/government-solutions/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={bankicon}
                        alt="Government"
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/government-solution" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/government-solution"
                      title=""
                      target=""
                      style={{ height: "65px" }}
                    >
                      Government
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/fintech/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={dollaricon}
                        alt="Financial Services"
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/fintech" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/fintech"
                      title=""
                      target=""
                      style={{ height: "65px" }}
                    >
                      Financial Services
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/sharing-economy/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={sendicon}
                        alt="Sharing Economy"
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/sharing-economy" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/sharing-economy/"
                      title=""
                      target=""
                      style={{ height: "65px" }}
                    >
                      Sharing Economy
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/insurance-services/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={insuranceicon}
                        alt="Insurance Services"
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/insurance-services" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="insurance-services"
                      title=""
                      target=""
                      style={{ height: "65px" }}
                    >
                      Insurance Services
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/hospitality/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={planeicon}
                        alt="Travel &amp; Hospitality"
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/hospitality" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/hospitality"
                      title=""
                      target=""
                      style={{ height: "65px" }}
                    >
                      Travel &amp; Hospitality
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4 col-md-4 pb-2 pb-md-4">
                <div class="item-inner py-4 pb-md-5 text-center d-block px-2 color-purple">
                  <a href="/retail-ecommerce/" title="" target="">
                    <div class="d-block w-100 pt-2 pt-xl-4 mb-xl-2">
                      <img
                        src={grocerystoreicon}
                        alt="Retail &amp; E-commerce"
                        class="mx-auto mb-2 mt-1"
                      />
                    </div>
                  </a>
                  <div class="d-block w-100 pb-3 pb-xl-3 mb-xl-1">
                    <a href="/retail-ecommerce/" title="" target=""></a>
                    <a
                      class="style-h4 rs_equalHeight"
                      href="/retail-ecommerce"
                      title=""
                      target=""
                      style={{ height: "65px" }}
                    >
                      Retail &amp; E-commerce
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-md-none bg-archer bg-archer-up">
        <svg
          width="400"
          height="87"
          viewBox="0 0 400 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 80.5 40.5 200 40.5C319.5 40.5 400 0 400 0V87H0V0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div class="w-100 d-none d-md-block bg-archer bg-archer-up">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0C0 0 506.625 124 1253.5 124C2000.38 124 2500 0 2500 0V178H0L0 0Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-12"
        class="w-100 position-relative block-big_title pt-5 text-md-center pb-2"
      >
        <div class="w-100 section-big_title">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-10 col-lg-8 px-md-4 mx-auto">
                <h2 class="style-h2">
                  Gain Insights into How Ride-Hailing Giant Gojek Leverages
                  Kasookoo's APIs to Enhance the Power of Their App
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-13"
        class="w-100 position-relative block-free_blocks_centerd "
      >
        <div class="w-100 section-free-blocks pt-3 pb-md-3 text-md-center w-md-75 mx-auto">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-md-auto mb-3">
                <p>
                  Revolutionize the lost and found process for Gojek, the
                  ride-hailing transport service, by integrating calling,
                  messaging, and number masking capabilities into their app.
                  enhance cooperation and communication between drivers and
                  customers.
                </p>
                <p>
                  <img
                    decoding="async"
                    loading="lazy"
                    class="aligncenter wp-image-3166 size-large"
                    src="https://toku.co/wp-content/uploads/2023/01/Frame-46246-1-e1673351533629-1024x433.png"
                    alt=""
                    width="1024"
                    height="433"
                    srcset="https://toku.co/wp-content/uploads/2023/01/Frame-46246-1-e1673351533629-1024x433.png 1024w, https://toku.co/wp-content/uploads/2023/01/Frame-46246-1-e1673351533629-300x127.png 300w, https://toku.co/wp-content/uploads/2023/01/Frame-46246-1-e1673351533629-768x324.png 768w, https://toku.co/wp-content/uploads/2023/01/Frame-46246-1-e1673351533629-1536x649.png 1536w, https://toku.co/wp-content/uploads/2023/01/Frame-46246-1-e1673351533629-2048x865.png 2048w"
                    sizes="(max-width: 1024px) 100vw, 1024px"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-14"
        class="w-100 position-relative block-center_button pb-5 text-start text-md-center"
      >
        <div class="container section-center_button">
          <div class="row align-items-center text-left text-md-center">
            <div class="col-12 ">
              <a href="/" class="btn btn-blue" target="">
                Learn More{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-15"
        class="w-100 position-relative block-cta_strip_2 py-md-3"
      >
        <div class="w-100 section-cta_strip_2 text-white text-center text-md-start pb-md-3">
          <div class="container pt-5 pt-md-0">
            <div class="row py-md-5">
              <div
                class="col-12 col-md-6 d-flex ps-md-5  order-last order-md-first"
                style={{ verticalAlign: "bottom" }}
              >
                <img
                  src={programmableVoiceImg8}
                  alt="Want to create new voice experiences?"
                  class="w-100 img-class"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div class="col-12 col-md-6 py-4 py-md-5 my-auto px-lg-4 py-md-3">
                <div class="d-block w-100">
                  <h2 class="style-h2">
                    Looking to innovate with new voice experiences
                  </h2>
                </div>
                <div class="d-block w-100 mt-4">
                  <a
                    href="/contact"
                    class="blact_btn"
                    target=""
                    data-bs-toggle="modal"
                    data-bs-target="#ProgrammableVoice"
                  >
                    Talk to an Expert{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
